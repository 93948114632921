/**
 * Created by dpardell on 01/12/2015.
 */
angular
    .module('annexaApp')
    .service('CommonService',['$q', '$http', 'RestService', '$filter', '$rootScope', 'Language', 'globalModals', 'AnnexaFormlyFactory', function ($q, $http, RestService, $filter, $rootScope, Language, globalModals, AnnexaFormlyFactory) {
        return {
            getRouterPromise: function(response, router) {
                var data = undefined;

                _.forEach(response, function(item) {
                    if(item.config.headers.hasOwnProperty('router')) {
                        if(item.config.headers.router == router) {
                            data = JSOG.decode(item.data);
                        }
                    }
                });

                return data;
            },
            formatDate: function(date, add, del) {
                if (add) {
                    date.setDate(date.getDate() + 1);
                }
                if (del) {
                    date.setDate(date.getDate() - 1);
                }
                var dd = date.getDate();
                var mm = date.getMonth() + 1;
                var yyyy = date.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd
                }
                if (mm < 10) {
                    mm = '0' + mm
                }
                return mm + '/' + dd + '/' + yyyy;
            },
            getTreeData: function (allNodes, language) {
                var treeData = { 0: [] };

                angular.forEach(allNodes, function (node) {
                    var numSubNodes = $linq(allNodes).count("x => x.parent != null && x.parent.id == " + node.id);

                    if(!node.hasOwnProperty('selectable')) {
                        node.selectable = false;
                    }

                    if(node.parent == null) {
                        treeData[0].push({ 
                        		"id": node.id, 
                        		"title": node[language], 
                        		"size": numSubNodes, 
                        		"parent": numSubNodes == 0 ? false : true, 
                        		"selectable": node.selectable,
                        		"language1": node.language1,
                        		"language2": node.language2,
                        		"language3": node.language3 })
                    } else {
                        if(!(node.parent.id in treeData )) {
                            treeData[node.parent.id] = [];
                        }
                        var title = node[language] ? node[language] : (node['name'] ? node['name'] : '' );

                        treeData[node.parent.id].push({ 
                        		"id": node.id, 
                        		"title": title, 
                        		"size": numSubNodes, 
                        		"parent": numSubNodes == 0 ? false : true, 
                        		"selectable": node.selectable,
                        		"language1": node.language1,
                        		"language2": node.language2,
                        		"language3": node.language3 })
                    }
                });

                return treeData;
            },
            filterThirds: function(filter, aditional_filter) {
                return $http({
                    url: './api/thirds/filter',
                    method: 'GET',
                    params: { filter: filter, aditional_filter: aditional_filter }
                }).then(function(response) {
                    return JSOG.decode(response.data);
                }).catch(function(error) {
                    console.log(error);
                })
            },
            getEditOnlineUrl: function(code) {
                var deferrend = $q.defer();
                $http({
                    url: './api/repodocs',
                    method: "GET",
                    params: {
                        id: code
                    }
                }).success(function (res) {
                    deferrend.resolve(res.editOnlineUrl);
                }).error(function(msg){
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            },
            getNestableData: function (data, elementName, orderProperty) {
                var nestableData = [];

                var addNode = function (nodeList, node) {
                    var newNode = { nodes: [] };
                    newNode[elementName] = node;

                    nodeList.push(newNode);
                }

                var existNode = function (nodesList, node) {
                    var existNodeObj = $.grep(nodesList, function(n) {
                    	if(!node.id){
                    		if(node.value && node.value == n[elementName].value){
                    			return n;
                    		}
                    	}else if(n[elementName].id == node.id) {
                            return n;
                        }
                        return ;
                    });

                    if(existNodeObj == null || existNodeObj == undefined || existNodeObj.length == 0) {
                        var continueFor = true;
                        angular.forEach(nodesList, function (value) {
                            if(value.nodes && value.nodes.length > 0 && continueFor) {
                                existNodeObj = existNode(value.nodes, node);
                                if(existNodeObj && existNodeObj.length > 0) {
                                    continueFor = false;
                                }
                            }
                        })
                    }

                    return existNodeObj;
                }

                var createNode = function (node) {
                    if(!node.parent) {
                        var existNodeObj = existNode(nestableData, node);
                        if(existNodeObj == null || existNodeObj == undefined || existNodeObj.length == 0) {
                            addNode(nestableData, node);
                        }
                    } else {
                        var existNodeObj1 = existNode(nestableData, node.parent);
                        if(existNodeObj1 != null && existNodeObj1 != undefined && existNodeObj1.length > 0) {
                            var exist = existNode(nestableData, node);
                            if(exist == null || exist == undefined || exist.length == 0) {
                                addNode(existNodeObj1[0].nodes, node);
                            }
                        } else {
                            createNode(node.parent);
                            existNodeObj1 = existNode(nestableData, node.parent);
                            if(existNodeObj1 != null && existNodeObj1 != undefined && existNodeObj1.length > 0) {
                                var exist1 = existNode(nestableData, node);
                                if(exist1 == null || exist1 == undefined || exist1.length == 0) {
                                    addNode(existNodeObj1[0].nodes, node);
                                }
                            }
                        }
                    }
                };

                var orderArray = function(array){
                	array = $linq(array).orderBy(function(x){return x[elementName][orderProperty]}).toArray();
                	_.forEach(array, function(subArray){
                		if(subArray.nodes && subArray.nodes.length > 0){
                			subArray.nodes = orderArray(subArray.nodes);
                		}
                	});
                	return array;
                }
                
                angular.forEach(data, function(value) {
                    createNode(value)
                });
                if(orderProperty){
                	nestableData = orderArray(nestableData);
                }
                return nestableData;
            },
            base64ArrayBuffer: function (arrayBuffer) {
                var base64    = ''
                var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

                var bytes         = new Uint8Array(arrayBuffer)
                var byteLength    = bytes.byteLength
                var byteRemainder = byteLength % 3
                var mainLength    = byteLength - byteRemainder

                var a, b, c, d
                var chunk

                // Main loop deals with bytes in chunks of 3
                for (var i = 0; i < mainLength; i = i + 3) {
                    // Combine the three bytes into a single integer
                    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

                    // Use bitmasks to extract 6-bit segments from the triplet
                    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
                    b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
                    c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
                    d = chunk & 63               // 63       = 2^6 - 1

                    // Convert the raw binary segments to the appropriate ASCII encoding
                    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
                }

                // Deal with the remaining bytes and padding
                if (byteRemainder == 1) {
                    chunk = bytes[mainLength]

                    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

                    // Set the 4 least significant bits to zero
                    b = (chunk & 3)   << 4 // 3   = 2^2 - 1

                    base64 += encodings[a] + encodings[b] + '=='
                } else if (byteRemainder == 2) {
                    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

                    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
                    b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

                    // Set the 2 least significant bits to zero
                    c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

                    base64 += encodings[a] + encodings[b] + encodings[c] + '='
                }

                return base64
            },
            convertToArray: function (object) {
                var array;

                if(!Array.isArray(object)) {
                    array = [ object ];
                } else {
                    array = object;
                }

                return array;
            },
            guid: function() {
                var s4 = function() {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }

                return s4() + s4() + '-' + s4() + '-' +  s4() + '-' +
                        s4() + '-' + s4() + s4() + s4();
            },
            loadUsers: function(value, withThird) {
                var valString = '';

                if(value.hasOwnProperty('val')) {
                    valString = value.val;
                } else if(value.hasOwnProperty('value')) {
                    valString = value.value;
                } else {
                    valString = value;
                }

                if(!valString) {
                    return [];
                }

                if(valString != '*' && valString.length < 3) {
                    return [];
                }

                if(valString == '*') {
                    valString = '';
                }

                return RestService.loadData('common', 'User', valString)
                    .then(function(data) {
                        var users = [];

                        if(data.data && data.data.content && data.data.content.length > 0) {
                            var usersObtained = JSOG.decode(data.data.content);

                            if(withThird) {
                                usersObtained = $linq(usersObtained).where("x => x.third").toArray();
                            }

                            _.forEach(usersObtained, function(val) {

                                var name = val.name + (val.surname1Particles ? ' ' + val.surname1Particles : '') + ' ' + val.surename1;

                                if(val.surename2) {
                                    name +=  (val.surname2Particles ? ' ' + val.surname2Particles : '') + ' ' + val.surename2;
                                }

                                users.push({ 'id':  val.id, 'user': val, 'value': name });
                            });
                        }

                        return users;
                    }).catch(function() {
                        return [];
                    });

            },
            getMimetypeDescription: function(mimeType) {
                var deferred = $q.defer();

                if(mimeType){
                    $http.get('./scripts/components/json/mimeTypes.json')
                        .then(function(res) {
                            var foundMimeType = $linq(res.data.mimeTypesList).firstOrDefault(undefined, "x => x.mimeType == '" + mimeType + "'");

                            if(foundMimeType) {
                                deferred.resolve(foundMimeType.description);
                            } else {
                                deferred.resolve(mimeType);
                            }
                        }).catch(function() {
                            deferred.resolve(mimeType);
                    });
                } else {
                    deferred.resolve(mimeType);
                }

                return deferred.promise;
            },
            getFileSizeDescription: function (size) {
                var sizeString = '0 Bytes';

                if(size && size > 0) {
                    var sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
                    var i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
                    sizeString = Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
                }

                return sizeString;
            },
            getParameterList: function(ids) {
                var idsString = '';

                _.forEach(ids, function (item) {
                    idsString += idsString != '' ? ',' + item : item;
                });

                if(!idsString) {
                    idsString = '-1'
                }

                return idsString;
            },
            getThirdCompleteName: function (third) {
                if(third.thirdType != 'PHISICAL') {
                    return third.corporateName;
                } else {
                    return third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1 + (third.surname2Particles ? ' ' + third.surname2Particles : '') + (third.surename2 ? ' ' + third.surename2 : '');
                }
            },
            getThirdRelationType: function(third_relation) {
                if(third_relation.interested) {
                    return $filter('translate')('global.literals.interested');
                }

                var representated = '';
                
                if(third_relation.representated && third_relation.representated.identificationDocument) {
                    representated += '(' + this.getThirdCompleteName(third_relation.representated) + ' (' + third_relation.representated.identificationDocument +'))';
                    representated = $filter('translate')('global.literals.representative', { representated: representated });
                   
                }else if(third_relation.representative){
                	representated = $filter('translate')('global.literals.representative');
                }

                return representated;
            },
            getThirdAddress: function(address) {
                var retValue = '';

                if(address) {
                    switch(address.addressType) {
                        case 'POSTAL':
                            if(address.address) {
                                retValue += address.address[($rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value ? "address" : "shortAddress")+Language.getActiveColumn()]
                            }
                            break;
                        case 'TELEMATIC_EMAIL':
                            retValue += address.telematicValue;
                            break;
                        case 'POSTAL_CODE':
                            retValue += address.telematicValue;
                            break;
                        case 'TELEMATIC_MOBILEPHONE':
                            if(address.phoneInternationalPrefix) {
                                retValue += '+' + address.phoneInternationalPrefix + ' ';
                            }
                            retValue += address.telematicValue;
                            break;
                    }
                }

                return retValue;
            },
            getThirdCompleteNameWithAddress: function(third, address) {
            	if(third && third.completeName && third.completeName != '') {
            		var retValue = third.completeName;
                } else {
                    var retValue = this.getThirdCompleteName(third);
                }

                if(address) {
                    retValue += ' (' + this.getThirdAddress(address) + ')';
                }

                return retValue;
            },
            fixRepoIdForSendRest: function(repoId) {
                repoId = repoId.replace(new RegExp('/', 'g'), '<$>');
                repoId = repoId.replace(new RegExp(':', 'g'), '<><');

                return repoId;
            },
			executeExpression: function(exp, pars, actualCF){
				var modalExec = angular.copy(globalModals.expressionEvaluator);
				var buttonField = $linq(modalExec.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addExpression'");
				buttonField.templateOptions.executeFunction = function(a,b,c,d,e) {
            		var modalExp = angular.copy(globalModals.expressionEvaluatorAdd);
					modalExp.annexaFormly.options = {};
					var executeExpressionAdd = function(){
						var modalExpAux = this;
						if(modalExpAux && modalExpAux.annexaFormly && modalExpAux.annexaFormly.model && modalExpAux.annexaFormly.model.modal_body && modalExpAux.annexaFormly.model.modal_body.expression){
							if(modalExec && modalExec.annexaFormly && modalExec.annexaFormly.model && modalExec.annexaFormly.model.modal_body){
								var paramsSpel = '';
								if(modalExpAux.annexaFormly.model.modal_body.expression.params && modalExpAux.annexaFormly.model.modal_body.expression.params.length > 0){
									var paramsField = $linq(modalExec.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'root'");
									var paramIndex = paramsField.fieldGroup[0].data.params.length;
				        			if(paramsField && paramsField.fieldGroup && paramsField.fieldGroup.length > 0 && paramsField.fieldGroup[0].data && paramsField.fieldGroup[0].data.params){
										_.forEach(modalExpAux.annexaFormly.model.modal_body.expression.params, function(param, index){
											paramsField.fieldGroup[0].data.params.push({name:'param'+(paramIndex+index+1), type:{id:param.type, name:param.type}, typeList:((param.typeList)?{id:param.typeList, name:param.typeList}:undefined), value:undefined});
											paramsSpel = paramsSpel+((paramsSpel !== '')?',':'')+'[\'param'+(paramIndex+index+1)+'\']';
										});
										
				        			}
								}
								modalExec.annexaFormly.model.modal_body.spel = ((modalExec.annexaFormly.model.modal_body.spel)?modalExec.annexaFormly.model.modal_body.spel:'')+' #'+modalExpAux.annexaFormly.model.modal_body.expression.functionName+'('+paramsSpel+')';
							}		
						}
						modalExpAux.close();
					}
					AnnexaFormlyFactory.showModal('modalExpressionEvaluatorAdd', modalExp, executeExpressionAdd, false);
            	};
	            modalExec.annexaFormly.options = {};
				if(exp){
					if(modalExec.annexaFormly){
						if(modalExec.annexaFormly.model){
							if(modalExec.annexaFormly.model.modal_body){
								modalExec.annexaFormly.model.modal_body.spel = exp;
							}else{
								modalExec.annexaFormly.model.modal_body = {spel:exp};
							}
						}else{
								modalExec.annexaFormly.model = {modal_body:{spel:exp}};
						}
					}
				}
				if(pars && pars.length > 0){
					var paramsField = $linq(modalExec.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'root'");
				    if(paramsField && paramsField.fieldGroup && paramsField.fieldGroup.length > 0 && paramsField.fieldGroup[0].data && paramsField.fieldGroup[0].data.params){
						paramsField.fieldGroup[0].data.params = pars;
				    }
				}
				var executeExpression = function(){
					var modal = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.spel){
						var expression = {
							spel: modal.annexaFormly.model.modal_body.spel,
							params: [],
							actualCF:actualCF
						}
						var paramsField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'root'");
				        if(paramsField && paramsField.fieldGroup && paramsField.fieldGroup.length > 0 && paramsField.fieldGroup[0].data && paramsField.fieldGroup[0].data.params){
				            _.forEach(paramsField.fieldGroup[0].data.params, function(param){
								var par = {
									name: param.name
								}
								if(param.type && param.type.id){
									par.type = param.type.id;
									par.typeList = ((param.typeList && param.typeList.id)?param.typeList.id:undefined);
									par.value = param.value;
									par.id = param.id;
								}else{
									par.value = param.value;
								}
								expression.params.push(par);
							});
				        }
						$http({
			                method: 'PUT',
			                url: './api/tram/spel_function/evaluator/call/',
		 					data: expression
			            }).success(function (data, status) {
							var result = JSOG.decode(data);
							if(result && result.result){
								try{
									result.result = JSON.stringify(result.result);
								}catch(e){
									result.result = result.result;
								}
								modal.annexaFormly.model.modal_body.result = result.result;
							}else{
								if(result.result === false){
									modal.annexaFormly.model.modal_body.result = result.result;
								}else{
									modal.annexaFormly.model.modal_body.result = $filter('translate')('global.literals.noResults');
								}
							}
			            }).error(function (msg, code) {
			            	if(msg && msg.message === 'Spel contains invalid class'){
			            		modal.alerts.push({ msg: $filter('translate')('global.errors.errorEvalutateExpression')+' '+$filter('translate')('global.errors.spelInvalidClass')});
			            	}else{
			            		modal.alerts.push({ msg: $filter('translate')('global.errors.errorEvalutateExpression')+' '+((msg && msg.message)?msg.message:'')});
			            	}
			            });
					}else{
						
					}
				}
	            AnnexaFormlyFactory.showModal('modalExpressionEvaluator', modalExec, executeExpression, false, false, 'global.querys.execute.execute');
			}
        }
    }]);