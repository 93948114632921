/**
 * Created by osirvent on 14/03/2016.
 */
angular.module('annexaApp')
    .directive('tablefilter',['HelperService', '$compile', '$filter', '$rootScope', function(HelperService, $compile, $filter, $rootScope) {

        var getColClass = function(elements,i,rowNum, classNumRows) {
            var colLength;

            return "col-sm-12 col-md-"+classNumRows;
        };

        var getModel = function(element, i){
            switch (element.type) {
                case 'select-multiple':
                    if(i == 0){
                        return ' filter.' + element.name + '.model.length != 0 ';
                    }else{
                        return ' || filter.' + element.name + '.model.length != 0 ';
                    }
                    break;
                case 'select':
                    if(i == 0){
                        return ' filter.' + element.name + '.model.id ';
                    }else{
                        return ' || filter.' + element.name + '.model.id ';
                    }
                    break;
                case 'select-tree': {
                    if(i == 0) {
                        return ' filter.' + element.name + '.model.$selected.title';
                    } else {
                        return ' || filter.' + element.name + '.model.$selected.title';
                    }
                }
                case 'text':
                    if(i == 0){
                        return ' filter.' + element.name + '.model ';
                    }else{
                        return ' || filter.' + element.name + '.model ';
                    }
                    break;
                case 'dateRange':
                    if(i == 0){
                        return ' filter.' + element.name + '.model.dataFrom || filter.' + element.name + '.model.dataTo ';
                    }else{
                        return ' || filter.' + element.name + '.model.dataFrom || filter.' + element.name + '.model.dataTo ';
                    }
                    break;
                case 'checkbox':
                    if(i == 0){
                        return ' filter.' + element.name + '.model ';
                    }else{
                        return ' || filter.' + element.name + '.model ';
                    }
                    break;
            }
        }

        var cleanFilters = function(scope) {
            var elements = scope.filter.getElements();
            if (elements.length != 0) {
                for (i = 0; i < elements.length; i++) {
                    switch (elements[i].type) {
                        case 'select-multiple':
                            if(elements[i].listValues && elements[i].listValues.length > 0) {
                                elements[i].model = elements[i].listValues[0];
                            }else{
                                elements[i].model = '';
                            }
                            break;
                        case 'select':
                            if(elements[i].listValues && elements[i].listValues.length > 0) {
                                elements[i].model = elements[i].listValues[0];
                            }else{
                                elements[i].model = '';
                            }
                            break;
                        case 'text':
                            elements[i].model = '';
                            break;
                        case 'dateRange':
                            elements[i].model = undefined;
                            break;
                        case 'select-tree':
                            elements[i].model = {};
                            break;
                    }
                }
                scope.dofilter();
            }
        }

        var getTemplateAdvancedSearch = function(id, filterObject, submitFunction, scope){

            if(!id) {
                id = 'tableFilter';
            }
            var template = '';
            var templateInput = '';
            template = template + '<div class="col-sm-12 collapse in" id="' + id +'">';

            var templateTab = '<div class="col-sm-12 text-right p-y-sm" id="resultFilter" ng-show="(';
            var templateTabs = '';

            var i,j;


            template = template + '<form class="p-b" novalidate>';

            var elements    = filterObject.getElements();
            var showLegend  = filterObject.getShowLegend();

            template = template + '<div class="row p-y">';
            template = template + '<div class="col-sm-5 p-r-2 search-left ">';
            template = template + '<div class="row"><h3 class="col-sm-12 text _600" translate="global.literals.advancedSearchWith"></h3></div>';
            template = template + '<div class=" m-l ">';

            if(elements.length != 0) {

                // Pintar camps estàtics del filtre
                for (i = 0; i < elements.length; i++) {

                    if (!elements[i].userChosenFilter) {

                        if (elements[i].type != 'dateRange') { // tots els tipus excepte dateRange que es un 2 en 1
                            if (elements[i].type == 'checkbox') {
                                template = template + '<div class="filter-checkbox row">';
                            } else {
                            	template = template + '<div class="row">';
                                template = template + ' <label class="col-sm-12 form-control-label _600 small p-l-0 p-b-0" translate="' + elements[i].label + '" for="' + elements[i].name + '">' + elements[i].label + '</label>';
                                template = template + '</div>';
                                template = template + '<div class="row">';
                            }
                        }

                        switch (elements[i].type) {

                            case 'select':
                                template = template + '<ui-select fix-focus-on-touch ng-model="filter.' + elements[i].name + '.model" theme="bootstrap" ng-change="changeValue(filter.showLegend, false)">';
                                template = template + '<ui-select-match>';
                                if (elements[i].iconElement) {
                                    template = template + '<circleicon	size="sm" ng-if="filter.' + elements[i].name + '.model && filter.' + elements[i].name + '.model.id"';
                                    template = template + 'bgcolor="filter.' + elements[i].name + '.model.style" >';
                                    template = template + '{{ filter.' + elements[i].name + '.model.' + elements[i].iconElement + ' | annexatranslate: \'' + $rootScope.app.language + '\' }}';
                                    template = template + '</circleicon>';
                                }
                                template = template + ' {{filter.' + elements[i].name + '.model.' + elements[i].nameElement + ' | annexatranslate: \'' + $rootScope.app.language + '\'}}';
                                template = template + '</ui-select-match>';
                                template = template + '<ui-select-choices repeat="item in filter.' + elements[i].name + '.listValues | filter: $select.search">';
                                template = template + '<div>';
                                if (elements[i].iconElement) {
                                    template = template + '<circleicon	size="sm" ng-if="item.id"';
                                    template = template + 'bgcolor="item.style" >';
                                    template = template + '{{ item.' + elements[i].iconElement + ' | annexatranslate: \'' + $rootScope.app.language + '\' }}';
                                    template = template + '</circleicon>';
                                }
                                template = template + ' {{item.' + elements[i].nameElement + ' | annexatranslate: \'' + $rootScope.app.language + '\'}}';
                                template = template + '</div>';
                                template = template + '</ui-select-choices>';
                                template = template + '</ui-select>';
                                templateTab = templateTab + getModel(elements[i], i);
                                templateTabs = templateTabs + '<span class="filters-see " ng-show="filter.' + elements[i].name + '.model.id"><a href="" ng-click="filter.' + elements[i].name + '.model=filter.' + elements[i].name + '.listValues[0]; changeValue(filter.showLegend, true)" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a>';
                                if (elements[i].nameElement == 'name') {
                                    templateTabs = templateTabs + '{{filter.' + elements[i].name + '.model.name | annexatranslate: \'' + $rootScope.app.language + '\'}}';
                                } else {
                                    templateTabs = templateTabs + '{{filter.' + elements[i].name + '.model.' + elements[i].nameElement + '}}';
                                }
                                templateTabs = templateTabs + '</span>';
                                break;
                            case 'text':
                                template = template + '<input type="text" class="form-control" id="' + elements[i].name + '" ng-model="filter.' + elements[i].name + '.model" name="' + elements[i].name + '" ng-change="changeValue(filter.showLegend, true, \'' + elements[i].name + '\')" ng-model-options="{debounce: 1000}"/>';
                                templateTab = templateTab + getModel(elements[i], i);
                                templateTabs = templateTabs + '<span class=" filters-see " ng-show="filter.' + elements[i].name + '.model"><a href="" ng-click="filter.' + elements[i].name + '.model=\'\'; changeValue(filter.showLegend, true,  \'' + elements[i].name + '\')" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a> {{filter.' + elements[i].name + '.model}}</span>';
                                break;
                            case 'checkbox':
                            	template = template + ' <input type="checkbox" name="' + elements[i].name + '" id="' + elements[i].name + '" ng-model="filter.' + elements[i].name + '.model" ng-change="changeValue(filter.showLegend, false, \'' + elements[i].name + '\')" />';
                            	template = template + ' <label class="col-sm-12 form-control-label _600 small p-l-0 p-b-0" translate="' + elements[i].label + '" for="' + elements[i].name + '">' + elements[i].label + '</label>';
                                templateTab = templateTab + getModel(elements[i], i);
                                templateTabs = templateTabs + '<span class=" filters-see " ng-show="filter.' + elements[i].name + '.model"><a href="" ng-click="filter.' + elements[i].name + '.model=false; changeValue(filter.showLegend, true,  \'' + elements[i].name + '\')" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a> {{\'' + elements[i].label + '\' | translate}}</span>';
                                break;
                        }
                        template = template + '</div>';
                        // template = template + '<div class="row"><label class="col-sm-12"></label></div>';
                        // template = template + '<div class="row"><label class="col-sm-12"></label></div>';
                    }
                    else{
                        scope.userAvailableFilters.push(elements[i]);
                        scope.copyAvailableFilters.push(elements[i]);
                    }
                }

                scope.userChosenFilters.push({initial: true});

                template = template + '</div>';
                template = template + '</div>';


                // Pintar camps dinàmics del filtre avançat
                template += '<div class="col-sm-7 p-l-md" ng-if="userAvailableFilters.length != 0">';
                template +=   '<div class="row"><h3 class="col-sm-12 _600 text" translate="global.literals.searchUsersOtherCriteria"></h3></div>';
                template +=   '<div class="row ">';
                template +=       '<div class="col-sm-5 pos-stc m-l-sm">';
                template +=           '<div data-ng-repeat="chooseFilter in userChosenFilters track by $index">';
                template +=               '<div class="row m-y-sm">';
                template +=                   '<div class="col-xs-11 col-sm-12">';
                template +=                       '<ui-select fix-focus-on-touch ng-model="chooseFilter.value" theme="bootstrap" on-select="changeUISelect($item, $model)" ng-hide="chooseFilter.value">';
                template +=                           '<ui-select-match >{{$select.selected.label | translate}}</ui-select-match>';
                template +=                           '<ui-select-choices repeat="item in userAvailableFilters | filter: $select.search" >';
                template +=                               '<div ng-bind-html="item.label | translate"></div>';
                template +=                           '</ui-select-choices>';
                template +=                       '</ui-select>';
                template +=                       '<label class="form-control-label" for="customAddedField-{{$index}}" ng-show="chooseFilter.value" translate="{{chooseFilter.value.label}}"></label>';
                template +=                   '</div>';
                template +=                   '<div class="abs-r-auto">';
                template +=                       '<button type="button" ng-click="removeCustomFilter($index, chooseFilter.value)" class="no-bg b-a-0 remove-button m-t-xs" ng-if="!chooseFilter.initial">';
                template +=                           '<i class="fa fa-remove grey-pencil" title="{{ \'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
                template +=                        '</button>';
                template +=                       '<button type="button" ng-click="addCustomFilter($index)" class="remove-button m-t-xs no-bg b-a-0" ng-if="chooseFilter.value && chooseFilter.initial && customAddedFields.length < copyAvailableFilters.length">';
                template +=                           '<i class="fa fa-plus grey-pencil" title="{{ \'global.literals.add\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.add\' | translate}}</span>';
                template +=                        '</button>';
                template +=                   '</div>';
                template +=               '</div>';
                template +=           '</div>';
                template +=       '</div>';
                template +=       '<div class="col-sm-6 p-l-0">';
                template +=           '<div class="row  m-y-sm" data-ng-repeat="customAddedField in customAddedFields">';

                // **************************** TIPUS TEXT ******************************
                template +=               '<div class="col-xs-11 col-sm-12" ng-if="customAddedField.type == \'text\'">';
                template +=                   '<input type="text" id="customAddedField-{{$index}}" class="form-control" ng-model="filter[customAddedField.name].model" ng-change="changeValue(true, true, customAddedField.name)" ng-model-options="{debounce: 1000}" />';
                template +=               '</div>';


                // **************************** TIPUS DATE ******************************
                template +=               '<div class="col-xs-11 col-sm-12" ng-if="customAddedField.type == \'dateRange\'">';
                template +=                 '<div class="row">';

                template +=                     '<div class="col-sm-12 col-md-6 dateRange">';
                template +=                         '<div class="agafaData">';
                template +=                             '<input type="text" class="form-control" placeholder="{{\'global.literals.dateFrom\' | translate}}"  uib-datepicker-popup="dd/MM/yyyy"  datepicker-options="{startingDay: 1,showWeeks: false}" ng-model="filter[customAddedField.name].model.dataFrom" ng-change="changeDateValue(filter.showLegend,filter[customAddedField.name].model, false)" is-open="filter[customAddedField.name].openFrom" current-text = "{{\'global.alerts.today\' | translate}}" clear-text ="{{\'global.literals.clear\' | translate}}" close-text = "{{\'global.literals.close\' | translate}}" />';
                template +=                             '<span class="input-group-btn">';
                template +=                                 '<button type="button" class="btn btn-default" ng-click="filter[customAddedField.name].openFrom=true"><i class="fa fa-calendar" aria-hidden="true" title="{{\'global.literals.showCalendar\' | translate}}"></i><span class="sr-only">{{\'global.literals.showCalendar\' | translate}}</span></button>';
                template +=                             '</span>';
                template +=                         '</div>';
                template +=                     '</div>';

                template +=                     '<div class="col-sm-12 col-md-6 dateRange">';
                template +=                         '<div class="agafaData">';
                template +=                             '<input type="text" class="form-control" placeholder="{{\'global.literals.dateTo\' | translate}}" uib-datepicker-popup="dd/MM/yyyy" datepicker-options="{startingDay: 1,showWeeks: false}" ng-model="filter[customAddedField.name].model.dataTo" ng-min="filter[customAddedField.name].model.dataFrom" ng-change="changeDateValue(filter.showLegend,filter[customAddedField.name].model, false)" is-open="filter[customAddedField.name].openTo" showWeeks="false" current-text = "{{\'global.alerts.today\' | translate}}" clear-text ="{{\'global.literals.clear\' | translate}}" close-text = "{{\'global.literals.close\' | translate}}" />';
                template +=                             '<span class="input-group-btn">';
                template +=                                 '<button type="button" class="btn btn-default" ng-click="filter[customAddedField.name].openTo=true"><i class="fa fa-calendar" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.showCalendar\' | translate}}</span></button>';
                template +=                             '</span>';
                template +=                         '</div>';
                template +=                     '</div>';

                template +=                 '</div>';
                template +=               '</div>';

                // **************************** TIPUS SELECT ******************************
                template +=               '<div class="col-xs-11 col-sm-12" ng-if="customAddedField.type == \'select\'">';
                template +=                   '<ui-select fix-focus-on-touch ng-model="filter[customAddedField.name].model" theme="bootstrap" on-select="changeSelectValue(true, customAddedField.name, $item)" ng-if="customAddedField.type == \'select\'">';
                template +=                       '<ui-select-match >{{ $select.selected.name }}</ui-select-match>';
                template +=                       '<ui-select-choices repeat="item in filter[customAddedField.name].listValues | filter: $select.search" >';
                template +=                           '<div ng-bind-html="item.name"></div>';
                template +=                       '</ui-select-choices>';
                template +=                   '</ui-select>';
                template +=               '</div>';

                // **************************** TIPUS SELECT-MULTIPLE ******************************
                template +=               '<div class="col-xs-11 col-sm-12" ng-if="customAddedField.type == \'select-multiple\'">';
                template +=                   '<ui-select fix-focus-on-touch ng-click="selectMe($event)" multiple ng-model="filter[customAddedField.name].model" theme="bootstrap" on-select="changeSelectMultipleValue(true, customAddedField.name, $item)" on-remove="changeSelectMultipleValue(true, customAddedField.name, $item)" ng-if="customAddedField.type == \'select-multiple\'">';
                template +=                       '<ui-select-match >{{ $item.name | translate }}</ui-select-match>';
                template +=                       '<ui-select-choices data-repeat="item in filter[customAddedField.name].listValues | filter: $select.search" >';
                template +=                           '<div ng-bind-html="item.name | translate"></div>';
                template +=                       '</ui-select-choices>';
                template +=                   '</ui-select>';
                template +=               '</div>';

                template +=   '<div class="row">';
                template +=       '<div data-ng-repeat="customAddedField in customAddedFields">';
                template +=           '<div class="col-sm-2">';
                template +=               '<span class=" filters-see " ng-show="filter[customAddedField.name].model.id && customAddedField.type == \'text\'">';
                template +=                   '<a href="" ng-click="filter[customAddedField.name].model=\'\'; changeValue(true, true,  customAddedField.name)">x</a> {{filter[customAddedField.name].model}}';
                template +=               '</span>';
                template +=           '</div>';
                template +=       '</div>';
                template +=   '</div>';
                template += '</div>';

            }

            template += '</div>';
            template += '</form>';
            template += '</div>';
            templateTab += ') && filter.showLegend">';
            templateTab += templateTabs;
            templateTab += "</div>";
            template += templateTab;

            return template;

        };

        var getTemplate = function(id,filterObject, submitFunction, collapsed,  scope) {
            if(!id) {
                id = 'tableFilter';
            }
            var numTableRows = 4;
            var classNumRows = 3;
            if(filterObject.numTableRows){
                numTableRows = filterObject.numTableRows;
                classNumRows = filterObject.classNumRows;
            }
            var template = '';
            var collapse = 'collapse';
            if(collapsed == false){
                collapse = '';
            }
            if(filterObject && filterObject.showLegend){
                template = template + '<div class="col-sm-12 '+collapse+'" id="' + id +'">';
            }else{
                template = template + '<div class="col-sm-12 '+collapse+' in" id="' + id +'">';
            }
            var templateTab = '<div class="col-sm-12 ng-hide text-right p-y-sm" id="resultFilter" ng-show="(';
            var templateTabs = '';

            var i, k;
            template = template + '<form class="p-b" novalidate>';
            template = template + '<div class="p-t">';

            var elements = filterObject.getElements();
            var showLegend = filterObject.getShowLegend();
            if(elements.length != 0) {
                for (i = 0, k = -1, j = 0; i < elements.length; i++, j++) {
                    if (i % numTableRows === 0) {
                        k++;
                        template = template + '<div class="row">';
                    }

                    if (elements[i].type != 'dateRange') { // tots els tipus excepte dateRange que es un 2 en 1
                        template = template + '<div class="' + getColClass(elements,i, k, classNumRows) + ' m-b-xs">';
                        template = template + '<div class="row">';
                        template = template + '<span class="col-sm-12 form-control-label _600 small" translate="'+elements[i].label+'">'+elements[i].label+'</span>';
                        template = template + '<div class="col-sm-12">';
                    }

                    switch(elements[i].type) {
                        case 'select-multiple':
                            template += '<ui-select ng-click="selectMe($event)" multiple data-ng-model="filter.'+elements[i].name+'.model" theme="bootstrap" ng-change="changeValue(filter.showLegend, false)">';
                            template += '   <ui-select-match>';
                            template += '       {{$item.' + elements[i].nameElement + ' | translate}}';
                            template += '   </ui-select-match>';
                            template += '   <ui-select-choices data-repeat="item in filter.'+elements[i].name+'.listValues | filter: $select.search">';
                            template += '       <div>';
                            template += '           {{item.' + elements[i].nameElement + ' | translate}}';
                            template += '       </div>';
                            template += '   </ui-select-choices>';
                            template += '</ui-select>';
                            templateTab = templateTab + getModel(elements[i], i);
                            templateTabs += '<span class="filters-see " ng-show="item.id" ng-repeat="item in filter.' + elements[i].name + '.model">';
                            templateTabs += '   <a href="" ng-click="filter.' + elements[i].name + '.model.splice($index, 1); changeValue(filter.showLegend, false)" >';
                            templateTabs += '       <i class="fa fa-close small" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></i>';
                            templateTabs += '   </a>';
                            templateTabs += '   {{item.' + elements[i].nameElement + ' | translate}}';
                            templateTabs += '</span>';
                            break;
                        case 'select':
                            template = template + '<ui-select fix-focus-on-touch ng-model="filter.'+elements[i].name+'.model" theme="bootstrap" ng-change="changeValue(filter.showLegend, false)">';
                            template = template + '<ui-select-match>';
                            if(elements[i].iconElement) {
                                template = template + '<circleicon	size="sm" ng-if="filter.' + elements[i].name + '.model && filter.' + elements[i].name + '.model.id"';
                                template = template + 'bgcolor="filter.' + elements[i].name + '.model.style" >';
                                template = template + '{{ filter.' + elements[i].name + '.model.' + elements[i].iconElement + ' | annexatranslate: \'' + $rootScope.app.language + '\' }}';
                                template = template + '</circleicon>';
                            }
                            template = template + ' {{filter.' + elements[i].name + '.model.' + elements[i].nameElement + ' | annexatranslate: \'' + $rootScope.app.language + '\'}}';
                            template = template + '</ui-select-match>';
                            template = template + '<ui-select-choices repeat="item in filter.'+elements[i].name+'.listValues | filter: $select.search">';
                            template = template + '<div>';
                            if(elements[i].iconElement) {
                                template = template + '<circleicon	size="sm" ng-if="item.id"';
                                template = template + 'bgcolor="item.style" >';
                                template = template + '{{ item.'+elements[i].iconElement+' | annexatranslate: \''+$rootScope.app.language+'\' }}';
                                template = template + '</circleicon>';
                            }
                            template = template + ' {{item.' + elements[i].nameElement + ' | annexatranslate: \''+$rootScope.app.language+'\'}}';
                            template = template + '</div>';
                            template = template + '</ui-select-choices>';
                            template = template + '</ui-select>';
                            templateTab = templateTab + getModel(elements[i], i);
                            templateTabs = templateTabs + '<span class="filters-see " ng-show="filter.' + elements[i].name + '.model.id"><a href="" ng-click="filter.' + elements[i].name + '.model=filter.'+elements[i].name+'.listValues[0]; changeValue(filter.showLegend, false)" ><i class="fa fa-close small" title="{{\'global.literals.remove\' | translate}}"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
                            if(elements[i].nameElement == 'name') {
                                templateTabs = templateTabs + '{{filter.' + elements[i].name + '.model.name | annexatranslate: \'' + $rootScope.app.language + '\'}}';
                            } else {
                                templateTabs = templateTabs + '{{filter.' + elements[i].name + '.model.' + elements[i].nameElement +'}}';
                            }
                            templateTabs = templateTabs + '</span>';
                            break;
                        case 'select-tree':
                            template += '<ui-tree-select ng-model="filter.' + elements[i].name + '.model" data="filter.' + elements[i].name + '.listValues" change-filter="changeTree()" id-model="' + elements[i].name + '"></ui-tree-select>';
                            templateTab += getModel(elements[i], i);
                            templateTabs = templateTabs + '<span class=" filters-see " ng-show="filter.' + elements[i].name + '.model.$selected"><a href="" ng-click="filter.' + elements[i].name + '.model={}; changeTree()" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a> {{filter.' + elements[i].name + '.model.$selected.title}}</span>';
                            break;
                        case 'text':
                            template = template + '<input type="text" class="form-control" id="'+elements[i].name+'" aria-label="{{\'global.sign.pending.signers\' | translate}}" ng-model="filter.'+elements[i].name+'.model" name="'+elements[i].name+'" ng-change="changeValue(filter.showLegend, true, \'' + elements[i].name +'\')" ng-model-options="{debounce: 1000}"/>';
                            templateTab = templateTab + getModel(elements[i], i);
                            templateTabs = templateTabs + '<span class=" filters-see " ng-show="filter.' + elements[i].name + '.model"><a href="" ng-click="filter.' + elements[i].name + '.model=\'\'; changeValue(filter.showLegend, true,  \'' + elements[i].name +'\')"><i class="fa fa-close small" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a> {{filter.' + elements[i].name + '.model}}</span>';
                            break;
                        case 'dateRange':
                            scope.filter[elements[i].name].openFrom = false;
                            scope.filter[elements[i].name].openTo = false;
                            template += '<div class="col-sm-12  col-md-'+classNumRows+' m-b-xs">';
                            template += '   <div class="row dateRange-group">';
                            template += '       <div class="col-sm-12 col-md-6 dateRange">';
                            template += '           <label class="small _600 form-control-label" translate="'+elements[i].label+'" for="' + elements[i].name + '">'+elements[i].label+'</label>';
                            template += '           <div class="agafaData">';
                            template += '              <input id="' + elements[i].name + '" type="text" class="form-control" placeholder="{{\'global.literals.dateFrom\' | translate}}"  uib-datepicker-popup="dd/MM/yyyy"  datepicker-options="{startingDay: 1,showWeeks: false}" ng-model="filter.'+elements[i].name+'.model.dataFrom" ng-change="changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, false)"  is-open="filter.'+elements[i].name+'.openFrom" current-text = "{{\'global.alerts.today\' | translate}}" clear-text ="{{\'global.literals.clear\' | translate}}" close-text = "{{\'global.literals.close\' | translate}}" />';
                            template += '              <span class="input-group-btn">';
                            template += '                 <button type="button" class="btn btn-default" ng-click="filter.'+elements[i].name+'.openFrom=true"><i class="fa fa-calendar" title="{{\'global.literals.showCalendar\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.showCalendar\' | translate}}</span></button>';
                            template += '              </span>';
                            template += '           </div>';
                            template += '       </div>';
                            template += '       <div class="col-sm-12 col-md-6 dateRange">';

                            template += '           <label class="small _600 form-control-label no-visible" for="to' + elements[i].name + '">{{\'global.literals.dateTo\' | translate}}</label>';
                            template += '           <div class="agafaData">';
                            template += '               <input id="to' + elements[i].name + '" type="text" class="form-control" placeholder="{{\'global.literals.dateTo\' | translate}}" uib-datepicker-popup="dd/MM/yyyy" datepicker-options="{startingDay: 1,showWeeks: false}" ng-model="filter.'+elements[i].name+'.model.dataTo" ng-min="filter.'+elements[i].name+'.model.dataFrom" ng-change="changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, false)" is-open="filter.'+elements[i].name+'.openTo" showWeeks="false" current-text = "{{\'global.alerts.today\' | translate}}" clear-text ="{{\'global.literals.clear\' | translate}}" close-text = "{{\'global.literals.close\' | translate}}" />';
                            template += '               <span class="input-group-btn">';
                            template += '                   <button type="button" class="btn btn-default" ng-click="filter.'+elements[i].name+'.openTo=true"><i class="fa fa-calendar" title="{{\'global.literals.showCalendar\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.showCalendar\' | translate}}</span></button>';
                            template += '               </span>';
                            template += '           </div>';

                            templateTab = templateTab + getModel(elements[i], i);
                            templateTabs = templateTabs + '<span class="filters-see" ng-show="filter.' + elements[i].name + '.model.dataTo || filter.' + elements[i].name + '.model.dataFrom"><a href="" ng-click="filter.' + elements[i].name + '.model=undefined; changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, true)" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a> ';
                            templateTabs = templateTabs + ' <span class="block" ng-show="filter.' + elements[i].name + '.model.dataFrom" translate="'+elements[i].label+'"> </span> ';
                            templateTabs = templateTabs + ' <span class="block" ng-show="filter.' + elements[i].name + '.model.dataFrom">{{filter.' + elements[i].name + '.model.dataFrom | date:\'dd/MM/yyyy\'}}</span> ';
                            templateTabs = templateTabs + ' <span class="block" ng-show="filter.' + elements[i].name + '.model.dataTo" translate="global.literals.dateTo"> </span> ';
                            templateTabs = templateTabs + ' <span class="block" ng-show="filter.' + elements[i].name + '.model.dataTo">{{filter.' + elements[i].name + '.model.dataTo | date:\'dd/MM/yyyy\'}}</span> ';
                            templateTabs = templateTabs + '</span>';

                            break;
                        case 'dateRange1':
                            template = template + '<div class="col-sm-12 col-md-6 col-lg-6 col-xl-'+classNumRows+' m-b-xs">';
                            template = template + '<div class="row">';
                            template += '<div class="col-sm-12 col-md-6 dateRange">';
                            template += '<label class="small _600 form-control-label" translate="'+elements[i].label+'" for="'+elements[i].name+'">'+elements[i].label+'</label>';
                            template = template + '<input type="date" ng-model="filter.'+elements[i].name+'.model.dataFrom" class="form-control"  ng-change="changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, false)"/>';
                            template += '</div>';
                            template += '<div class="col-sm-12 col-md-6 dateRange">';

                            template = template + '<label class="small form-control-label" translate="global.literals.dateTo"></label>';
                            template = template +'<input type="date" ng-model="filter.'+elements[i].name+'.model.dataTo" ng-min="filter.'+elements[i].name+'.model.dataFrom" class="form-control"  ng-change="changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, false)"/>';
                            templateTab = templateTab + getModel(elements[i], i);
                            templateTabs = templateTabs + '<span class="filters-see m-l" ng-show="filter.' + elements[i].name + '.model.dataTo || filter.' + elements[i].name + '.model.dataFrom"><a href="" ng-click="filter.' + elements[i].name + '.model=undefined; changeDateValue(filter.showLegend,filter.'+elements[i].name+'.model, true)" title="{{\'global.literals.remove\' | translate}}" aria-label="{{\'global.literals.remove\' | translate}}">x</a> ';
                            templateTabs = templateTabs + '<label ng-show="filter.' + elements[i].name + '.model.dataFrom" translate="'+elements[i].label+'"> </label> ';
                            templateTabs = templateTabs + '<label ng-show="filter.' + elements[i].name + '.model.dataFrom">{{filter.' + elements[i].name + '.model.dataFrom | date:\'dd/MM/yyyy\'}}</label> ';
                            templateTabs = templateTabs + '<label ng-show="filter.' + elements[i].name + '.model.dataTo" translate="global.literals.dateTo"> </label> ';
                            templateTabs = templateTabs + '<label ng-show="filter.' + elements[i].name + '.model.dataTo">{{filter.' + elements[i].name + '.model.dataTo | date:\'dd/MM/yyyy\'}}</label> ';
                            templateTabs = templateTabs + '</span>';

                            j++;
                            break;
                    }

                    template = template + '         </div>';
                    template = template + '     </div>';
                    template = template + ' </div>';

                    if(i== (elements.length-1) || j % numTableRows === numTableRows-1) {
                        template = template + '</div>';
                    }
                }
            }

            template = template + '</div>';
            template = template + '</div>';
            template = template + '</form>';
            template = template + '</div>';
            templateTab = templateTab + ') && filter.showLegend">';
            templateTab = templateTab + '<button type="button" class="btn-xs grey-500 text-white btn" ng-click="clearFilters()" translate="global.literals.no_filters">No aplicar cap filtre</button>';
            templateTab = templateTab + templateTabs;
            templateTab = templateTab + "</div>";
            template = template + templateTab;

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                filter: '=filterObject',
                dofilter: '&filterFunction',
                advancedSearch: '@',
                collapsed: '='
            },
            link: function(scope, element, attr) {

                scope.changeTree = function() {
                    if(scope.filter.showLegend) {
                        scope.dofilter()
                    }
                };


                scope.userChosenFilters     = []; // Camps afegits per l'usuari (rows)
                scope.userAvailableFilters  = []; // Filtres disponibles actualment
                scope.customAddedFields     = []; // Contingut dels filtres adicionals: input, select, date
                scope.copyAvailableFilters  = []; // Copia de tots els filtres disponibles

                // Seleccionar un camp per filtrar
                scope.changeUISelect  = function($item, $model) {

                    var currentlyIndex = scope.getFilterItemIndex($item.name, "name", scope.userAvailableFilters);

                    // Eliminem el item afegit dels disponibles dels selects
                    if (scope.userAvailableFilters.length >= 1){
                        scope.userAvailableFilters.splice(currentlyIndex, 1);
                    }

                    // Afegir el camp corresponent
                    if (!scope.checkIfExistsFilterItem($item.name, "name", scope.customAddedFields)) {
                        if ($item.type == "select" || $item.type == "select-multiple"){
                            var values = [];
                            angular.forEach($item.listValues, function (v, i) {
                                if (v.name) {
                                    values.push({id: (v.id && v.id != '') ? v.id: null, name: $filter('translate')(v.name)});
                                } else {
                                    values.push({id: v.id, name: v[$rootScope.app.langColumn], language1: v[$rootScope.app.langColumn], language2: v[$rootScope.app.langColumn], language3: 'NotTranslated'});
                                }
                            });
                            $item.listValues = values;
                            $item.model = {id: $item.model.id, name: ($item.model.name) ? $filter('translate')($item.model.name) : ''};
                        }
                        scope.customAddedFields.push($item);
                    }

                    // Marquem tots els camps com a "eliminables" perque no en queden més per definir
                    if(scope.customAddedFields.length == scope.copyAvailableFilters.length){
                        angular.forEach(scope.userChosenFilters, function (v, i) {
                            v.initial = false;
                        })
                    }
                    // Al últim element només deixem eliminar el camp
                    else {
                        scope.userChosenFilters[scope.userChosenFilters.length-1].initial = true;
                    }
                };


                // Eliminar un camp del filtre adicional
                scope.removeCustomFilter = function(index, selectedItem){

                    // Si els eliminem tots, restaurem els filtres disponibles
                    if (scope.customAddedFields.length == 1){

                        var currentlyIndex = scope.getFilterItemIndex(selectedItem.name, "value.name", scope.userChosenFilters);

                        if (currentlyIndex !== -1){
                            scope.userChosenFilters[currentlyIndex].value = undefined;
                            scope.userChosenFilters[currentlyIndex].initial = true;
                            scope.customAddedFields.splice(index, 1);
                        }
                        // Si eliminem el primer camp mentres escollim el segon
                        else {
                            scope.userChosenFilters[0].value = undefined;
                            scope.userChosenFilters[0].initial = true;
                            scope.userChosenFilters.splice(1, 1);
                            scope.customAddedFields.splice(index, 1);
                        }
                        angular.copy(scope.copyAvailableFilters, scope.userAvailableFilters);
                    }
                    // Recuperem el filtre per la selecció del element eliminat
                    else if(scope.customAddedFields.length > index){

                        scope.userChosenFilters.splice(index, 1);
                        scope.customAddedFields.splice(index, 1);
                        scope.userChosenFilters[scope.userChosenFilters.length-1].initial = true;

                        var currentlyAdded = scope.checkIfExistsFilterItem(selectedItem.name, "name", scope.userAvailableFilters);
                        var currentlyIndex = scope.getFilterItemIndex(selectedItem.name, "name", scope.copyAvailableFilters);

                        if(!currentlyAdded){
                            scope.userAvailableFilters.push(scope.copyAvailableFilters[currentlyIndex]);
                        }
                    }
                    else {}

                    // No cal executar la cerca si no s'ha introduit criteri de cerca
                    if(scope.filter[selectedItem.name].model != undefined && scope.filter[selectedItem.name].model != ""){
                        scope.filter[selectedItem.name].model = "";
                        scope.changeValue(true, true,  selectedItem.name);
                    }
                };


                // Afegeix un filtre avançat a la cerca
                scope.addCustomFilter = function(index){

                    var filter = [];
                    angular.forEach(scope.userAvailableFilters,function(value, key) {
                        var field = {
                            name: value.name,
                            label: value.label,
                            type: value.type
                        };
                        filter.push(field);
                    });

                    if(scope.userChosenFilters.length >0){
                        scope.userChosenFilters[scope.userChosenFilters.length-1].initial = false;
                    }
                    scope.userChosenFilters.push({ initial: true});
                };


                // Al canviar estat d'algun filtre adicional, executar filtre
                scope.changeValue = function(show, textbox, model) {
                    if(show) {
                        if(!textbox) {
                            scope.dofilter();
                        } else if(textbox && (this.filter[model].model == '' || this.filter[model].model.length >= 3)) {
                            scope.dofilter();
                        }
                    }
                };


                // Al canviar select d'algun filtre adicional, posar model del filtre i executar cerca
                scope.changeSelectValue = function(show, name, item){

                    if(show && this.filter[name].model != undefined && item.id != '') {
                        this.filter[name].model = item;
                        scope.dofilter();
                    }
                };

                scope.changeSelectMultipleValue = function(show, name, item){
                    if(show && this.filter[name].model != undefined && item.id != '') {
                        scope.dofilter();
                    }
                };

                // Al canviar un input dateRange d'algun filtre adicional, executar cerca
                scope.changeDateValue = function(show, model, remove) {
                    if(show) {
                        if (remove) {
                            scope.dofilter();
                        } else {
                            if (model) {
                                if (model.dataFrom && model.dataTo && model.dataFrom <= model.dataTo) {
                                    scope.dofilter();
                                } else {
                                    scope.dofilter();
                                }
                            }
                        }
                    }
                };


                // Obté el index d'un array donat una propietat i el seu nom
                scope.getFilterItemIndex = function(itemName, prop, filter){
                    var index = -1;
                    angular.forEach(filter, function (elem, key) {
                        if (elem[prop] === itemName){
                            index = key;
                        }
                    });
                    return index;
                };


                // Comprova si existeix una propietat al array
                scope.checkIfExistsFilterItem = function (itemName, prop, collection) {
                    var found = false;
                    angular.forEach(collection, function (elem, i) {
                        if (elem[prop] === itemName){
                            found = true;
                        }
                    });
                    return found;
                };


                // Netejar filtres
                scope.clearFilters = function() {
                    cleanFilters(scope);
                };


                var el = '';
                if(attr['idObject']) {
                    el = getTemplate(attr['idObject'], scope.filter, attr['filterFunction'], scope.collapsed, scope);
                }
                else{
                    if(scope.advancedSearch) {
                        el = getTemplateAdvancedSearch(attr['id'], scope.filter, attr['filterFunction'], scope);
                    }
                    else{
                        el = getTemplate(attr['id'], scope.filter, attr['filterFunction'], scope.collapsed, scope);
                    }
                }

                var compiled = $compile(el)(scope);
                element.html(compiled).show();
            }
        }
    }]);
