/**
 * Created by osirvent on 27/09/2016.
 */
angular
    .module('annexaApp')
     .factory('AnnexaEntityFactory',['$rootScope', 'AnnexaFormsFactory', 'BoxFactory', 'ThirdFactory', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', '$state', 'ModalService', 'ErrorFactory', '$q', 'Language', '$filter', 'RegFactory', 'RestService', 'TramFactory', 'CustomFieldFactory', 'dialogs', 'AnnexaPermissionsFactory', 'CommonService', 'TaskFactory','NotificationFactory', 'SecFactory', 'AnnexaPlugins', 'GlobalDataFactory', 'DccumentsFactory', 'DialogsFactory', 'apiAdmin', '$http','TerritoryFactory', 'TasksModals', 'AnnexaPermissionsFactory', 'TableFilter', 'DTColumnBuilder', 'AnnexaModalFactory', 'TramNewFactory', 'NotificationFactory',
    function ($rootScope, AnnexaFormsFactory, BoxFactory, ThirdFactory, AnnexaFormlyFactory, globalModals, HelperService, $state, ModalService, ErrorFactory, $q, Language, $filter, RegFactory, RestService, TramFactory, CustomFieldFactory, dialogs, AnnexaPermissionsFactory, CommonService, TaskFactory, NotificationFactory, SecFactory, AnnexaPlugins, GlobalDataFactory, DccumentsFactory, DialogsFactory, apiAdmin, $http, TerritoryFactory, TasksModals, AnnexaPermissionsFactory, TableFilter, DTColumnBuilder, AnnexaModalFactory, TramNewFactory, NotificationFactory) {
    	var factory = {};

        //region General
        var thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        var docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
        var secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
        var dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        var regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();
        var regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();

        var showModal = function(modal, entity, pluginId, pluginObject) {
            modal.annexaFormly = entity.form;
            modal.boxes = entity.boxes;
            modal.submitModal = modal.annexaFormly.onSubmit;
            modal.alerts = [];
            entity.modal = modal;

            ModalService.showModal({
                template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                controller: 'AnnexaFormlyModalController',
                modalObj: entity
            }).then(function(modalThen) {
                modal.modal = modalThen;
                modal.close = modalThen.element.scope().close;
                modal.annexaFormly.close = modalThen.element.scope().close;
                modalThen.element.modal();
                angular.element(".modal-title").focus();
                if(pluginId && pluginObject){
                    AnnexaPlugins.addPlugin(modalThen.element.scope(), pluginObject, pluginId);
                }
            });
        };

        var getBox = function(type, renderType, box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, entryType, documents, receivers, aditional_filter, byEntryType, onlyOne) {
            var box ;

            var thirdExternalSearch = $rootScope.app.configuration.conectaNucli_config && $rootScope.app.configuration.conectaNucli_config.active;

            switch (type) {
                case 'document':
                    box = angular.copy(BoxFactory.DocumentBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory);
                    break;
                case 'third':
                    box = angular.copy(BoxFactory.ThirdBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, "third", "x => x.third.id", undefined, renderType, thirdExternalSearch);
                    break;
                case 'receiver':
                    box = angular.copy(BoxFactory.ThirdBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, "receiver", "x => x.third.id", undefined, renderType, thirdExternalSearch);
                    break;
                case 'representant':
                	var selectedFunction =  function (x) {
                		if(ThirdFactory.Third && ThirdFactory.Third.id){
                			if(x && x.agent && x.agent.id && x.agent.id != ThirdFactory.Third.id){
                				return x.agent.id;
                			}else if(x && x.represented && x.represented.id && x.represented.id != ThirdFactory.Third.id){
                				return x.represented.id
                			}else{
                				return -1
                			}
                		}else{
                			if(x && x.agent && x.agent.id){
                				return x.agent.id;
                			}else if(x &&  x.represented && x.represented.id){
                				return x.represented.id
                			}else{
                				return -1
                			}
                		}
                	};
                	box = angular.copy(BoxFactory.ThirdBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, "representant", selectedFunction, aditional_filter, renderType, thirdExternalSearch);
                    break;
                case 'dossier':
                    box = angular.copy(BoxFactory.DossierBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, undefined, aditional_filter, undefined, onlyOne);
                    break;
                case 'dossier-related':
                    box = angular.copy(BoxFactory.DossierBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, undefined, aditional_filter,"x => x.related.id");
                    break;
                case 'registerEntry':
                    box = angular.copy(BoxFactory.RegisterBox).initialize(box_title, placeholder, advanced_search_title, entryType, newButton, searchButton, advancedSearchButton, searchAddButton, factory, byEntryType, onlyOne);
                    break;
                case 'documentChild':
                    box = angular.copy(BoxFactory.DocumentChildBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory);
                    break;
                case 'dossierTransaction':
                    box = angular.copy(BoxFactory.DossierTransactionBox).initialize(box_title, placeholder, advanced_search_title,false,false,false, Language.getActiveColumn());
                    break;
            }
            
            if(box) {
                box.boxDefinition.content = [];
                box.boxDefinition.saveSearch = undefined;
                box.boxDefinition.deleteFunction = function (objectId, index) {
                    box.boxDefinition.content.splice(index, 1);
                };
                box.boxDefinition.renderType = renderType;
            }
           
            return box;
        };

        //endregion

        //region Documents
        var documentFormFields = function (tram_templates, document_types, thirds, transaction) {
            var form = new AnnexaFormly();

            var fields = {};

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong',
                extralabel: true,
                clear: function($event,model,key, $select) {
                    $event.stopPropagation();
                    model[key] = undefined;
                    if($select) {
                        $select.selected = undefined;
                        $select.search = undefined;
                    }
                }
            };

            var field1 = form.createField(
                'tramTemplate',
                'annexaSelectGroupRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.template',
                    'id',
                    'literal',
                    [],
                    true,
                    undefined,
                    function(item, options) {
                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                        $rootScope.$broadcast('newTemplateSelectedDocumentType', { item: item });
                    }
                ),
                data
            );
            fields.tramTemplate = field1;
            var field2 = form.createField(
                'documentTitle',
                'annexaInputRow',
                'col-sm-12',
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.title',
                    true
                ),
                data
            );
            field2.templateOptions.updateOnBlur = true;
            fields.documentTitle = field2;

            if(HelperService.isSuperAdmin()) {
            	var sections = GlobalDataFactory.sections;
            } else {
            	var sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
            }
            sections = new SortedArray(sections, Language.getActiveColumn()).sort();
            var field3 = form.createField(
                'section',
                'annexaSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.section',
                    'id',
                    Language.getActiveColumn(),
                    sections,
                    true
                ),
                data
            );
            fields.section = field3;

            /*if(HelperService.isSuperAdmin()) {
                var profiles = GlobalDataFactory.allProfiles;
            } else {*/
                var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
            //}
            var profilesExp = $linq(profiles).intersect($linq(transaction.processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();

            var field4 = form.createField(
                'profiles',
                'annexaMultipleSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.profiles',
                    'id',
                    Language.getActiveColumn(),
                    profilesExp,
                    true
                ),
                data
            );
            fields.profiles = field4;

            if(thirds && thirds.length > 0){
                var field5 = form.createField('thirdsName',
                    'annexaLabel',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(undefined, $filter('translate')('global.literals.receivers'), false, false, undefined),
                    data);
                fields.thirdLabel = field5;
                
                fields.addReciversButton = form.createField('receiversBtn','annexaLabelButton','col-sm-12',
                        new AnnexaFormlyFieldLabelButtonTemplateOptions('text',undefined,$filter('translate')('global.literals.addReceiversBtn'),undefined,undefined,undefined,
                                'btn-sm',undefined,undefined,undefined),
                        data);
                
                fields.thirds = [];
                var i = 0;
                var selectedThirds = [];
                
                var field = form.createField(
                    'receivers',
                    'annexaLabelsRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(undefined, selectedThirds, false, false, undefined),
                    {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: 'label-value',
                        extralabel: true,
                        type: 'HTMLOneModel'
                    }
                );
                fields.thirds.push(field);
                
            }

            var documentTypesOptions = [];
            
            if(document_types && document_types.length > 0){
            	_.forEach (document_types, function(docType){
                    if(docType.profiles && docType.profiles.length > 0){
                        // intersection de perfil de usuario y los perfiles de documentTypeProfile
                        var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                            documentTypesOptions.push(angular.copy(docType));
                        }
                    }
                    else{
                        documentTypesOptions.push(angular.copy(docType));
                    }
                });
            }else if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0) {
                _.forEach (GlobalDataFactory.documentTypes, function(docType){
                    if(docType.profiles && docType.profiles.length > 0){
                        // intersection de perfil de usuario y los perfiles de documentTypeProfile
                        var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                            documentTypesOptions.push(angular.copy(docType));
                        }
                    }
                    else{
                        documentTypesOptions.push(angular.copy(docType));
                    }
                });
            }

            var field6 = form.createField(
                'typeSelect',
                'annexaSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.type',
                    'id',
                    Language.getActiveColumn(),
                    documentTypesOptions,
                    true,
                    undefined,
                    function(item, options) {
                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                        $rootScope.$broadcast('newDocumentTypeSelectedTramTemplate', { item: item });
                    }
                ),
                data
            );
            fields.documentType = field6;
            
            var field7 = form.createField(
        		'archiveClassification',
        		'annexaSelectTreeRow',
        		'col-sm-12',
        		new AnnexaFormlyFieldSelectTemplateOptions(
    				'global.literals.classificationBox',
    				'id',
    				Language.getActiveColumn(),
    				CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn())
    				,false
        		)
        		,data
            );
            fields.archiveClassification = field7;
            
            var fieldObservations = form.createField(
                    'observations',
                    'annexaTextAreaRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'),
                    data
                );
           fields.observations = fieldObservations;
            
            var field8 = form.createField(
        		'onlySpecificTemplate',
        		'annexaCheckbox',
        		'col-sm-12',
        		{
        			type: 'checkbox', 
        			label: 'global.tram.literals.onlySpecificTemplates', 
        			required:false
        		}
        		,data
        	);                
            fields.onlySpecificTemplate = field8;
            var field9 = {key: 'documentCustomFields',className: '',templateOptions: {},fieldGroup: [],
                    hideExpression: function ($viewValue, $modelValue, scope) {
                    	if(scope.model && scope.model.documentCustomFieldsShow){
                    		return false;
                    	}else{
                    		return true;
                    	}
                    }};
            fields.documentCustomFields = field9;
            return fields;
        };

        var documentFromTemplateForm = function(transaction,tram_templates,document_types, modal, boxDefinition, dossier, idModal, isShowTransaction, relatedDocument, dossierThirds, requiredAndCannotCreateDocument) {
            var fields = new documentFormFields(tram_templates,document_types, ((dossier && dossier.thirds && dossier.thirds.length > 0)? dossier.thirds: ((dossierThirds)?dossierThirds:[])), transaction);
            var newModel = { row1: {}, row2: {}, documentCustomFields: {}, origDocumentType:undefined };
            var trans = [];
			if(dossier && dossier.dossierTransactions && dossier.dossierTransactions.length > 0){
				var transAux = $linq(dossier.dossierTransactions).orderByDescending("x => x.createdDate").thenByDescending("x => x.id").toArray();
				_.forEach(transAux, function(tran, key){
					trans.push({id:tran.id, name:(dossier.dossierTransactions.length - key)+" "+tran[Language.getActiveColumn()]});
				});
			}
            var form = new AnnexaFormly();
            
            _.forEach(tram_templates, function (value, key) {
                if(transaction && transaction.dossier && transaction.dossier.procedure && value.procedures 
                		&& $linq(value.procedures).contains(transaction.dossier.procedure, "(x, y) => x.id == y.id")) {
                	value.literal = '<strong>' + value[Language.getActiveColumn()] + '</strong>';
                } else {
                	value.literal = value[Language.getActiveColumn()];
                }
            });
            
            form.tramTemplates = tram_templates;
            fields.tramTemplate.controller = ['$scope','$rootScope', 'Language', 'DccumentsFactory',function ($scope, $rootScope, Language, DccumentsFactory) {
                var fillOptions = function(newOptions){
                	if($scope.to.options){
                		$scope.to.options.length = 0;
                	}else{
                		$scope.to.options = [];
                	}
                	if(newOptions && newOptions.length > 0){
                		_.forEach(newOptions, function(op){
                			$scope.to.options.push(angular.copy(op));
                		});
                	}
                }
            	
            	var setOptions = function(documentType){
                    if(documentType && $scope.to.tramTemplates){
                        var opts = $linq($scope.form && $scope.to.tramTemplates).where("x => !x.documentType || !x.documentType.id || x.documentType.id == "+documentType).toArray();
                        fillOptions(opts);
                        if($scope.model.tramTemplate && !$linq($scope.to.options).contains($scope.model.tramTemplate, "(x, y) => x.id == y")){
                        	$scope.model.tramTemplate =	undefined;
                        }
                    }else{
                    	fillOptions($scope.to.tramTemplates);
                    }
                }
                $scope.$on('newDocumentTypeSelectedTramTemplate', function(event, args){ 
                        if(args && args.item && args.item.id){
                            setOptions(args.item.id);
                        }else{
                        	fillOptions($scope.to.tramTemplates);
                        }
                        DccumentsFactory.modifyCustomFields(((args && args.item && args.item.id)?args.item.id:undefined), form);
                    }
                )
                setOptions($scope.to.typeSelect);
            	$scope.to.groupFunction = function(item){
            		if(item.documentType && item.documentType.id){
            			return $filter('translate')("global.tram.literals.templatesOf")+" "+item.documentType[Language.getActiveColumn()];
            		}else{
            			return $filter('translate')("global.tram.literals.templateGeneric");
            		}
            	}
            	$scope.to.orderByFunction = function(item){
            		if(item.documentType){
            			return "[ !"+item.documentType+", -"+item.documentType+" ]";
            		}else{
            			return null;
            		}
            	}
            	$scope.$on('showSpecificTemplates', function(event, args){ 
                    if(args && args.onlySpecificTemplate) {
                    	var newOptions = [];
                    	var exist = false;
                    	_.forEach(tram_templates, function (value, key) {
                            if(transaction && transaction.dossier && transaction.dossier.procedure && value.procedures 
                            		&& $linq(value.procedures).contains(transaction.dossier.procedure, "(x, y) => x.id == y.id")) {
                            	if($scope.model.tramTemplate == value.id) {
                            		exist = true;
                            	}
                            	newOptions.push(value);
                            }
                        });
                    	if(!exist) {
                    		$scope.model.tramTemplate =	undefined;
                    	}
                    	fillOptions(newOptions);
                    } else {
                    	fillOptions(form.tramTemplates);
                    }
                }
            )
            
            $scope.$on('newTemplateSelectedDocumentType', function(event, args){
            	var fth = undefined;
            	if(form && form.fields){
            		fth = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'row17'");
            		if(fth && fth.fieldGroup){
            			if(form.model){
            				form.model.row17 = {};
            			}
            			fth.fieldGroup.length = 0
	            		if(args && args.item && args.item.transactions && args.item.transactions.length > 0){
	            			var data = {
    			                row: true,
    			                colClass: 'col-sm-12',
    			                labelClass: 'label-strong',
    			                extralabel: true,
    			                clear: function($event,model,key, $select) {
    			                    $event.stopPropagation();
    			                    model[key] = undefined;
    			                    if($select) {
    			                        $select.selected = undefined;
    			                        $select.search = undefined;
    			                    }
    			                }
    			            };
	            			 _.forEach(args.item.transactions, function(transaction){
	            				 fth.fieldGroup.push(form.createField(transaction.keyId,'annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions(transaction[Language.getActiveColumn()],'id','name',trans,false),data));
	            			 });
	            			
	            		}
            		}
            	}
        	})
        	
            }];
            fields.documentType.data.clear = function ($event, model, key, $select, to) {
                $event.stopPropagation();
                model[key] = undefined;
                if ($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
                if(to.onSelected) {
                    to.onSelected(undefined, undefined);
                }
            }
            
            fields.onlySpecificTemplate.controller =['$scope','$rootScope', 'HelperService',function ($scope, $rootScope, HelperService) {
            	$scope.to.onlySpecificTemplate = (($scope && $scope.model && $scope.model.onlySpecificTemplate)?$scope.model.onlySpecificTemplate:undefined);
            	$scope.to.onChange = function(){
            		if($scope && $scope.model && $scope.model.onlySpecificTemplate !== undefined && $scope.model.onlySpecificTemplate !== $scope.to.onlySpecificTemplate) {
            			$scope.to.onlySpecificTemplate = $scope.model.onlySpecificTemplate;
            			$rootScope.$broadcast('showSpecificTemplates', {onlySpecificTemplate: $scope.model.onlySpecificTemplate});
            		}
                };
            }];
            
            fields.documentTitle.controller = ['$scope','$rootScope', 'HelperService', 'Language',function ($scope, $rootScope, HelperService, Language) {
            	$scope.to.onFocus = function(viewValue){
            		if($rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
            			var typeSelect = angular.element('#'+idModal).scope().modalObj.form.model.row7.typeSelect;
            			var templateSelect = angular.element('#'+idModal).scope().modalObj.form.model.row1.tramTemplate;
            			var patternProperties = HelperService.getPropertiesInPattern();
            			var documentNamePattern =  $rootScope.app.configuration.document_name_pattern.value;
            			var selectTemplate;
            			if(patternProperties && (patternProperties.acronym || patternProperties.actualDate)){
            				if(typeSelect){
                        	var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + typeSelect);
                				if(templateSelect){
                					selectTemplate = $linq(form.tramTemplates).singleOrDefault(undefined, "x => x.id ==" + templateSelect);
                					if(selectTemplate[Language.getActiveColumn()]!= undefined){
                						selectTemplate = selectTemplate[Language.getActiveColumn()];
                					}else{
                						selectTemplate = selectTemplate.language1;
                					}
								}
                				documentNamePattern =  documentNamePattern.replace(new RegExp("\\]\\[","g"), "] [");
                				if(selectedType || selectTemplate){
                					var changeName = function (acronym, pattern, title){
                						if(pattern.acronym){
                							title = title.replace(pattern.acronym.name, acronym);
										}
                						if(pattern.actualDate){
                							var actualDate = $filter('date')(new Date(), 'ddMMyyyy');
                							title = title.replace(pattern.actualDate.name, actualDate);
                            		}
                        				
                						return title;
												}
                					if(($scope.lastSelectedType && $scope.lastSelectedType != selectedType.acronym) || $scope.lastSelectedTemplate ){
                						if($scope.lastSelectedType){
                							var pos = undefined;
                							if($scope.model.documentTitle){
                								var pos = $scope.model.documentTitle.match(new RegExp('\\b'+$scope.lastSelectedType+'\\b'));
                							}
                    						if(pos && templateSelect != undefined){
                    							$scope.model.documentTitle = $scope.model.documentTitle.replace(new RegExp('\\b'+$scope.lastSelectedType+'\\b'), selectedType.acronym);
											}else{
                    							documentNamePattern = changeName(selectedType.acronym, patternProperties, documentNamePattern);
                    							var positionName = documentNamePattern.indexOf(patternProperties.name.name);
                    							if(selectTemplate){
                    								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, selectTemplate);
                            					}else{
                    								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, "");
												}
                                				
                    							setTimeout(function() {
                        							HelperService.setSelectedRange('title', positionName);
                    							}, 1);
											}
                    						if($scope.lastSelectedTemplate){
                    							var pos = undefined;
                    							if($scope.model.documentTitle){
                    								pos = $scope.model.documentTitle.match(new RegExp('\\b'+$scope.lastSelectedTemplate+'\\b'));
                    							}
                        						if(pos && templateSelect){
                        							$scope.model.documentTitle = $scope.model.documentTitle.replace(new RegExp('\\b'+$scope.lastSelectedTemplate+'\\b'), selectTemplate);
                        						}else{
                        							documentNamePattern = changeName(selectedType.acronym, patternProperties, documentNamePattern);
                        							var positionName = documentNamePattern.indexOf(patternProperties.name.name);
                        							if(selectTemplate){
                        								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, selectTemplate);
                        							}else{
                        								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, "");
                        							}
                        							setTimeout(function() {
                            							HelperService.setSelectedRange('title', positionName);
                        							}, 1);
                        						}
                        					}
                    					}
                					}
                					if(!$scope.lastSelectedType || !$scope.lastSelectedTemplate){
                						documentNamePattern = changeName(selectedType.acronym, patternProperties, documentNamePattern);
            							var positionName = documentNamePattern.indexOf(patternProperties.name.name);
            							if(selectTemplate){
            								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, selectTemplate);
											}else{
            								$scope.model.documentTitle = documentNamePattern.replace(patternProperties.name.name, "");
											}
            							
                        				
            							setTimeout(function() {
                							HelperService.setSelectedRange('title', positionName);
            							}, 1);
                					}
                					if(selectedType){
                						$scope.lastSelectedType = ((patternProperties && patternProperties.acronym)?selectedType.acronym:'');
                					}
                					if(selectTemplate){
                						$scope.lastSelectedTemplate = selectTemplate;
										}else{
                						$scope.lastSelectedTemplate = undefined;
										}
                                    }
                            	}
            			} else if(viewValue){
            				$scope.model.documentTitle = viewValue;
                            }
                        }
            		}
            }];
            
            fields.documentType.controller = ['$scope','$rootScope', 'Language', 'DccumentsFactory',function ($scope, $rootScope, Language, DccumentsFactory) {
            	$scope.$on('newTemplateSelectedDocumentType', function(event, args){ 
            			var docTypeId = undefined;
            			if(args && args.item && args.item.id){
            				if($scope.to.documentTypes && $scope.to.tramTemplates){
            					var documentType = $linq($scope.form && $scope.to.tramTemplates).first("x => x.id == "+args.item.id).documentType;
                    			if(documentType && documentType.id) {
                    				$scope.model.typeSelect = documentType.id;
                    				docTypeId = documentType.id;
                    			}
            				}
            			}
            			if(!docTypeId && form && form.model && form.model.row7 && form.model.row7.typeSelect){
            				docTypeId = form.model.row7.typeSelect;
            			}
            			DccumentsFactory.modifyCustomFields(docTypeId, form);
            		}
            	)
            }];
            
            fields.tramTemplate.templateOptions.tramTemplates = tram_templates;
            
            fields.documentType.templateOptions.documentTypes = document_types;
            fields.documentType.templateOptions.tramTemplates = tram_templates;
            form.addGroup('row7', '', [fields.documentType], 'annexaRow');
            form.addGroup('row10', '', [fields.onlySpecificTemplate], 'annexaRow');
            form.addGroup('row1', '', [fields.tramTemplate], 'annexaRow');
            form.addGroup('row2', '', [fields.documentTitle], 'annexaRow');
			if(fields.section.templateOptions.options.length>1) {
				newModel.row3 = {};
                form.addGroup('row3', '', [fields.section], 'annexaRow');
            }
            if(fields.profiles.templateOptions.options.length>1) {
                newModel.row4 = {};

                var hideProfile = function($viewValue, $modelValue, scope) {
                    return scope.model && scope.model.row4 && scope.model.row4.profile;
                };

                form.addGroup('row4', '', [fields.profiles], 'annexaRow');

                var defaultProfile ;

                if ($rootScope.LoggedUser.userDefaultEntityProfiles) {
                    var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'DOC'").select("x => x.profile").toArray();

                    if (profiles && profiles.length > 0) {
                        defaultProfile = profiles[0];
                    }
                }

                if(transaction.processProfiles == 1) {
                    newModel.row4.profiles = [transaction.processProfiles[0].profile.id];
                } else {
                    if(defaultProfile) {
                        if($linq($linq(transaction.processProfiles).select("x => x.profile").toArray()).count("x => x.id == " + defaultProfile.id) > 0) {
                            newModel.row4.profiles = [defaultProfile.id];
                        }
                    }
                }
            }
            form.addGroup('row8', '', [fields.archiveClassification], 'annexaRow');
            form.addGroup('row9', '', [fields.observations], 'annexaRow');
            if(dossier && dossier.archiveClassification && dossier.archiveClassification.model && dossier.archiveClassification.model.$selected ){
            	newModel.row8 = {archiveClassification:{$selected:angular.copy(dossier.archiveClassification.model.$selected)}};
            }else{
            	newModel.row8 = {archiveClassification:{}};
            }
            if(fields.thirdLabel){
                var hideFunction = function($viewValue, $modelValue, scope){
                    var hide = true;
                    if(scope.model && scope.model.row1 && scope.model.row1.tramTemplate && form.tramTemplates && form.tramTemplates.length > 0){
                        var defaults = $linq(form.tramTemplates).where('x => x.id == '+scope.model.row1.tramTemplate+' && x.allowThirds == true').toArray();
                        if(defaults && defaults.length > 0){
                            hide = false;
                        }
                    }
                    return hide;
                };
                
                var modalSelectMultReceivers = function() {
                	var modal = angular.copy(globalModals.selectMultipleReceiversCheckbox);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.model.modal_body.selectValues = {};
                    
                    var thirds = ((dossier && dossier.thirds)?dossier.thirds:((dossierThirds)?dossierThirds:[]));
					if(thirds && thirds.length >0){
						_.forEach(thirds, function(third){
							if(third.dossier){
								third.dossier = {dossier:((third.dossier)?third.dossier.id:undefined)}
							}
						});
					}
                    thirds = $linq(thirds).orderBy("x => x.third.completeName", linq.caseInsensitiveComparer).toArray();
                    var selectableOptions = [];
                    _.forEach(thirds, function (value, key) {
                    	modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                    });
                    _.forEach(form.model.row6, function (value, key) {
                        modal.annexaFormly.model.modal_body.selectValues[value.id] = true;
                    });
                    
                    angular.forEach(thirds, function (value, key) {
                        var receiver = angular.copy(value);
                        var name = ((receiver.third.name)?receiver.third.name+" ":"") + ((receiver.third.surname1Particles)?receiver.third.surname1Particles+" ":"") + ((receiver.third.surename1)?receiver.third.surename1+" ":"") + ((receiver.third.surname2Particles)?receiver.third.surname2Particles+" ":"") + ((receiver.third.surename2)? receiver.third.surename2+" " : "" ) + ((receiver.third.corporateName)?receiver.third.corporateName:"");
                        var destinatariType = '';
                        if(receiver.interested){
                        	var roleInterested = '';
                            if(receiver.roleInterested) {
                            	roleInterested = '(' + receiver.roleInterested[Language.getActiveColumn()] + ')';
                            }
                            destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
                        }else{
                            var representated = '';
                            if(value.representated && value.representated.identificationDocument){
                                if(value.representated.name){
                                    representated= '(' + value.representated.name + ((value.representated.surname1Particles)?' '+value.representated.surname1Particles : '') + ' ' + value.representated.surename1 + ((value.representated.surname2Particles)?' '+value.representated.surname2Particles : '') + ((value.representated.surename2)?' '+value.representated.surename2 : '') + ' (' + value.representated.identificationDocument + ')';
                                }else{
                                    representated= '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                                }
                            }
                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                        }
                        selectableOptions.push({ id: value.id, value: name, typeName: destinatariType});
                    });
                    
                    modal.annexaFormly.model.modal_body.thirds = thirds;
                    modal.annexaFormly.model.modal_body.selectableOptions = selectableOptions;
                    modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = selectableOptions;
                    
                    var submitFunction = function () {
                    	
                    	var selectedOptions = [];
                    	var slectedThirdsLabel = '';
                    	var labels = [];
                    	if(this.annexaFormly.model.modal_body.selectValues){
                        	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
                            if(keys && keys.length > 0){
                                _.forEach(keys, function(key){
                                    if(modal.annexaFormly.model.modal_body.selectValues[key]){
                                    	var sel = $linq(thirds).firstOrDefault(function (x) { return x.id == parseInt(key); });
                                    	selectedOptions.push(sel);
                                    	var name = ((sel.third.name)?sel.third.name+" ":"") + ((sel.third.surname1Particles)?sel.third.surname1Particles+" ":"") + ((sel.third.surename1)?sel.third.surename1+" ":"") + ((sel.third.surname2Particles)?sel.third.surname2Particles+" ":"") + ((sel.third.surename2)? sel.third.surename2+" " : "" ) + ((sel.third.corporateName)?sel.third.corporateName:"");
                                        var destinatariType = '';
                                        if(sel.interested){
                                        	var roleInterested = '';
                                            if(sel.roleInterested) {
                                            	roleInterested = '(' + sel.roleInterested[Language.getActiveColumn()] + ')';
                                            }
                                            destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
                                        }else{
                                            var representated = '';
                                            if(sel.representated && sel.representated.identificationDocument){
                                                if(sel.representated.name){
                                                    representated= '(' + sel.representated.name + ((sel.representated.surname1Particles)?' '+ sel.representated.surname1Particles : '') + ' ' + sel.representated.surename1 + ((sel.representated.surname2Particles)?' '+ sel.representated.surname2Particles : '') + ((sel.representated.surename2)?' '+sel.representated.surename2 : '') + ' (' + sel.representated.identificationDocument + '))';
                                                }else{
                                                    representated= '(' + sel.representated.corporateName + ' (' + sel.representated.identificationDocument + '))';
                                                }
                                            }
                                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                                        }
                                        slectedThirdsLabel = name + '[' + destinatariType + '] ';
                                        labels.push({label: name, type: destinatariType});
                                    }
                                });
                            }
                        }
						var fth = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'row6'");
						if(fth && fth.fieldGroup && fth.fieldGroup.length > 0 && form.fields[6].fieldGroup[0].templateOptions){
	                    	fth.fieldGroup[0].templateOptions.labels = $linq(labels).orderBy("x => x.label", linq.caseInsensitiveComparer).toArray();
						}
                    	form.model.row6 = selectedOptions;
                    	
                    	this.close();
                    }
                	AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
                }
                
                fields.addReciversButton.templateOptions.executeFunction = modalSelectMultReceivers;
                
                var thsAux = ((dossier && dossier.thirds && dossier.thirds.length > 0)? dossier.thirds: ((dossierThirds)?dossierThirds:[]));
                var selectedOptionsAux = [];
                var labelsAux = [];
                if(thsAux && thsAux.length > 0){
                	_.forEach(thsAux, function(thAux){
                		if(thAux.notifiable){
                			selectedOptionsAux.push(thAux);
                        	var name = ((thAux.third.name)?thAux.third.name+" ":"") + ((thAux.third.surname1Particles)?thAux.third.surname1Particles+" ":"") + ((thAux.third.surename1)?thAux.third.surename1+" ":"") + ((thAux.third.surname2Particles)?thAux.third.surname2Particles+" ":"") + ((thAux.third.surename2)? thAux.third.surename2+" " : "" ) + ((thAux.third.corporateName)?thAux.third.corporateName:"");
                			var destinatariType = '';
                            if(thAux.interested){
                            	var roleInterested = '';
                                if(thAux.roleInterested) {
                                	roleInterested = '(' + thAux.roleInterested[Language.getActiveColumn()] + ')';
                                }
                                destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
                            }else{
                            	var representated = '';
                                if(thAux.representated && thAux.representated.identificationDocument){
                                    if(thAux.representated.name){
                                        representated= '(' + thAux.representated.name + ((thAux.representated.surname1Particles)?' '+ thAux.representated.surname1Particles : '') + ' ' + thAux.representated.surename1 + ((thAux.representated.surname2Particles)?' '+ thAux.representated.surname2Particles : '') + ((thAux.representated.surename2)?' '+thAux.representated.surename2 : '') + ' (' + thAux.representated.identificationDocument + '))';
                                    }else{
                                        representated= '(' + thAux.representated.corporateName + ' (' + thAux.representated.identificationDocument + '))';
                                    }
                                }
                                destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                            }
                            labelsAux.push({label: name, type: destinatariType});
                		}
                	});
                }
                
                form.addGroup('row5', '', [fields.thirdLabel], 'annexaRow', hideFunction);
                form.addGroup('row6', '', fields.thirds, 'annexaRow', hideFunction);
                form.addGroup('row16', '', [fields.addReciversButton], 'annexaRow', hideFunction);
                newModel.row5 = {};
                newModel.row6 = {};
                newModel.row16 = {};
                if(labelsAux && labelsAux.length > 0){
                	var fth = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'row6'");
                	if(fth && fth.fieldGroup && fth.fieldGroup.length > 0 && form.fields[6].fieldGroup[0].templateOptions){
                		fth.fieldGroup[0].templateOptions.labels = $linq(labelsAux).orderBy("x => x.label", linq.caseInsensitiveComparer).toArray();
                	}
                	newModel.row6 = selectedOptionsAux;
                }
            }
            form.fields.push(fields.documentCustomFields);
            var typeSelect = {};
            newModel.row7 = {};
            if(fields.documentType && fields.documentType.templateOptions && fields.documentType.templateOptions.options && fields.documentType.templateOptions.options.length > 0) {
				if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPR.id
	               		&& transaction && transaction.transactionType && (transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id || transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id) && transaction.proposalSubtype != 'DECREE') {
					if(requiredAndCannotCreateDocument && requiredAndCannotCreateDocument.length > 0){
						var dtypesWithoutSPR = angular.copy(fields.documentType.templateOptions.options);
						dtypesWithoutSPR = $linq(dtypesWithoutSPR).where("x => x.id != "+$rootScope.app.configuration.sec_document_types.SPR.id[0]).toArray();
						fields.documentType.templateOptions.options = dtypesWithoutSPR;
						if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
							typeSelect = $linq(dtypesWithoutSPR).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
						}
						var errorFields = "<br/><br/>";
						_.forEach(requiredAndCannotCreateDocument, function(req){
							errorFields = errorFields + "    "+$filter('translate')(req)+"<br/>"; 
						});
						DialogsFactory.error($filter('translate')('global.sec.literals.proposalRequiredFields')+errorFields);
					}else{
						typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPR.id[0]);
					}
	            } else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPD.id
	              		&& transaction && transaction.transactionType && (transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id || transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id) && transaction.proposalSubtype == 'DECREE') {
                	if(requiredAndCannotCreateDocument && requiredAndCannotCreateDocument.length > 0){
						var dtypesWithoutSPR = angular.copy(fields.documentType.templateOptions.options);
						dtypesWithoutSPR = $linq(dtypesWithoutSPR).where("x => x.id != "+$rootScope.app.configuration.sec_document_types.SPD.id[0]).toArray();
						fields.documentType.templateOptions.options = dtypesWithoutSPR;
						if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
							factory.typeDefault = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
						}
						var errorFields = "<br/><br/>";
						_.forEach(requiredAndCannotCreateDocument, function(req){
							errorFields = errorFields + "    "+$filter('translate')(req)+"<br/>"; 
						});
						DialogsFactory.error($filter('translate')('global.sec.literals.proposalRequiredFields')+errorFields);
            		}else{
            			typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPD.id[0]);
					}
            	} else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.STR.id
            			&& transaction && transaction.transactionType && transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
            		typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.STR.id);
            	} else if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
                    typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
                }
                if (!typeSelect || !typeSelect.id) {
                    typeSelect = fields.documentType.templateOptions.options[0].id;
                }else{
                    typeSelect = typeSelect.id;
                }
            }
            newModel.row7.typeSelect = typeSelect;
            fields.tramTemplate.templateOptions.typeSelect = typeSelect;

            form.model = newModel;
            if(form.model.row7 && form.model.row7.typeSelect){
            	DccumentsFactory.modifyCustomFields(form.model.row7.typeSelect, form);
            }
            var submitNewDocumentFromDossierTemplate = function () {
                form.form.$setSubmitted();
                if(form.form.$valid) {
                    var hide = true;
                    var thirdsSelected = [];
                    if(form.model && form.model.row1 && form.model.row1.tramTemplate && form.tramTemplates && form.tramTemplates.length > 0){
                        var defaults = $linq(form.tramTemplates).where('x => x.id == '+form.model.row1.tramTemplate+' && x.allowThirds == true').toArray();
                        if(defaults && defaults.length > 0){
                            hide = false;
                        }
                    }

                    if(!hide && dossierThirds){
					    angular.forEach(dossierThirds,function(value, key) {
                        	var selThird = $linq(form.model.row6).singleOrDefault(undefined, "x => x.id == " + value.id);
                            if(form.model && form.model.row6 && selThird){
                                thirdsSelected.push({id:value.id, dossier:{id:dossier.id}, third:{id:value.third.id}});
                            }
                        });
                    }else if(!hide && dossier && dossier.thirds){
                        angular.forEach(dossier.thirds,function(value, key) {
                        	var selThird = $linq(form.model.row6).singleOrDefault(undefined, "x => x.id == " + value.id);
                            if(form.model && form.model.row6 && selThird){
                                thirdsSelected.push({id:value.id, dossier:{id:dossier.id}, third:{id:value.third.id}});
                            }
                        });
                    }
                    
                    if (!DccumentsFactory.validDocumentName(form.model.row2.documentTitle, form.model.row7.typeSelect)) {
                    	var errorMessage = $filter('translate')('global.errors.docNameFormat') + $rootScope.app.configuration.document_name_pattern.value;
                    	modal.alerts.push({msg: errorMessage});
                    } else if(!hide && thirdsSelected.length == 0){
                        modal.alerts.push({msg: 'global.errors.noDestinataris'}); 
                    } else {
                        $rootScope.showLoadingdivSignin = true;
                        var fields = new documentFormFields(tram_templates, document_types, ((dossier && dossier.thirds)?dossier.thirds:((dossierThirds)?dossierThirds:[])), transaction);
                        var documentModel = {};

                        if (form.model.row1) {
                            documentModel.tramTemplate = form.model.row1.tramTemplate;
                        }

                        if (form.model.row2) {
                            documentModel.documentTitle = form.model.row2.documentTitle;
                        }

                        if (form.model.row3 && form.model.row3.section) {
                            documentModel.section = ((form.model.row3.section.id)?form.model.row3.section.id : form.model.row3.section);
                        } else {
                            if (fields.section.templateOptions.options.length > 0) {
                                documentModel.section = fields.section.templateOptions.options[0][fields.section.templateOptions.valueProp];
                            } else {
                                documentModel.section = null;
                            }
                        }

                        if (form.model.row4) {
                            if(form.model.row4.profile) {
                                documentModel.profile = form.model.row4.profile;
                                dcoumentModel.profiles = undefined;
                            } else if(form.model.row4.profiles) {
                                documentModel.profile = undefined;
                                documentModel.profiles = form.model.row4.profiles;
                            }
                        } else {
                            documentModel.profile = undefined;
                            if (fields.profiles.templateOptions.options.length > 0) {
                                documentModel.profiles = [fields.profiles.templateOptions.options[0][fields.profiles.templateOptions.valueProp]];
                            } else {
                                documentModel.profiles = null;
                            }
                        }
                        if(form.model && form.model.row8.archiveClassification && form.model.row8.archiveClassification.$selected && form.model.row8.archiveClassification.$selected.id){
                        	documentModel.archiveClassification = { id: form.model.row8.archiveClassification.$selected.id };
                        }
                        if(form.model && form.model.row9 && form.model.row9.observations){
                        	documentModel.observations = form.model.row9.observations;
                        }
                        documentModel.thirdsSelected = thirdsSelected;
                        if(form.model && form.model.row7 && form.model.row7.typeSelect) {
                            documentModel.type = {id:  form.model.row7.typeSelect};
                        }
                        var calculateCFTGroups = function(newGroups){
	                    	var groups = [];
	                    	if(newGroups){
	                    		_.forEach(newGroups, function(ng){
	                    			_.forEach(ng.groups, function(g){
	                    				g.groups = calculateCFTGroups(g.newGroups); 
	                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
	                    				groups.push(g);
	                    			})
	                    		})
	                    	}
	                    	return groups;
	                    }
	                	var cf = CustomFieldFactory.getModelValues(form.model.documentCustomFields);
                        documentModel.customFields = [];
                        var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + documentModel.type.id);
                        if(documentType && cf && cf.length > 0) {
                            _.forEach(documentType.customFields, function(docTypeField) {
                            	var documentCustomFieldsField = $linq(form.fields).firstOrDefault({fieldGroup:[]}, "x => x.key == 'documentCustomFields'");
                            	var groupField = $linq(documentCustomFieldsField.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
                            	var docCustomField = undefined;
                            	if(groupField && groupField.data && groupField.data.customField){
                            		docCustomField = angular.copy(groupField.data.customField);
                            	}else{
                            		docCustomField = angular.copy(docTypeField);	
                            	}
                                docCustomField.id = undefined;
                                docCustomField.customField = { id: docTypeField.customField.id };
                                docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
	                            
                                var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

                                if(cfValue) {
                                    if(docTypeField.customField.frontendType == 'CHECKBOX'){
                                        var custom_field_selected = $linq(cf).where(function(x){
                                            if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                        ).toArray();
                                        if(custom_field_selected && custom_field_selected.length > 0){
                                            docCustomField.value = '[';
                                            _.forEach(custom_field_selected, function (item, key) {
                                                if(item.value) {
                                                    if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
                                                        var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
                                                            if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                        ).toArray();
                                                        if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                            docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                        }
                                                    }
                                                }
                                            });
                                            docCustomField.value += ']';
                                        }
                                    }else if(Array.isArray(cfValue.value)) {
                                        if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                            docCustomField.value = '[';

                                            _.forEach(cfValue.value, function (item) {
                                                docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
                                            });

                                            docCustomField.value += ']';
                                        } else {
                                            docCustomField.value = cfValue.value[0];
                                        }
                                    } else {
                                        if(cfValue.value instanceof Date) {
                                            docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                        } else {
                                            docCustomField.value = cfValue.value;
                                        }
                                    }
                                }

                                documentModel.customFields.push(docCustomField);
                            });
                        }


                        newDocumentFromTemplate(transaction, documentModel, processNewDocumentFromTemplate, relatedDocument, ((form && form.model && form.model.row17)?form.model.row17:undefined))
                            .then(function (data) {
                            }).catch(function (error) {
                            $rootScope.showLoadingdivSignin = false;
                            if(error && error.data && error.data.message == 'Error call to SUITE'){
                            	modal.alerts.push({msg: $filter('translate')('global.errors.errorConnectSuite')});
                        	}else{
								if(error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'template', error.data), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
 								}else{
									modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'template', error.data)});
								}
                        		
                        	}
                        });
                    }
                }
            };

            var processNewDocumentFromTemplate = function(documents) {
                if(boxDefinition && boxDefinition.box && boxDefinition.box.tableDefinition && boxDefinition.box.search && boxDefinition.box.search.saveSearch){
					boxDefinition.box.search.saveSearch(documents);
				}else if(boxDefinition && boxDefinition.documents && boxDefinition.documents.dossier && boxDefinition.documents.dossier.id){
					if(boxDefinition && boxDefinition.documents && boxDefinition.documents.saveDocumentationToProvide){
						boxDefinition.documents.saveDocumentationToProvide(documents);
					}else{
						$rootScope.$broadcast('allowGenerateFromContentDocumentTypeTemplateExecutedTram', {documents: documents});
					}
				} else if(boxDefinition){
	            	if(documents && documents.length > 0){
	                    angular.forEach(documents, function(value, key){
	                        boxDefinition.content.push(value);
	                        boxDefinition.searchedValue = { id: -1, value: ''};
	                        if(boxDefinition.saveSearch) {
	                        	boxDefinition.saveSearch(value);
	                        }
	                    });
	                    $rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
	                }
                    $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
	                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: boxDefinition.content, relationType: 'DOCUMENT', transactionId:((transaction)?transaction.id:undefined) });
                }else{
                	if(documents && documents.length > 0){
	                    angular.forEach(documents, function(value, key){
	                        transaction.documents.push(value);
	                    });
	                }
                	if(isShowTransaction){
                		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: transaction.documents, relationType: 'DOCUMENT', transactionId:((transaction)?transaction.id:undefined)  });
                	}
                }
                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
                $rootScope.showLoadingdivSignin = false;
                form.close();
            };
            var hideFunctionTramTemplate = function($viewValue, $modelValue, scope){
            	var hide = true;
            	if(scope.model && scope.model.row1 && scope.model.row1.tramTemplate && form.tramTemplates && form.tramTemplates.length > 0){
            		var ttemp = $linq(form.tramTemplates).firstOrDefault(undefined, 'x => x.id == '+scope.model.row1.tramTemplate);
            		if(ttemp && ttemp.transactions && ttemp.transactions.length > 0){
            			hide = false;
            		}
            	}
            	return hide;
            };
            newModel.row17 = {};
            form.addGroup('row17', '', [], 'annexaRow', hideFunctionTramTemplate);

            form.addSubmitCallback(submitNewDocumentFromDossierTemplate);

            return form;
        };

        var newDocumentFromTemplate = function(transaction, model, processFunc, relatedDocument, relatedTransactions) {
            var deferrend = $q.defer();

            TramFactory.fromTemplate(transaction, model.tramTemplate, model.documentTitle, model.section, model.profile, model.thirdsSelected, model.profiles, model.type, model.customFields, model.archiveClassification, relatedDocument, relatedTransactions, model.observations)

                .then(function (data) {
                    processFunc(data);
                    deferrend.resolve(data);
                }).catch(function (error) {
                    deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.showNewDocumentFromTemplateModal = function (transaction, tram_templates, document_types, boxDefinition, isShowTransaction, relatedDocument, thirds, createTransactionTemplates, requiredAndCannotCreateDocument) {
            var date = new Date();
			var dossier = ((TramFactory.Dossier)?TramFactory.Dossier:((boxDefinition && boxDefinition.box && boxDefinition.box.config && boxDefinition.box.config.transaction && boxDefinition.box.config.transaction.dossier)?boxDefinition.box.config.transaction.dossier:((boxDefinition && boxDefinition.documents && boxDefinition.documents.dossier)?boxDefinition.documents.dossier:undefined)))
            var modal = angular.copy(globalModals.dossierDocFromTemplate);
            modal.alerts = [];
            var templatesOk = tram_templates;
            if(TramFactory.Dossier && (!TramFactory.Dossier.thirds || TramFactory.Dossier.thirds.length == 0)){
                templatesOk = $linq(templatesOk).where("x => !x.allowThirds").toArray();
            }else if(!TramFactory.Dossier && boxDefinition && boxDefinition.box && boxDefinition.box.tableDefinition && boxDefinition.box.search && boxDefinition.box.search.saveSearch && (!thirds || thirds.length == 0)){
				templatesOk = $linq(templatesOk).where("x => !x.allowThirds").toArray();
			}else if(!TramFactory.Dossier && boxDefinition && boxDefinition.documents && boxDefinition.documents.dossier && (!thirds || thirds.length == 0)){
				templatesOk = $linq(templatesOk).where("x => !x.allowThirds").toArray();
			}
            templatesOk = $linq(templatesOk).where(function(x) {
            	var ok = true;
	            if(x.profiles && x.profiles.length > 0){
	                var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
	                    if(y.profile){
	                        return (x.profile.id == y.profile.id);
	                    }
	                    else{
	                        return true;
	                    }
	                }).toArray();
	                if(profiles && profiles.length > 0){
	                	ok = true;
	                } else {
	                	ok = false;
	                }
	            }
	            if(ok && createTransactionTemplates && createTransactionTemplates.length > 0) {
	            	ok = $linq(createTransactionTemplates).contains(x.id, "(x, y) => y == x.tramTemplate.id");
	            }
	            
	            return ok;
            }).toArray();
            var idModal = date.getTime() + '';
            var entity = new AnnexaEntity(idModal,'modal', documentFromTemplateForm(transaction, templatesOk, document_types, modal, boxDefinition, dossier, idModal, isShowTransaction, relatedDocument, thirds, requiredAndCannotCreateDocument), []);
            if(DccumentsFactory.sectionsUser && DccumentsFactory.sectionsUser.length > 0){
            	showModal(modal, entity);
            }else{
            	DialogsFactory.error($filter('translate')('global.errors.noUserSection'), $filter('translate')('DIALOGS_ERROR_MSG'));
            }

        };

        //endregion

        //region Tercers

        var getThirdBoxesModel = function (formExtra) {
        	var indexIdentificationReferences = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.identificationReferences'");
            var indexContacts = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.form.contactData'");
            var indexCosents = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.consents'");
            var indexDocuments = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.thirdDocs'");
            var indexReceivers = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.repr'");
            
            return {
            	identificationReferences: indexIdentificationReferences != -1 ? formExtra[indexIdentificationReferences].boxDefinition.content : [],
                contacts: indexContacts != -1 ? formExtra[indexContacts].boxDefinition.content : [],
                consents: indexCosents != -1 ? formExtra[indexCosents].boxDefinition.content : [],
                documents: indexDocuments != -1 ? formExtra[indexDocuments].boxDefinition.content : [],
                receivers: indexReceivers != -1 ? formExtra[indexReceivers].boxDefinition.content : []
            };
        };

        factory.thirdFormFields = function(colClass) {
            var form = new AnnexaFormly();

            var fields = {};

            var thirdTypes = [
                {"id": "PHISICAL", "name": "global.thirds.literals.phisical"},
                {"id": "LEGAL", "name": "global.thirds.literals.legal"},
                {"id": "PERSONWITHOUTLEGALPERSONALITY", "name": "global.thirds.literals.personwithoutlegalpersonality"}
            ];

            var thirdIdentificationDocumentTypes = [
                {"id": "DNI", "name": "DNI/NIF"},
                {"id": "NIE", "name": "NIE/NIF"},
                {"id": "NIF", "name": "NIF"}
            ];
            
            var thirdIdentificationDocumentTypesLegal = [
        		{"id": "NIF", "name": "NIF"},
        		{"id": "OTHER", "name": "global.literals.other"}
        	];

            //En modo ESET, en caso de personas físicas debe mantenerse el literal Pasaporte, si no debe ser Otros
            if($rootScope.esetMode) {
            	thirdIdentificationDocumentTypes.push({"id": "OTHER", "name": "global.thirds.literals.passport"});
            } else { 
                thirdIdentificationDocumentTypes.push({"id": "OTHER", "name": "global.literals.other"});
            }

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong',
                extralabel: true
            };

            // Tipus tercer
            var field1 = form.createField(
                'thirdType',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.type',
                    'id',
                    'name',
                    thirdTypes,
                    true,
                    undefined,
                    function(item, options) {
                        if ((item.id == 'LEGAL' || item.id == 'PERSONWITHOUTLEGALPERSONALITY') || (item == 'LEGAL' || item == 'PERSONWITHOUTLEGALPERSONALITY')) {
                        	loadFieldTemplateOptions(fields.identificationDocumentType, thirdIdentificationDocumentTypesLegal);
                        } else {
                        	loadFieldTemplateOptions(fields.identificationDocumentType, thirdIdentificationDocumentTypes);
                        }
                        
                        if (fields.identificationDocument) {
                        	//Valida el campo con el numero de documento
                        	runValidators(fields.identificationDocument);
                        }
                    }
                ),
                data
            );
            field1.hideExpression = function($viewValue, $modelValue, scope) {
            	if (scope.$parent.model.row2 && (scope.$parent.model.row2.counter == undefined || scope.$parent.model.row2.counter > 1)) {
            		scope.$parent.model.row2.identificationDocumentType = undefined;
            	}
            	if (scope.$parent.model.row2 && scope.$parent.model.row2.counter != undefined) {
            		scope.$parent.model.row2.counter = scope.$parent.model.row2.counter + 1;
            	}
            	if (fields.identificationDocument) {
            		//Valida el campo con el numero de documento
                	runValidators(fields.identificationDocument);
            	}
            	return false; 
            };
            fields.thirdType = field1;

            // Tipus identificacio
            var field2 = form.createField(
                'identificationDocumentType',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.thirds.literals.identificationdocumenttype',
                    'id',
                    'name',
                    thirdIdentificationDocumentTypes,
                    true,
                    undefined,
                    function(item, options) {
                    	if (fields.identificationDocument) {
                    		//Valida el campo con el numero de documento
                    		runValidators(fields.identificationDocument);
                    	}
                    }
                ),
                data
            );
            field2.hideExpression = function($viewValue, $modelValue, scope) {
            	if (fields.identificationDocument) {
            		//Valida el campo con el numero de documento
                	runValidators(fields.identificationDocument);
            	}
            	return false; 
            };
            fields.identificationDocumentType = field2;

            // Nombre identificador
            var field3 = form.createField(
                'identificationDocument',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptionsValidator(
                    'text',
                    'global.thirds.literals.identificationdocument',
                    true,
                    undefined,
                    undefined,
                    {
                        "documentTypeInvalid": {
                            "expression": function (viewValue, modelValue, scope) {
                                if (modelValue && fields.identificationDocumentType.value() && fields.thirdType.value()) {
                                    if (HelperService.validateDocumentByType(modelValue, fields.identificationDocumentType.value(), fields.thirdType.value())) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return true;
                                }
                            }
                        }
                    },
                    255
                ),
                data
            );
            fields.identificationDocument = field3;

            // Nom
            var field4 = form.createField(
                'name',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.name',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.name = field4;

            // Cognom1
            var field5 = form.createField(
                'surename1',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.surename1',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.surename1 = field5;

            // Partícules del Cognom1
            var surname1ParticlesField = form.createField(
                'surname1Particles',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.thirds.literals.surname1Particles',
                    false,
                    undefined,
                    undefined,
                    20
                ),
                data
            );
            fields.surname1Particles = surname1ParticlesField;

            // Cognom2
            var field7 = form.createField(
                'surename2',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.surename2',
                    false,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.surename2 = field7;

            // Partícules del Cognom2
            var surname2ParticlesField = form.createField(
                'surname2Particles',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.thirds.literals.surname2Particles',
                    false,
                    undefined,
                    undefined,
                    20
                ),
                data
            );
            fields.surname2Particles = surname2ParticlesField;

            // Nom sentit
            var meaningNameField = form.createField(
                'meaningName',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.thirds.literals.meaningName',
                    false,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.meaningName = meaningNameField;

            // Gènere
            var genderField = form.createField(
                'gender',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.thirds.literals.gender',
                    'id',
                    Language.getActiveColumn(),
                    GlobalDataFactory.genders,
                    false
                ),
                data
            );
            fields.gender = genderField;

            var bornDateField = form.createField(
                'bornDate',
                'annexaDatepickerRow',
                'col-sm-12',
                new AnnexaFormlyFieldDatepickerTemplateOptions(
                    'text',
                    'global.thirds.literals.bornDate',
                    false,
                    {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }
                ),
                data
            );
            fields.bornDate = bornDateField;

            // Tractament
            var treatmentField = form.createField(
                'treatment',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.thirds.literals.treatment',
                    'id',
                    Language.getActiveColumn(),
                    GlobalDataFactory.treatments,
                    false
                ),
                data
            );
            fields.treatment = treatmentField;

            // Idioma de relació
            var relationshipLanguageField = form.createField(
                'relationshipLanguage',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.thirds.literals.relationshipLanguage',
                    'id',
                    Language.getActiveColumn(),
                    GlobalDataFactory.relationshipLanguages,
                    false
                ),
                data
            );
            fields.relationshipLanguage = relationshipLanguageField;

            // Nom empresa
            var field6 = form.createField(
                'corporateName',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.corporateName',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.corporateName = field6;
            
            // Nombre referencia
            var field8 = form.createField(
                'identificationReference',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.thirds.literals.identificationReference',
                    false,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.identificationReference = field8;
            
            // Observacions
            var fieldObservations = form.createField(
                'observations',
                'annexaTextAreaRow',
                colClass,
                new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'),
                data
            );
            fields.observations = fieldObservations;

            return fields;
        };
        
        function loadFieldTemplateOptions(field, load){
        	field.templateOptions.options = [];
        	angular.forEach(load, function (element){
        		field.templateOptions.options.push(element);
        	});
    	}
        
        function runValidators(field) {
        	if (field.formControl && field.formControl.length > 0) {
        		angular.forEach(field.formControl, function (value, key) {
        			value.$$runValidators(field.value(), field.value(), angular.noop);
                });
        	}
        }

        var thirdNewForm = function(saveOkState, thirdModelParam) {
            var fields = factory.thirdFormFields('col-sm-12');

            var form = new AnnexaFormly();
            form.addGroup('row1', '', [fields.thirdType], 'annexaRow');
            form.addGroup('row2', '', [fields.identificationDocumentType], 'annexaRow');
            form.addGroup('row3', '', [fields.identificationDocument, fields.identificationReference], 'annexaRow');
            form.addGroup('row4', '', [fields.name], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row5', '', [fields.surname1Particles], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row6', '', [fields.surename1], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row7', '', [fields.surname2Particles], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row8', '', [fields.surename2], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row9', '', [fields.meaningName], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row10', '', [fields.gender], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row11', '', [fields.bornDate], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row12', '', [fields.treatment], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            
            form.addGroup('row13', '', [fields.corporateName], 'annexaRow', 'model.row1.thirdType == "PHISICAL" || !model.row1.thirdType');
            form.addGroup('row14', '', [fields.relationshipLanguage], 'annexaRow');
            form.addGroup('row15', '', [fields.observations], 'annexaRow');

            if (thirdModelParam) {
                form.model = {
                    row1:{thirdType: thirdModelParam.thirdType},
                    row2:{identificationDocumentType: thirdModelParam.identificationDocumentType},
                    row3:{identificationDocument: thirdModelParam.identificationDocument}, //no mirem el camp identificationReference perquè s'omplirà al box 
                    row4:{name: thirdModelParam.name},
                    row5:{surname1Particles: thirdModelParam.surname1Particles},
                    row6:{surename1: thirdModelParam.surename1},
                    row7:{surname2Particles: thirdModelParam.surname2Particles},
                    row8:{surename2: (thirdModelParam.surename2)?thirdModelParam.surename2:""},
                    row9:{meaningName: thirdModelParam.meaningName},
                    row10:{gender: (thirdModelParam.gender)?thirdModelParam.gender.id:undefined}, 
                    row11:{bornDate: thirdModelParam.bornDate},
                    row12:{treatment: (thirdModelParam.treatment)?thirdModelParam.treatment.id:undefined},
                    row13:{corporateName: thirdModelParam.corporateName},
                    row14:{relationshipLanguage: (thirdModelParam.relationshipLanguage)?thirdModelParam.relationshipLanguage.id:undefined},
                    row15:{observations: thirdModelParam.observations}
                };
                form.fields[1].fieldGroup[0].defaultValue = thirdModelParam.identificationDocumentType;
            }

            var submitNewThird = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var thirdModel = { third: {}};

                    if(form.model.row1) {
                        thirdModel.third.thirdType = form.model.row1.thirdType;
                    }

                    if(form.model.row2) {
                        thirdModel.third.identificationDocumentType = form.model.row2.identificationDocumentType;
                    }

                    if(form.model.row3) {
                        thirdModel.third.identificationDocument = form.model.row3.identificationDocument;
                        thirdModel.third.identificationReference = form.model.row3.identificationReference;
                    }
                    
                    if(form.model.row4) {
                        thirdModel.third.name = form.model.row4.name;
                    }

                    if(form.model.row5) {
                        thirdModel.third.surname1Particles = form.model.row5.surname1Particles;
                    }

                    if(form.model.row6) {
                        thirdModel.third.surename1 = form.model.row6.surename1;
                    }

                    if(form.model.row7) {
                        thirdModel.third.surname2Particles = form.model.row7.surname2Particles;
                    }

                    if(form.model.row8) {
                        thirdModel.third.surename2 = form.model.row8.surename2;
                    }

                    if(form.model.row9) {
                        thirdModel.third.meaningName = form.model.row9.meaningName;
                    }

                    if(form.model.row10 && form.model.row10.gender) {
                        thirdModel.third.gender = { id: form.model.row10.gender };
                    }

                    if(form.model.row11) {
                        thirdModel.third.bornDate = form.model.row11.bornDate;
                    }

                    if(form.model.row12 && form.model.row12.treatment) {
                        thirdModel.third.treatment = { id: form.model.row12.treatment };
                    }

                    if(form.model.row13) {
                        thirdModel.third.corporateName = form.model.row13.corporateName;
                    }

                    if(form.model.row14 && form.model.row14.relationshipLanguage) {
                        thirdModel.third.relationshipLanguage = { id: form.model.row14.relationshipLanguage };
                    }

                    if(form.model.row15) {
                        thirdModel.third.observations = form.model.row15.observations;
                    }

                    var boxesModel = getThirdBoxesModel(form.extra);

                    thirdModel.contacts = boxesModel.contacts;
                    thirdModel.documents = boxesModel.documents;
                    thirdModel.receivers = boxesModel.receivers;
                    thirdModel.consents = boxesModel.consents;
                    thirdModel.identificationReferences = boxesModel.identificationReferences;
                    thirdModel.third.identificationReferences = thirdModel.identificationReferences;

                    newThird(thirdModel, saveOkState)
                        .then(function(data){
                        }).catch(function (error) {
                            form.addAlert({ msg: error });
                    });
                }
            };

            form.addSubmitCallback(submitNewThird);

            return form;
        };

        factory.getThirdBoxNewForm = function(modal, processFunc) {
            return thirdBoxNewForm(undefined, modal, processFunc);
        };

        var thirdBoxNewForm = function(boxDefinition, modal, processFunc) {
            var fields = factory.thirdFormFields('col-sm-6');

            var form = new AnnexaFormly();
            form.addGroup('row1', '', [fields.thirdType, fields.identificationDocumentType], 'annexaRow');
            form.addGroup('row2', '', [fields.identificationDocument, fields.identificationReference], 'annexaRow');
            form.addGroup('row3', '', [fields.name, fields.surname1Particles, fields.surename1, fields.surname2Particles, fields.surename2, 
            						   fields.meaningName, fields.gender, fields.bornDate, fields.treatment], 'annexaRow', "model.row1.thirdType != 'PHISICAL'");
            form.addGroup('row4', '', [fields.corporateName], 'annexaRow', "model.row1.thirdType == 'PHISICAL' || !model.row1.thirdType");
            form.addGroup('row5', '', [fields.relationshipLanguage], 'annexaRow');
            form.addGroup('row6', '', [fields.observations], 'annexaRow');

            if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
                form.model = {
                    row1:{thirdType: "PHISICAL"},
                    row2:{identificationDocumentType:"NIF"},
                    row3:{name:boxDefinition.user.name,
                        surename1:boxDefinition.user.surename1,
                        surename2:(boxDefinition.user.surename2)?boxDefinition.user.surename2:""
                    }
                };
            }
            
            if (modal.annexaFormly && modal.annexaFormly.model) {
                form.model = {
                    row1:{thirdType: modal.annexaFormly.model.thirdType, identificationDocumentType: modal.annexaFormly.model.identificationDocumentType},
                    row2:{identificationDocument: modal.annexaFormly.model.identificationDocument}, //no mirem el camp identificationReference perquè s'omplirà al box 
                    row3:{name: modal.annexaFormly.model.name,
                    	surname1Particles: modal.annexaFormly.model.surname1Particles, surename1: modal.annexaFormly.model.surename1,
                        surname2Particles: modal.annexaFormly.model.surname2Particles, surename2: (modal.annexaFormly.model.surename2)?modal.annexaFormly.model.surename2:"",
                        meaningName: modal.annexaFormly.model.meaningName, gender: (modal.annexaFormly.model.gender)?modal.annexaFormly.model.gender.id:undefined, 
                        bornDate: modal.annexaFormly.model.bornDate, treatment: (modal.annexaFormly.model.treatment)?modal.annexaFormly.model.treatment.id:undefined
                    },
                    row4:{corporateName: modal.annexaFormly.model.corporateName},
                    row5:{relationshipLanguage: (modal.annexaFormly.model.relationshipLanguage)?modal.annexaFormly.model.relationshipLanguage.id:undefined},
                    row6:{observations: modal.annexaFormly.model.observations}
                };
            }

            var submitNewThird = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var thirdModel = {third: {}};

                    if(form.model.row1) {
                        thirdModel.third.thirdType = form.model.row1.thirdType;
						thirdModel.third.identificationDocumentType = form.model.row1.identificationDocumentType;
                    }

                    if(form.model.row2) {
                        thirdModel.third.identificationDocument = form.model.row2.identificationDocument;
                        thirdModel.third.identificationReference = form.model.row2.identificationReference;
                    }

                    if(form.model.row3) {
                        thirdModel.third.name = form.model.row3.name;
                        thirdModel.third.surname1Particles = form.model.row3.surname1Particles;
                        thirdModel.third.surename1 = form.model.row3.surename1;
                        thirdModel.third.surname2Particles = form.model.row3.surname2Particles;
                        thirdModel.third.surename2 = form.model.row3.surename2;
                        thirdModel.third.meaningName = form.model.row3.meaningName;
                        thirdModel.third.gender = form.model.row3.gender ? { id: form.model.row3.gender } : undefined;
                        thirdModel.third.bornDate = form.model.row3.bornDate;
                        thirdModel.third.treatment = form.model.row3.treatment ? { id: form.model.row3.treatment } : undefined;
                    }

                    if(form.model.row4) {
                        thirdModel.third.corporateName = form.model.row4.corporateName;
                    }
                    
                    if(form.model.row5) {
                    	thirdModel.third.relationshipLanguage = form.model.row5.relationshipLanguage ? { id: form.model.row5.relationshipLanguage } : undefined;
                    }

                    if(form.model.row6) {
                        thirdModel.third.observations = form.model.row6.observations;
                    }

                    var boxesModel = getThirdBoxesModel(form.extra);

                    thirdModel.contacts = boxesModel.contacts;
                    thirdModel.documents = boxesModel.documents;
                    thirdModel.receivers = boxesModel.receivers;
                    thirdModel.consents = boxesModel.consents;
                    thirdModel.identificationReferences = boxesModel.identificationReferences;

                    newThird(thirdModel,undefined, processFunc ? processFunc : processNewThird, boxDefinition, form)
                        .then(function (data) {
                        }).catch(function (error) {
                            modal.alerts.push({ msg: error });
                    });
                }
            };

            var processNewThird = function(third) {
                boxDefinition.searchedValue = { id: third.third.id, object: third.third };
                boxDefinition.searchAdd();
                form.close();
            };

            form.addSubmitCallback(submitNewThird);

            return form;
        };

        var newThird = function(model, okstate, processFunc, boxDefinition, form) {
            var deferrend = $q.defer();

            // TODO: provisionalmente se le pasa el campo model.third.identificationReference por petición de Marcos para evitar posibles incidencias con algunos clientes 
            // que estan usando integraciones, las cuales todavía no se han adaptado a los nuevos requerimientos, para poder validar tanto el campo como el listado de número de referencia
            ThirdFactory.existThirdByIdentificationDocumentAndReferences(model.third.identificationDocument, model.third.identificationReference, model.identificationReferences)
	            .then(function(data2){
	                if(data2.data && data2.data.length > 0) {
	                	if ($rootScope.app.configuration.can_duplicate_third_by_document_number.value) {
	            			//Se pueden duplicar terceros por numero de documento
	                        if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
	                            boxDefinition.actionIfThirdExist(data2.data, deferrend, model, $rootScope.LoggedUser, form);
	                        }else {
	                            var dlg = dialogs.confirm(
	                                $filter('translate')('global.literals.existThird'),
	                                $filter('translate')('global.literals.existThirdConfirm'),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: '',
	                                    backdropClass: 'zMax',
	                                    windowClass: 'zMax2'
	                                }
	                            );
	
	                            dlg.result.then(function (btn) {
	                                ThirdFactory.newThird(model.third, $rootScope.LoggedUser, model.contacts, model.documents, model.receivers, model.consents, model.identificationReferences)
	                                    .then(function (data) {
	                                        if (okstate) {
	                                            $state.transitionTo(okstate);
	                                        }
	                                        if (processFunc) {
	                                            processFunc(data, form);
	                                        }
	                                        deferrend.resolve(data);
	                                    }).catch(function (error) {
	                                    deferrend.reject(ErrorFactory.getErrorMessage('thirds', 'new', error));
	                                });
	                            }, function (btn) {
	                            });
	                        }
                    	} else {
                    		//No se pueden duplicar terceros por numero de documento
                			DialogsFactory.notify('DIALOGS_EXIST_THIRD');
                			deferrend.resolve(false);
                    	}
                    } else {
                        ThirdFactory.newThird(model.third, $rootScope.LoggedUser, model.contacts, model.documents, model.receivers, model.consents, model.identificationReferences)
                            .then(function (data) {
                                if (okstate) {
                                    $state.transitionTo(okstate);
                                }
                                if (processFunc) {
                                    processFunc(data, form);
                                }
                                deferrend.resolve(data);
                            }).catch(function (error) {
                            deferrend.reject(ErrorFactory.getErrorMessage('thirds', 'new', error));
                        });
                    }
                }).catch(function (error) {
                    deferrend.reject(error);
            });

            return deferrend.promise;
        };

        var contactBox = function(permissions) {
            var contactBox = angular.copy(ThirdFactory.ContactBox)
                .initialize('global.thirds.literals.form.contactData', 'global.literals.placeholder_search_receiver', 'global.thirds.literals.advanced_search_repr', AnnexaPermissionsFactory.haveOnePermission(permissions), factory);
            contactBox.boxDefinition.content = [];
            contactBox.boxDefinition.saveSearch = undefined;

            contactBox.boxDefinition.deleteFunction = function(objectId, index){
                var key = contactBox.boxDefinition.content[index].key;

                var indexConsent = $linq(angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content).indexOf("x => x.thirdAddress.key == '" + key + "'");
                while(indexConsent != -1) {
                    angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content.splice(indexConsent, 1);
                    indexConsent = $linq(angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content).indexOf("x => x.thirdAddress.key == '" + key + "'");
                }
                var entityBoxes = angular.element('#new-third').scope().$parent.entity.boxes;
                contactBox.boxDefinition.content.splice(index, 1);
            };
            
            contactBox.boxDefinition.canEditThird = function(){
                return AnnexaPermissionsFactory.haveSinglePermission('edit_third');
            };

            contactBox.boxDefinition.editFunction = function(objectId, index){
                var key = contactBox.boxDefinition.content[index].key;
            };

            contactBox.boxDefinition.defaultFunction = function(contact, index){
                if(contactBox.boxDefinition.content == 1){
                    contact.default = true;
                }else{
                    var defaults = $linq(contactBox.boxDefinition.content).where('x => x.default == true').toArray();
                    if(defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.default = false;});
                    }
                    contact.default = true;
                }
            };
            contactBox.boxDefinition.renderType = 'contact-third';
            contactBox.boxDefinition.rederPermissions = permissions;
            contactBox.deletedAddresses = [];
            return contactBox;
        };

        var identificationReferenceBox = function (permissions) {
            var identificationReferenceBox = angular.copy(ThirdFactory.IdentificationReferenceBox)
                .initialize('global.thirds.literals.identificationReferences', '', '', AnnexaPermissionsFactory.haveOnePermission(permissions));
            identificationReferenceBox.boxDefinition.content = [];
            identificationReferenceBox.boxDefinition.saveSearch = undefined;
            identificationReferenceBox.boxDefinition.deleteFunction = function(objectId, index) {
                identificationReferenceBox.boxDefinition.content.splice(index, 1);
            };
            identificationReferenceBox.boxDefinition.renderType = 'identificationReference-third';
            identificationReferenceBox.boxDefinition.rederPermissions = permissions;

            return identificationReferenceBox;
        };

        var consentBox = function (permissions) {
            var consentBox = angular.copy(ThirdFactory.ConsentBox)
                .initialize('global.literals.consents', '', '', AnnexaPermissionsFactory.haveOnePermission(permissions));
            consentBox.boxDefinition.content = [];
            consentBox.boxDefinition.saveSearch = undefined;
            consentBox.boxDefinition.deleteFunction = function(objectId, index) {
                consentBox.boxDefinition.content.splice(index, 1);
            };
            consentBox.boxDefinition.renderType = 'consent-third';
            consentBox.boxDefinition.rederPermissions = permissions;

            return consentBox;
        };

        var receiverBox = function (permissions, additionalThirdFilter) {
            var receiverBox = getBox('representant', 'receiver-third', 'global.thirds.literals.repr', 'global.literals.placeholder_search_receiver', 'global.thirds.literals.advanced_search_repr', 
            		AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions),
            		undefined, undefined, undefined, additionalThirdFilter);
            receiverBox.boxDefinition.addSearchAdd(
                angular.copy(globalModals.representationAdd),
                function(selected) {
                    return { third: selected.id, startValidityPeriod: undefined, endValidityPeriod: undefined, object: selected.object };
                },
                function() {
                    var modal_data = this.annexaFormly.model.modal_body;
                    var objThird = JSOG.decode(modal_data.object);
                    var newContent = undefined;
                    if(modal_data.representationType && modal_data.representationType == 'representat'){
                    	newContent = {
                    			represented: objThird,
                    			startValidityPeriod: modal_data.startValidityPeriod,
                    			endValidityPeriod: modal_data.endValidityPeriod,
                    			revoked: false
                    	} 
                	}else{
                		newContent = {
                				agent: objThird,
                				startValidityPeriod: modal_data.startValidityPeriod,
                				endValidityPeriod: modal_data.endValidityPeriod,
                				revoked: false
                		}
                	}
                    this.extra.content.push(newContent);
                    this.extra.searchedValue = { id: -1, value: ''};
                    if(this.extra.saveSearch) {
                        this.extra.saveSearch(newContent);
                    }
                    this.close();
                },
                AnnexaFormlyFactory);

            return receiverBox;
        };

        factory.getThirdBoxes = function() {
            return thirdBoxes();
        };

        var thirdBoxes = function () {
            var retBoxes = [];

            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(identificationReferenceBox(['new_third']));
            }

            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(contactBox(['new_third']));
            }

            var documentBox = getBox('document', 'document-third', 'global.thirds.literals.thirdDocs', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_documents', AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'));
            documentBox.boxDefinition.module = "THIRDS";
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBox);
            }

            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(receiverBox(['new_third']));
            }

            return retBoxes;
        };

        factory.getEditThirdBox = function (type) {
            var box;

            switch (type) {
                case 'document':
                    box = getBox('document', 'document-third', 'global.thirds.literals.thirdDocs', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_documents', AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'));
                    box.boxDefinition.saveSearch = function (document) {
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                        }).catch(function (error) {
                            box.boxDefinition.content.pop();
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        ThirdFactory.updateThird(ThirdFactory.Third).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    break;
                case 'identificationReference':
                    box = identificationReferenceBox(['edit_third']);
                    box.boxDefinition.saveSearch = function (identificationReference) {
                        var identificationReferences = [];
//                        if(ThirdFactory.Third.identificationReferences) {
//                            identificationReferences = ThirdFactory.Third.identificationReferences;
//                        }

                        identificationReferences.push({
                            identificationReferenceType: identificationReference.identificationReferenceType,
                            identificationReference: identificationReference.identificationReference,
                            deleted: false,
                            third: { id: ThirdFactory.Third.id }
                        });

                        var actualIdentificationReferences = ThirdFactory.Third.identificationReferences;
                        ThirdFactory.Third.identificationReferences = identificationReferences;

                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                        	if(data && data.identificationReferences){
                        		actualIdentificationReferences.pop();
                        		if(actualIdentificationReferences.length == box.boxDefinition.content.length){
                        			ThirdFactory.Third.identificationReferences = actualIdentificationReferences;
                                    ThirdFactory.Third.identificationReferences.push(data.identificationReferences[0]);
                        		}else{
                        			ThirdFactory.Third.identificationReferences = data.identificationReferences;
                        		}
                        	}
                        }).catch(function(error) {
                        	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                        		box.boxDefinition.content.pop();
                        		ThirdFactory.Third.identificationReferences = box.boxDefinition.content;
                        	}
//							if(ThirdFactory.Third && ThirdFactory.Third.identificationReferences && ThirdFactory.Third.identificationReferences.length > 0){
//								ThirdFactory.Third.identificationReferences.pop();
//							}
                        	var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        ThirdFactory.Third.identificationReferences[index].removedDate = new Date();
                        ThirdFactory.Third.identificationReferences[index].deleted = true;
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                                box.boxDefinition.content.splice(index, 1);
//                                ThirdFactory.Third.identificationReferences.splice(index, 1);
                        }).catch(function(error) {
                            ThirdFactory.Third.identificationReferences[index].removedDate = undefined;
                            ThirdFactory.Third.identificationReferences[index].deleted = false;
                        });
                    };
                    box.boxDefinition.editFunction = function(objectId, index) {
                    	var modal = angular.copy(globalModals.identificationReferencesAdd);
                    	var editedIdentRef = angular.copy(box.boxDefinition.content[index]);
                    	modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = editedIdentRef;
                        modal.annexaFormly.options = { watchAllExpressions: true };
                        modal.extra = this;
                        var completedEdit = function(modal){
                        	var identificationReferenceIndex = $linq(ThirdFactory.Third.identificationReferences).indexOf("x => x.id == " + modal.annexaFormly.model.modal_body.id);
                        	
                        	ThirdFactory.Third.identificationReferences[identificationReferenceIndex] = modal.annexaFormly.model.modal_body;
                        	
                            ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                                ThirdFactory.Third.identificationReferences = data.identificationReferences;
                                box.boxDefinition.content = data.identificationReferences;
                                modal.close();
                            }).catch(function(error) {
                            	var err = dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
	                        });
                    	};
                        AnnexaFormlyFactory.showModal("modalEditConsent", modal, completedEdit, false);
                    }
                    break;
                case 'consent':
                    box = consentBox(['edit_third']);
                    box.boxDefinition.saveSearch = function (consent) {
                        var consents = [];
                        if(ThirdFactory.Third.consents) {
                            consents = ThirdFactory.Third.consents;
                        }

                        consents.push(
                            {
                                consentType: consent.consentType,
                                deleted: false,
                                dossier: consent.dossier ? consent.dossier : undefined,
                                familyProcedure: consent.familyProcedure ? consent.familyProcedure : undefined,
                                procedure: consent.procedure ? consent.procedure : undefined,
                                thirdAddress: { id: consent.thirdAddress.id }
                            }
                        );

                        ThirdFactory.Third.consents = consents;

                        ThirdFactory.updateThird(ThirdFactory.Third)
                            .then(function(data) {
                                ThirdFactory.Third.consents = data.consents;
                            }).catch(function(error) {
                            	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                            		box.boxDefinition.content.pop();
                            	}
								if(ThirdFactory.Third && ThirdFactory.Third.consents && ThirdFactory.Third.consents.length > 0){
									ThirdFactory.Third.consents.pop();
								}
                            	var err = dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        ThirdFactory.Third.consents[index].removedDate = new Date();
                        ThirdFactory.Third.consents[index].deleted = true;
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                                box.boxDefinition.content.splice(index, 1);
                                ThirdFactory.Third.consents.splice(index, 1);
                        }).catch(function(error) {
                            ThirdFactory.Third.consents[index].removedDate = undefined;
                            ThirdFactory.Third.consents[index].deleted = false;
                        });
                    };
                    box.boxDefinition.editFunction = function(objectId, index) {
                    	var editedConsent = angular.copy(box.boxDefinition.content[index]);
                    	var modal = angular.copy(globalModals.consentsEdit);
                    	modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = editedConsent;
                        modal.annexaFormly.options = { watchAllExpressions: true };
                        modal.extra = this;
                        var completedEdit = function(modal){

                            angular.forEach(ThirdFactory.Third.consents, function(value, key){
                                if(value.id == modal.annexaFormly.model.modal_body.id) {
                                	value.thirdAddress = { id: modal.annexaFormly.model.modal_body.thirdAddress.id}
                                }
                            });
                            
                            ThirdFactory.updateThird(ThirdFactory.Third)
	                            .then(function(data) {
	                            	var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
	                            	var address = $linq(data.consents).single("x => x.id == " + box.boxDefinition.content[index].id).thirdAddress;
	                            	address.name = thirdAddress.getAddressHtml(address, false, true);
	                            	
	                            	box.boxDefinition.content[index].thirdAddress = address;
	                                ThirdFactory.Third.consents = data.consents;
	                                modal.close();
	                            }).catch(function(error) {
	                            	var err = dialogs.error(
	                                    $filter('translate')('DIALOGS_ERROR_MSG'),
	                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
	                                    {
	                                        animation: true,
	                                        backdrop: 'static',
	                                        keyboard: true,
	                                        size: ''
	                                    }
	                                );
	                        });
                    	};
                        AnnexaFormlyFactory.showModal("modalEditConsent", modal, completedEdit, false);
                    }
                    break;
                case 'contact':
                    box = contactBox(['edit_third']);
                    box.boxDefinition.saveSearch = function (document) {
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                            ThirdFactory.Third.addresses.length = 0;
                            angular.forEach(data.addresses, function(value, key){
                                ThirdFactory.Third.addresses.push(value);
                            });
                        }).catch(function (error) {
                        	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                        		box.boxDefinition.content.pop();
                        	}
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };

                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        if(box.boxDefinition.content && box.boxDefinition.content.length > 1) {
                            box.boxDefinition.content[index].removedDate = new Date();
                            box.boxDefinition.content[index].deleted = true;
                            var showLongAddressLanguage = $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value;
                            var addressDeleted = angular.copy(box.boxDefinition.content[index]);
                            var consentsToModify = $linq(ThirdFactory.Third.consents).where("x => x.thirdAddress.id == " + addressDeleted.id).toArray();
                            if(consentsToModify && consentsToModify.length > 0) {
                            	var adaptConsent = function (consent) {
                            		if(consent.familyProcedure) {
                            			consent.title = consent.familyProcedure[Language.getActiveColumn()];
                                    } else if (consent.procedure) {
                                    	consent.title = consent.procedure[Language.getActiveColumn()];
                                    } else if (consent.dossier) {
                                    	consent.title = consent.dossier.dossierNumber + " (" + consent.dossier.subject + ")";
                                    } else {
                                    	consent.title = '';
                                    }
                            		var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, showLongAddressLanguage);
                            		consent.thirdAddress.name = thirdAddress.getAddressHtml(consent.thirdAddress, false, true);
                            		return consent;
                            	}
                            	var modal = angular.copy(globalModals.consentsEdit);
                            	modal.annexaFormly.model = {};
                                modal.annexaFormly.options = { watchAllExpressions: true };
                                modal.extra = this;
                                modal.title = 'global.literals.replaceConsent';
                                var getAdressesHtml = function(contact){
                                    var thirdAddress = new ThirdAddress(undefined, Language.getActiveColumn(), undefined, showLongAddressLanguage);
                                    return thirdAddress.getAddressHtml(contact, false, true);
                                }
                                var contacts = [];

                                var contactBoxContent = undefined;

                                if(angular.element('#new-third').length != 0) {
                                    contactBoxContent = angular.element('#new-third').scope().$parent.entity.boxes[0].boxDefinition.content;
                                } else if (angular.element('#edit-third').length != 0) {
                                    contactBoxContent = angular.element('#edit-third').scope().contactBox.boxDefinition.content;
                                }
								var notificationTypes = angular.copy(GlobalDataFactory.notificationTypes);
								notificationTypes = ((notificationTypes)?$linq(notificationTypes).select("x => x.addressType").distinct().toArray():[]);
								_.forEach(contactBoxContent, function (contact, key) {
									if(_.contains(notificationTypes, contact.addressType) && addressDeleted.id != contact.id){
										contact.name = getAdressesHtml(contact);
                                    	contacts.push(contact);
									}
                                });
                            	if(consentsToModify.length > 1) {
									modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = contacts;
                                    modal.annexaFormly.fields[0].fieldGroup[0].controller = undefined;
                                    
                            		var editedConsents = [];
                                    angular.forEach(consentsToModify, function(value, key){
                                    	var copyFields = angular.copy(modal.annexaFormly.fields[0]);
                                    	if(key == 0) {
                                    		modal.annexaFormly.fields[0].key = modal.annexaFormly.fields[0].key + key;
                                    		modal.annexaFormly.fields[0].className = modal.annexaFormly.fields[0].className + " p-a grey-100 overflow-y-60";
                                    		modal.annexaFormly.model["modal_body" + key] = adaptConsent(angular.copy(value));
                                    	} else {
                                    		copyFields.key = "modal_body" + key;
                                    		copyFields.className = copyFields.className + " p-a grey-100 overflow-y-60";
                                    		modal.annexaFormly.fields.push(copyFields);
                                    		modal.annexaFormly.model["modal_body" + key] = adaptConsent(angular.copy(value));
                                    	}
                                    });
                                    var completedEdit = function(modal){
                                        angular.forEach(ThirdFactory.Third.consents, function(value, key){
                                        	angular.forEach(consentsToModify, function(consent, keyConsent){
                                                if(value.id == modal.annexaFormly.model["modal_body" + keyConsent].id 
                                                		&& value.thirdAddress.id != modal.annexaFormly.model["modal_body" + keyConsent].thirdAddress.id) {
                                                	value.thirdAddress = { id: modal.annexaFormly.model["modal_body" + keyConsent].thirdAddress.id}
                                                }
                                            });
                                        });
                                        ThirdFactory.updateThird(ThirdFactory.Third)
            	                            .then(function(data) {
            	                            	var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, showLongAddressLanguage);
            	                            	var consentBoxContent = undefined;
												if (angular.element('#edit-third').length != 0) {
													consentBoxContent = angular.element('#edit-third').scope().consentBox.boxDefinition.content;
			                                    }
												
												angular.forEach(consentBoxContent, function(value, key){
													var address = $linq(data.consents).single("x => x.id == " + value.id).thirdAddress;
													address.name = thirdAddress.getAddressHtml(address, false, true);
													value.thirdAddress = address;
												});
												
                                                box.boxDefinition.content.splice(index, 1);
                                                ThirdFactory.ThirdAddressesDeleted.push(addressDeleted);
            	                                modal.close();
            	                            }).catch(function(error) {
                                                box.boxDefinition.content[index].removedDate = undefined;
                                                box.boxDefinition.content[index].deleted = false;
            	                            	var err = dialogs.error(
            	                                    $filter('translate')('DIALOGS_ERROR_MSG'),
            	                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
            	                                    {
            	                                        animation: true,
            	                                        backdrop: 'static',
            	                                        keyboard: true,
            	                                        size: ''
            	                                    }
            	                                );
            	                        });
                                	};
                            		AnnexaFormlyFactory.showModal("modalEditConsents", modal, completedEdit, false);
                            	} else {
                            		modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = contacts;
                                    modal.annexaFormly.fields[0].fieldGroup[0].controller = undefined;
                            		var editedConsent = adaptConsent(angular.copy(consentsToModify[0]))
                                    modal.annexaFormly.model.modal_body = editedConsent;
                                    var completedEdit = function(modal){
                                        angular.forEach(ThirdFactory.Third.consents, function(value, key){
                                            if(value.id == modal.annexaFormly.model.modal_body.id
                                            		&& value.thirdAddress.id != modal.annexaFormly.model.modal_body.thirdAddress.id) {
                                            	value.thirdAddress = { id: modal.annexaFormly.model.modal_body.thirdAddress.id}
                                            }
                                        });
                                        ThirdFactory.updateThird(ThirdFactory.Third)
            	                            .then(function(data) {
            	                            	var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, showLongAddressLanguage);
            	                            	var consentBoxContent = undefined;
												if (angular.element('#edit-third').length != 0) {
													consentBoxContent = angular.element('#edit-third').scope().consentBox.boxDefinition.content;
			                                    }
												
												angular.forEach(consentBoxContent, function(value, key){
													var address = $linq(data.consents).single("x => x.id == " + value.id).thirdAddress;
													address.name = thirdAddress.getAddressHtml(address, false, true);
													value.thirdAddress = address;
												});

                                                box.boxDefinition.content.splice(index, 1);
                                                ThirdFactory.ThirdAddressesDeleted.push(addressDeleted);
            	                                modal.close();
            	                            }).catch(function(error) {
                                                box.boxDefinition.content[index].removedDate = undefined;
                                                box.boxDefinition.content[index].deleted = false;
            	                            	var err = dialogs.error(
            	                                    $filter('translate')('DIALOGS_ERROR_MSG'),
            	                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
            	                                    {
            	                                        animation: true,
            	                                        backdrop: 'static',
            	                                        keyboard: true,
            	                                        size: ''
            	                                    }
            	                                );
            	                        });
                                	};
                                    AnnexaFormlyFactory.showModal("modalEditConsent", modal, completedEdit, false);
                            	}
                            } else {
                            	ThirdFactory.updateThird(ThirdFactory.Third).then(function (data) {
                                    box.boxDefinition.content.splice(index, 1);
                                    ThirdFactory.ThirdAddressesDeleted.push(addressDeleted);
                                }).catch(function (error) {
                                    box.boxDefinition.content[index].removedDate = undefined;
                                    box.boxDefinition.content[index].deleted = false;
                                    var err = dialogs.error(
                                        $filter('translate')('DIALOGS_ERROR_MSG'),
                                        $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                        {
                                            animation: true,
                                            backdrop: 'static',
                                            keyboard: true,
                                            size: ''
                                        }
                                    );
                                });
                            }
                        }else{
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')('global.errors.oneContactRequired'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        }
                    };

                    box.boxDefinition.editFunction = function(objectId, index) {
                        var addressLanguage = 'address';
                        if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
                        	addressLanguage = 'shortAddress';

                        var editedAddress = angular.copy(box.boxDefinition.content[index]);
                        if(editedAddress && editedAddress.addressType == 'POSTAL' && editedAddress.address && !editedAddress.address.territory){
                        	var preCloseTerritoryModalFunction = function(address){
                        		box.boxDefinition.content[index].address = address;
                    		}
                        	if(editedAddress.address.id){
	                        	RestService.findOne('Address', editedAddress.address.id).then(function (data) {
	                        		TerritoryFactory.newAddress(JSOG.decode(data.data), preCloseTerritoryModalFunction, false);
	                            }).catch(function (error) {
	                                console.error(error);
	                            });
                        	}else{
                        		TerritoryFactory.newAddress(editedAddress.address, preCloseTerritoryModalFunction, false);
                        	}
                        }else{
                        	var openModalNewContact = function() {
                        		var modal = angular.copy(globalModals.contactsAdd);
                            	modal.annexaFormly.fields[0].fieldGroup[7].templateOptions.search = function(val) {
                            		var addressTypeSelected = undefined;
                            		if (modal.annexaFormly.model.modal_body.addressTypeTerritory && modal.annexaFormly.model.modal_body.territory_) {
                            			if(modal.annexaFormly.model.modal_body.addressTypeTerritory.id) {
                                			addressTypeSelected = {id:modal.annexaFormly.model.modal_body.addressTypeTerritory.id};
                            			} else {
                                			addressTypeSelected = {id:modal.annexaFormly.model.modal_body.addressTypeTerritory};
                            			}
                            		}
                            		var filter = {
                            				addresslanguage1:  val.val, 
                            				addresslanguage2:  val.val, 
                            				addresslanguage3:  val.val,
                            				addressType: addressTypeSelected
                            		};
                            		
                            		var additional_filter = { };
                            		return RestService.filterData('territory', 'Address', filter, additional_filter, 0, 10, '', 3)
                            		.then(function(data) {
                            			var response = [];
                            			
                            			_.forEach(data.data.content, function (value) {
                            				response.push({ id: value.id, object: JSOG.encode(value), value: value[addressLanguage+Language.getActiveColumn()] });
                            			});
                            			
                            			response = new SortedArray(response, 'value').sort();
                            			
                            			return response;
                            		});
                            	};
                            	modal.annexaFormly.fields[0].fieldGroup[9].templateOptions.executeFunction = function() {
                            		var preCloseFunction = function(address){
                            			if(modal.annexaFormly.model.modal_body.territory_){
                            				if(address && address.id){
                            					modal.annexaFormly.model.modal_body.address = {id:address.id, value: address[addressLanguage+Language.getActiveColumn()], object:address};
                            				}
                            			}else{
                            				if(address){
                            					modal.annexaFormly.model.modal_body.addressThirdAux = address;
                            					modal.annexaFormly.model.modal_body.addressThird = address[addressLanguage+Language.getActiveColumn()];
                            				}
                            			}
                            		}
                            		TerritoryFactory.newAddress(undefined, preCloseFunction, modal.annexaFormly.model.modal_body.territory_);
                            	};

                        		var addressForm = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'address'");
                                if(addressForm) {
                                	addressForm.templateOptions.advancedSearch = function() {
                                        var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
                						var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
                						var addressTypes = angular.copy(GlobalDataFactory.addressTypes)
                						
                						var tfilterDossier  = new TableFilter();
                						tfilterDossier.addElement('addressType', 'select', 0, 'global.literals.addressType', 3, HelperService.addAllSelect(addressTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
                						tfilterDossier.addElement('streetType', 'select', 1, 'global.thirds.literals.streetType', 3, HelperService.addAllSelect(streetTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
                						tfilterDossier.addElement('street', 'text', 2, 'global.thirds.literals.streetName').setFilterCallAux(true);
                						tfilterDossier.addElement('numberAddress', 'text', 3, 'global.thirds.literals.number').setFilterCallAux(true);
                						tfilterDossier.addElement('country', 'select', 4, 'global.thirds.literals.country', 3, HelperService.addAllSelect(countries, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false).setUserChosenFilter(true);
                						tfilterDossier.addElement('state', 'text', 5, 'global.thirds.literals.state').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('city', 'text', 6, 'global.literals.city').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('postalCode', 'text', 7, 'global.thirds.literals.postalCode').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('pseudoVia', 'text', 8, 'global.thirds.literals.pseudoVia').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('numberAddress2', 'text', 9, 'global.thirds.literals.number2').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('bis', 'text', 10, 'global.thirds.literals.bis').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('bis2', 'text', 11, 'global.thirds.literals.bis2').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('kilometer', 'text', 12, 'global.thirds.literals.kilometer').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('block', 'text', 13, 'global.thirds.literals.block').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('portal', 'text', 14, 'global.thirds.literals.portal').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('stair', 'text', 15, 'global.thirds.literals.stair').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('floor', 'text', 16, 'global.thirds.literals.floor').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('door', 'text', 17, 'global.thirds.literals.door').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('cadastralReference', 'text', 18, 'global.territory.list.cadastral_reference').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('coordinateX', 'text', 19, 'global.territory.list.coordinateX').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('coordinateY', 'text', 20, 'global.territory.list.coordinateY').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.addElement('address', 'text', 21, 'global.territory.list.address').setFilterCallAux(true).setUserChosenFilter(true);
                						tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());
                						
                						var tdDefDossier    = new TableData('territory', 'Address', './api/general/filterdata').setSortName(addressLanguage).setSort([[1, 'asc']]).setSkipFirstSearch(true);
                						
                						var addressTypeColumn = new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn());
                						var tabledataColumnsDossier = [
                                            DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                                                return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                                            }).notSortable(),
                                            DTColumnBuilder.newColumn(addressLanguage+Language.getActiveColumn()).withTitle($filter('translate')('global.territory.list.address')),
                                            DTColumnBuilder.newColumn('addressType').withTitle(addressTypeColumn.getTitle()).renderWith(addressTypeColumn.getRender)
                                        ];
                						
                						var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('modalASAddresses', 'global.literals.advanced_search_registerEntry', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, addressForm.templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                                        advancedModalDefinition.added = [];
                                        advancedModalDefinition.isAdvanced = true;
                                        advancedModalDefinition.active = 1;
                                        advancedModalDefinition.language = Language.getActiveColumn();
                                        advancedModalDefinition.addressLanguage = addressLanguage;

                                        AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                                	}
                                }
                            	var completedEdit = function(modal){
                            		var addressAux = angular.copy(box.boxDefinition.content[index]);
    								if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
                            			box.boxDefinition.content[index].telematicValue = modal.annexaFormly.model.modal_body.contactPhone;
                            			box.boxDefinition.content[index].phoneInternationalPrefix = modal.annexaFormly.model.modal_body.contactPrefix;
                            		}
                            		if(editedAddress.addressType == 'TELEMATIC_EMAIL') {
                            			box.boxDefinition.content[index].telematicValue = modal.annexaFormly.model.modal_body.contactEmail;
                            		}
                            		if(editedAddress.addressType == 'POSTAL_CODE') {
                            			box.boxDefinition.content[index].telematicValue = modal.annexaFormly.model.modal_body.postalCode;
                            		}
                            		
                            		if(editedAddress.addressType == 'POSTAL') {
                            			if(modal.annexaFormly.model.modal_body.territory_){
                            				box.boxDefinition.content[index].address = {id:modal.annexaFormly.model.modal_body.address.id};
                            			}else{
                            				//TODO:addressThirdType
                            			}
                            		}
                            		box.boxDefinition.content[index].observations = modal.annexaFormly.model.modal_body.observations;
                            		ThirdFactory.updateThird(ThirdFactory.Third).then(function(data){
                            			box.boxDefinition.content[index]=data.addresses[index];
                            			modal.close();
                            		}).catch(function (error) {
    									box.boxDefinition.content[index] = addressAux;
    									if(error && error.data && error.data.message && error.data.message == 'Address already exist.'){
    										DialogsFactory.error($filter('translate')('global.territory.list.errorExistAddress'));
    									}else{
    	                        			var err = dialogs.error(
    	                        					$filter('translate')('DIALOGS_ERROR_MSG'),
    	                        					$filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
    	                        					{
    	                        						animation: true,
    	                        						backdrop: 'static',
    	                        						keyboard: true,
    	                        						size: ''
    	                        					}
    	                        			);
    									}
                            		});
                            	};
                            	
                            	if(editedAddress) {
                            		if(editedAddress && editedAddress.address && editedAddress.address.territory){
                            			editedAddress.editExitAddress = true;
                            			editedAddress.territory_ = true;
                            			if(editedAddress.address && editedAddress.address.id){
                            				editedAddress.addressTypeTerritory = editedAddress.address.addressType;
                            				editedAddress.address = {id:editedAddress.address.id, value: editedAddress.address[addressLanguage+Language.getActiveColumn()], object:editedAddress.address};
                            			}
                            		}else if(editedAddress && editedAddress.address ){
                            			editedAddress.editExitAddress = true;
                            			editedAddress.territory_ = false;
                            			editedAddress.addressThird = editedAddress.address[addressLanguage+Language.getActiveColumn()];
                            			editedAddress.addressThirdAux = editedAddress.address;
                            		}
                            		var showDefaultByTypeMobile = false;
                            		var defaults;
                            		defaults = $linq(box.boxDefinition.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                            		if(defaults && defaults.length > 0){
                            			showDefaultByTypeMobile = true;
                            		}
                            		var showDefaultByTypeEmail = false;
                            		defaults = $linq(box.boxDefinition.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                            		if(defaults && defaults.length > 0){
                            			showDefaultByTypeEmail = true;
                            		}
                            		var showDefaultByTypePostalCode = false;
                            		defaults = $linq(box.boxDefinition.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                            		if(defaults && defaults.length > 0){
                            			showDefaultByTypePostalCode = true;
                            		}
                            		var showDefaultByTypePostal = false;
                            		defaults = $linq(box.boxDefinition.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                            		if(defaults && defaults.length > 0){
                            			showDefaultByTypePostal = true;
                            		}
                            		var showDefault = true;
                            		defaults = $linq(box.boxDefinition.content).where('x => x.default == true').toArray();
                            		if(defaults && defaults.length > 0){
                            			showDefault = false;
                            		}
                            		
                            		modal.annexaFormly.model = {};
                            		modal.annexaFormly.model.modal_body = editedAddress;
                            		modal.annexaFormly.model.modal_body.addressType = editedAddress.addressType;
                            		modal.annexaFormly.model.modal_body.showDefaultByTypeMobile = showDefaultByTypeMobile;
                            		modal.annexaFormly.model.modal_body.showDefaultByTypeEmail = showDefaultByTypeEmail;
                            		modal.annexaFormly.model.modal_body.showDefaultByTypePostal = showDefaultByTypePostal;
                            		modal.annexaFormly.model.modal_body.showDefaultByTypePostalCode = showDefaultByTypePostalCode;
                            		modal.annexaFormly.model.modal_body.showDefault = showDefault;
                            		if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
                            			modal.annexaFormly.model.modal_body.contactPhone = editedAddress.telematicValue;
                            			modal.annexaFormly.model.modal_body.contactPrefix = editedAddress.phoneInternationalPrefix;
                            		}
                            		
                            		if(editedAddress.addressType == 'TELEMATIC_EMAIL'){
                            			modal.annexaFormly.model.modal_body.contactEmail = editedAddress.telematicValue;
                            		}
                            		if(editedAddress.addressType == 'POSTAL_CODE'){
                            			modal.annexaFormly.model.modal_body.postalCode = editedAddress.telematicValue;
                            		}
                            		
                            		modal.extra = this;
                            		AnnexaFormlyFactory.showModal("modalNewContact", modal, completedEdit, false);
                            	}else {
                            		var err = dialogs.error(
                            				$filter('translate')('DIALOGS_ERROR_MSG'),
                            				$filter('translate')('global.errors.oneContactRequired'),
                            				{
                            					animation: true,
                            					backdrop: 'static',
                            					keyboard: true,
                            					size: ''
                            				}
                            		);
                            	}
                        	}
                        	
                        	if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value && !TerritoryFactory.defaultTown){
                        		RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value).then(function(data) {
                        			TerritoryFactory.defaultTown = JSOG.decode(data.data);
                        			openModalNewContact();
                        		}).catch(function(error) {
                        			openModalNewContact();
                                });
                    		} else {
                    			openModalNewContact();
                    		}
                        	
                        }
                    };

                    box.boxDefinition.defaultFunction = function(contact, index){
                        if(box.boxDefinition.content == 1){
                            contact.default = true;
                        }else{
                            var defaults = $linq(box.boxDefinition.content).where('x => x.default == true').toArray();
                            if(defaults && defaults.length > 0){
                                angular.forEach(defaults, function(value, key){value.default = false;});
                            }
                            contact.default = true;
                        }
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(){
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    break;
                case 'receiver':
                	var additionalThirdFilter = ThirdFactory.Third.id ? {entityId:ThirdFactory.Third.id} : undefined;
                    box = receiverBox(['edit_third'], additionalThirdFilter);
                    box.boxDefinition.saveSearch = function (receiver) {
                     	if(!receiver.agent){
                     		receiver.agent = {id:ThirdFactory.Third.id};
                     	}else if(!receiver.representated){
                     		receiver.represented = {id:ThirdFactory.Third.id};
                     	}
                    	box.boxDefinition.content.pop();
                    	ThirdFactory.createRepresentation(ThirdFactory.Third.id, receiver).then(function(data){
                        	if(ThirdFactory.Third.representations){
                        		ThirdFactory.Third.representations.length = 0;
                        		box.boxDefinition.content.length = 0;
                        	}else{
                        		ThirdFactory.Third.representations = [];
                        		box.boxDefinition.content = [];
                        	}
                        	_.forEach(data, function(rep){
                        		ThirdFactory.Third.representations.push(rep);
                        		box.boxDefinition.content.push(rep);
                        	});
                		}).catch(function(error){
                			DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
                		});
                    };
                    box.boxDefinition.deleteFunction = function(objectId)
                    {
                    	ThirdFactory.deleteRepresentation(ThirdFactory.Third.id, objectId).then(function(data){
                    		if(ThirdFactory.Third.representations){
                        		ThirdFactory.Third.representations.length = 0;
                        		box.boxDefinition.content.length = 0;
                        	}else{
                        		ThirdFactory.Third.representations = [];
                        		box.boxDefinition.content = [];
                        	}
                        	_.forEach(data, function(rep){
                        		ThirdFactory.Third.representations.push(rep);
                        		box.boxDefinition.content.push(rep);
                        	});
	                    }).catch(function (error) {
	                    });
                    };
                    break;
                case 'dossier':
                	if(AnnexaPermissionsFactory.havePermission(['process_dossier', 'view_dossier', 'see_all_dossier'])){
	                	box = getBox('dossier', 'dossier-third', 'global.literals.related_dossiers','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers', false,true,true, undefined, undefined, undefined, undefined, undefined, undefined, true);
	                    box.boxDefinition.saveSearch = function (dossier) { 
	                    	var addThirdToDosier = function(){
	                    		var self = this;
	                    		var newContent = {
		                            third: {id:this.annexaFormly.model.modal_body.object.id},
		                            interested: (( this.annexaFormly.model.modal_body.thirdType == 'interested')?true:false),
		                            representative:  (( this.annexaFormly.model.modal_body.thirdType == 'representative')?true:false),
		                            principal: false,
		                            representated: ((this.annexaFormly.model.modal_body.representated && this.annexaFormly.model.modal_body.representated.id)?{id:this.annexaFormly.model.modal_body.representated.id}:undefined),
		                            roleInterested: ((this.annexaFormly.model.modal_body.roleInterested && this.annexaFormly.model.modal_body.roleInterested.id)?{id:this.annexaFormly.model.modal_body.roleInterested.id}:undefined),
		                            dossier: {id: dossierIds[0]},
		                            additionalInformation: this.annexaFormly.model.modal_body.additionalInformation
	                    		};
	                    		TramFactory.createRelatedThird(dossierIds[0], newContent).then(function(data){
	                            	box.boxDefinition.content.push(dossier[0]);
	                    			self.close();
	                    		}).catch(function(error){
	                    			DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
	                    		});
	                    	}
	                    	var dossierIds = $linq(dossier).select("x => x.id").toArray();
	                    	var dossiersAux = angular.copy(box.boxDefinition.content);
	                    	if(dossierIds && dossierIds.length > 0){
	                    		_.forEach(dossierIds, function(id){
		                    		box.boxDefinition.content.pop();
	                    		});
	                    	}
	                    	TramFactory.getRelatedThirds(dossierIds[0]).then(function(data){
		                    	var modal = angular.copy(globalModals.receiverAdd);
		                        modal.size = 'modal-md';
		                        modal.annexaFormly.model = {};
		                        modal.annexaFormly.model.modal_body = { third: ThirdFactory.Third.id, interested: false, representative: false, object: angular.copy(ThirdFactory.Third) };
		                        modal.annexaFormly.options = {};
		                        modal.annexaFormly.options.formState = {readOnly: false, data: data};
		                      
		                        AnnexaFormlyFactory.showModal("modalSearchAdd", modal, addThirdToDosier, false);
	                    	}).catch(function(error){
	                    		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
	                    	});
	                    };
	                    box.boxDefinition.deleteFunction = function (objectId) {
	                    	var index = $linq(box.boxDefinition.content).indexOf("x => x.id =="+objectId);
	                    	TramFactory.deleteRelatedThird(objectId, ThirdFactory.Third.id).then(function(data){
		                     	if(index >= 0){
		                     		box.boxDefinition.content.splice(index, 1);
		                     	}
		                    }).catch(function (error) {
		                    });
	                    };
	                }else{
	                	box = getBox('dossier', 'dossier-third', 'global.literals.related_dossiers','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers', false, false, false, false);
                	}
                    break;
                case 'registerEntry':
                	if(AnnexaPermissionsFactory.havePermission(['view_input_register', 'diligence_input_register','view_all_input_register','view_all_diligences', 'view_output_register','view_all_output_register','new_input_entry_register', 'new_output_entry_register'])){
	                	box = getBox('registerEntry', 'registerEntry-third', 'global.literals.assentaments', 'global.literals.placeholser_search_registerEntry', 'global.literals.advanced_search_registerEntry', false,true,true,false, undefined, undefined, undefined, undefined, undefined, true);
	                    box.boxDefinition.saveSearch = function (reg) { 
	                    	var getRepresentateds = function(data) {
	                            var retRepresentateds = [];
	                           	var representeds = $linq(data).where("x => x.representated != null").select("x => x.representated").toArray();
	                           	var interested = $linq(data).where("x => x.interested").except(representeds,"(x,y) => (x.id && (x.id == y.third.id)) || (x == y.third.id)").toArray();
	                           	_.forEach(interested, function (value) {
	                                var representated = value.third;
	                                if(value.third.completeName){
	                                	representated.label = value.third.completeName + ' (' + value.third.identificationDocument + ')';
	                            	}else if(value.third.name) {
	                                    representated.label = value.third.name + ((value.third.surname1Particles)? ' ' + value.third.surname1Particles : '') + ' ' + value.third.surename1 + ((value.third.surname2Particles)? ' ' + value.third.surname2Particles : '') + ((value.third.surename2)? ' ' + value.third.surename2 : '') + ' (' + value.third.identificationDocument + ')';
	                                }else{
	                                    representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
	                                }
	                                retRepresentateds.push(representated);
	                            });
	
	                            return retRepresentateds
	                        }
	                    	
	                    	var addThirdToReg = function(){
	                    		var self = this;
	                        	var thirdAddressId;
	                        	var thirdToAdd = angular.copy(ThirdFactory.Third);
	                        	if (this.model && this.model.thirdAddress) {
	                        		thirdAddressId = this.model.thirdAddress
	                        	}
	                    		var newContent = {
		                            third: {id:thirdToAdd.id},
		                            interested: (( this.model.thirdType == 'interested')?true:false),
		                            representative:  (( this.model.thirdType == 'representative')?true:false),
		                            principal: false,
		                            representated: ((this.model.representated)?{id:this.model.representated}:undefined),
		                            roleInterested: ((this.model.roleInterested)?{id:this.model.roleInterested}:undefined),
		                            registerEntry: {id: regIds[0]},
	                                thirdAddress: ((thirdAddressId)?{id: thirdAddressId}:undefined)
	                    		};
	                    		RegFactory.newRegisterEntryThird(regIds[0], newContent).then(function(data){
	                            	box.boxDefinition.content.push(reg[0]);
	                            	$rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalSearchAdd' });
	                    		}).catch(function(error){
	                    			DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
	                    		});
	                    	}
	                    	var regIds = $linq(reg).select("x => x.id").toArray();
	                    	var regsAux = angular.copy(box.boxDefinition.content);
	                    	if(regIds && regIds.length > 0){
	                    		_.forEach(regIds, function(id){
		                    		box.boxDefinition.content.pop();
	                    		});
	                    	}
	                    	RegFactory.getRelatedThirds(regIds[0]).then(function(data){
	                    		var addressesArray = function() {1
	                                var content = [];
	
	                                if(ThirdFactory.Third.addresses) {
	                                	var th = angular.copy(ThirdFactory.Third)
	                                	var lstAddresses = th.addresses;
	                                	var thirdAddress = new ThirdAddress(th, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
	                                	
	                                	if (lstAddresses && lstAddresses.length > 0) {
	                                		var addressWithConsentOrDefault = ThirdFactory.getThirdAddresByThirdWithConsent(th, undefined, true);
											var notificationTypes = angular.copy(GlobalDataFactory.notificationTypes);
											notificationTypes = ((notificationTypes)?$linq(notificationTypes).select("x => x.addressType").distinct().toArray():[]);
											_.forEach(lstAddresses, function (value) {
												if(_.contains(notificationTypes, value.addressType)){
		                                    		var thirdAddressId = value.id;
		                                    		var addressHtml = thirdAddress.getAddressHtml(value, false, true);
		                                    		var active = false;
		                                    		var activeType = '';
		                                    		if(addressWithConsentOrDefault && addressWithConsentOrDefault.address && addressWithConsentOrDefault.address.id && value.id && value.id == addressWithConsentOrDefault.address.id){
		                                    			active = true;
		                                    			if(addressWithConsentOrDefault.isDefault){
		                                    				activeType = $filter('translate')('global.literals.defaultValue');
		                                    			}else{
		                                    				activeType = $filter('translate')('global.literals.withConsentAddress');
		                                    			}
		                                    		}
		                                    		var address = {value: thirdAddressId, label: addressHtml, active:active, activeType: activeType};  
		                                    		content.push(address);
												}
	                                    	});
	                                	}
	                                	
	                                }
	                                return content;
	                            }
	                    		
		                    	var modal = {
	                                title: 'global.literals.edit_receiver',
	                                size: '',
	                                fieldsDefinition: [
	                                	{ type: 'field', id: 'thirdType', fieldType: 'radio_checkbox', isRadio: true, 
	                                		data: [{value: 'interested', label: $filter('translate')('global.literals.interested')},
	                                			   {value: 'representative', label: $filter('translate')('global.literals.representative')}],
	                                		defaultValue: 'interested',	   
	                                		colClass: 'col-sm-12', required: true, label: 'global.literals.type' , labelProp: 'label'},
	                            		{ type: 'field', id: 'roleInterested', fieldType: 'select', data: GlobalDataFactory.roleInteresteds, required: false, label: 'global.literals.roleInterested', labelProp: 'label',
	                                		defaultValue: undefined,
	                                		hideExpression: function($viewValue, $modelValue, scope) {
	                                			var hide = true;
	                                        	if (scope.model.thirdType == 'interested' && GlobalDataFactory.roleInteresteds) {
	                                        		hide = false;
	                                        	}
	                                        	return hide;
	                                		},	
	                                		controller: ['$scope', function($scope) { 
	                                			$scope.to.labelProp = Language.getActiveColumn();
	                                        	$scope.to.options = GlobalDataFactory.roleInteresteds;
	                                        }]
	                                	},	
	                                    { type: 'field', id: 'representated', fieldType: 'select', data: getRepresentateds(data), required: true, label: 'global.literals.representated', labelProp: 'completeName',
	                                		defaultValue: undefined,	
	                                        hideExpression: function($viewValue, $modelValue, scope) { return scope.model.thirdType != 'representative'; },
	                                        controller: ['$scope', function($scope) { 
	                                        	$scope.to.options = getRepresentateds(data)
	                                        }]
	                                    },
	                                    { type: 'field', id: 'thirdAddress', fieldType: 'radio_checkbox', isRadio: true, data: addressesArray(), colClass: 'col-sm-12', required: false, label: 'global.literals.notificationChannel' , labelProp: 'label',
	                                    	defaultValue: undefined,
	                                        controller: ['$scope', function($scope) { 
	                                        	$scope.to.options = addressesArray()
	                                        }]},
	                                ],
	                                alerts: []
	                            };     
		                        modal.size = 'modal-md';
		                        AnnexaFormlyFactory.showAnnexaFormModal('modalSearchAdd', modal, addThirdToReg);
	                    	}).catch(function(error){
	                    		DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
	                    	});
	                    };
	                    box.boxDefinition.deleteFunction = function (objectId) {
	                    	var index = $linq(box.boxDefinition.content).indexOf("x => x.id =="+objectId);
	                    	RegFactory.deleteRegisterEntryThirdWithThird(objectId, [ThirdFactory.Third.id]).then(function(data){
		                     	if(index >= 0){
		                     		box.boxDefinition.content.splice(index, 1);
		                     	}
		                    }).catch(function (error) {
		                    });
	                    };
                	}else{
                		box = getBox('registerEntry', 'registerEntry-third', 'global.literals.assentaments', 'global.literals.placeholser_search_registerEntry', 'global.literals.advanced_search_registerEntry', false, false, false, false);
                	}
                    break;
            }

            return box;
        };

        factory.getNewThirdForm = function (thirdModel) {
            var entity = new AnnexaEntity('new-third', 'form', thirdNewForm('annexa.thirds', thirdModel), thirdBoxes()).setTitle('global.literals.basicInfo').setCancelState('annexa.thirds');

            if (thirdModel) {
            	if (thirdModel.identificationReferences) {
                	var thirdModelIR = [];
    				_.forEach(thirdModel.identificationReferences, function(val) {
    					var identificationReference = { identificationReference: val.identificationReference, identificationReferenceType: val.identificationReferenceType }
    					thirdModelIR.push(identificationReference);
    				});
                	entity.boxes[0].boxDefinition.content = thirdModelIR;
            	}
                if (thirdModel.addresses) {
                	entity.boxes[1].boxDefinition.content = thirdModel.addresses;
                }
            }

            return entity;
        };

        factory.showNewThirdModal = function (boxDefinition, thirdModel) {
            var date = new Date();
            var modal = angular.copy(globalModals.receiverNew);
            modal.title = 'global.literals.new_receiver_modal_title';
            if(boxDefinition && boxDefinition.entryType){
                if(boxDefinition.entryType == 'INPUT'){
                    modal.title = 'global.literals.newRemitent';
                }else if(boxDefinition.entryType == 'OUTPUT'){
                    modal.title = 'global.literals.new_receiver_modal_title';
                }
            } else if (boxDefinition.renderType && boxDefinition.renderType == 'third-task') {
            	modal.title = 'global.literals.new_third_modal_title';
            }

            if (thirdModel) {
            	modal.annexaFormly.model = thirdModel;
            }

            var entity = new AnnexaEntity(date.getTime() + '', 'modal', thirdBoxNewForm(boxDefinition, modal), thirdBoxes());

            if (thirdModel) {
            	if (thirdModel.identificationReferences) {
                	var thirdModelIR = [];
    				_.forEach(thirdModel.identificationReferences, function(val) {
    					var identificationReference = { identificationReference: val.identificationReference, identificationReferenceType: val.identificationReferenceType }
    					thirdModelIR.push(identificationReference);
    				});
                	entity.boxes[0].boxDefinition.content = thirdModelIR;
            	}
                if (thirdModel.addresses) {
                	entity.boxes[1].boxDefinition.content = thirdModel.addresses;
                }
            }

            if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
                var contactMail = null;
                if(boxDefinition.user.email){
                    contactMail = {};
                    contactMail.addressType = 'TELEMATIC_EMAIL';
                    contactMail.key = CommonService.guid();
                    contactMail.default =  true;
                    contactMail.defaultByType = true;
                    contactMail.telematicValue = boxDefinition.user.email;
                    entity.boxes[0].boxDefinition.content.push(contactMail);
                }
                var contactCellPhone = null;
                if(boxDefinition.user.cellPhone){
                    contactCellPhone = {};
                    contactCellPhone.addressType = 'TELEMATIC_MOBILEPHONE';
                    contactCellPhone.key = CommonService.guid();
                    contactCellPhone.default = (!contactMail)?true:false;
                    contactCellPhone.defaultByType = true;
                    contactCellPhone.telematicValue = boxDefinition.user.cellPhone;
                    entity.boxes[0].boxDefinition.content.push(contactCellPhone);
                }
                var contactPhone = {};
                if(boxDefinition.user.phone){
                    contactPhone.addressType = 'TELEMATIC_MOBILEPHONE';
                    contactPhone.key = CommonService.guid();
                    contactPhone.default =  (!contactMail && !contactCellPhone)?true:false;
                    contactPhone.defaultByType = (!contactCellPhone)?true:false;
                    contactPhone.telematicValue = boxDefinition.user.phone;
                    entity.boxes[0].boxDefinition.content.push(contactPhone);
                }
            }
            showModal(modal, entity);
        };

        //endregion

        //region Tram
        var getTramBoxesModel = function (formExtra) {
        	var checkRegisterAddresses = false;
        	var checkTaskAddresses = false;
            if($state.params && $state.params.register && $state.params.register.addresses && $state.params.register.addresses.length > 0){
            	checkRegisterAddresses = true;
            }
            if($state.params && $state.params.task && $state.params.task.addresses && $state.params.task.addresses.length > 0){
            	checkTaskAddresses = true;
            }
        	if(!(TramNewFactory.Procedure && (TramNewFactory.Procedure.allowHaveTerritorialAddress || checkRegisterAddresses || checkTaskAddresses))){
                return {
                    receivers: angular.copy(formExtra[0].boxDefinition.content),
                    documents: angular.copy(formExtra[1].boxDefinition.content),
                    registerInputs: angular.copy(formExtra[2].content),
                    registerOutputs: angular.copy(formExtra[3].content),
                    dossiers: angular.copy(formExtra[4].boxDefinition.content),
    				customFields: angular.copy(formExtra[5].content)
                };
        	}else{
        		return {
                    receivers: angular.copy(formExtra[0].boxDefinition.content),
                    documents: angular.copy(formExtra[1].boxDefinition.content),
                    registerInputs: angular.copy(formExtra[2].content),
                    registerOutputs: angular.copy(formExtra[3].content),
                    dossiers: angular.copy(formExtra[4].boxDefinition.content),
                    addresses: angular.copy(formExtra[5].content),
    				customFields: angular.copy(formExtra[6].content)
                };
        	}
        };


        var tramFormFields = function(colClass) {
            var form = new AnnexaFormly();
            var fields = {};
            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong'
            };
            if (TramNewFactory.Procedure.allowManualDossierNumbering) {
            	fields.numSeqManual = form.createField('numSeqManual','annexaInputRow', '', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.numSeqManualNum', true, false), data);
            	fields.yearManualNumbering = form.createField('yearManualNumbering','annexaInputRow', '', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.yearManualNum', true, false, 1, 4), data);
            } else {
                fields.numSeqManual = undefined;
                fields.yearManualNumbering = undefined;
            }
            if(TramNewFactory.Procedure.allowUserChangeMaxDays) {
                fields.allowUserChangeMaxDays = form.createField('maxDays','annexaInputRow', '', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.expire', true, false), data);
                fields.allowUserChangeMaxDaysExpireType = form.createField('expireType','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.expireType','id','description',apiAdmin.expirationTypeList,true), data);
            } else {
                fields.allowUserChangeMaxDays = undefined;
                fields.allowUserChangeMaxDaysExpireType = undefined;
            }
            if(!TramNewFactory.Procedure.guided && TramNewFactory.Procedure.transactionTypes && TramNewFactory.Procedure.transactionTypes.length > 1) {
                var transactionTypesAux = [];
                angular.forEach(TramNewFactory.Procedure.transactionTypes,function(value, key){
                    transactionTypesAux.push(value.transactionType);
                });
                if(transactionTypesAux && transactionTypesAux.length > 0){
                	transactionTypesAux = $linq(transactionTypesAux).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                fields.transactionTypes = form.createField('transactionType','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialTransactionType','id',Language.getActiveColumn(),transactionTypesAux,true, undefined, function(item, options) {
                                             var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                             $rootScope.$broadcast('newTransactionTypeSelectedOnStart', { item: item });
                                         }), data);
            } else {
                fields.transactionTypes = undefined;
            }
            if(!TramNewFactory.Procedure.guided){
                fields.tramitationType = form.createField('tramitationType','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitationType','id',Language.getActiveColumn(),GlobalDataFactory.dossierTramitationTypes,true), data);
            }
            switch (TramNewFactory.Procedure.showSubject) {
                case "OPTIONAL":
                    fields.subject = form.createField('subject','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.subject',false,false,undefined,255),data);
                    break;
                case "REQUIRED":
                    fields.subject = form.createField('subject','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.subject',true,false,undefined,255),data);
                    break;
            }
            var literalExternalCode = (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.literal_dossier_external_code && $rootScope.app.configuration.literal_dossier_external_code.value) ? $rootScope.app.configuration.literal_dossier_external_code.value : 'global.literals.externalCode');
            fields.externalCode = form.createField('externalCode','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text',literalExternalCode,false,false,undefined,255),data);
            fields.archiveClassification = form.createField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',Language.getActiveColumn(),CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn()),false),data);
            var councillors = GlobalDataFactory.councillors;
            if(councillors && councillors != undefined && councillors.length > 0) {
            	fields.councillor = form.createField('councillor','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.councillor','id',Language.getActiveColumn(),CommonService.getTreeData(GlobalDataFactory.councillors, Language.getActiveColumn()),false),data);
            } 
			if(fields.councillor && fields.councillor.templateOptions){
				fields.councillor.templateOptions.onlyLastLevelClick = true;
			}
            switch (TramNewFactory.Procedure.showClassification) {
                case "OPTIONAL":
                    fields.classification = form.createField(
                        'classification',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.classification',
                            'id',
                            Language.getActiveColumn(),
                            TramNewFactory.Procedure.procedureClassifications,
                            false
                        ),
                        data
                    );
                    break;
                case "REQUIRED":
                    fields.classification = form.createField(
                        'classification',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.classification',
                            'id',
                            Language.getActiveColumn(),
                            TramNewFactory.Procedure.procedureClassifications,
                            true
                        ),
                        data
                    );
                    break;
            }

            fields.observations = form.createField('observations', 'annexaTextAreaRow','', new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'), data);
            
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.useEmgdeSecurityToFilter && $rootScope.app.configuration.useEmgdeSecurityToFilter.filterDocuments){
            	fields.documentTypeCanSee =form.createField('documentTypeCanSee', 'annexaMultipleSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.documentTypeCanSeeProcedure', 'id', Language.getActiveColumn(), angular.copy(GlobalDataFactory.documentTypes)),data);
            }
            
            //Perfiles, dependiendo si es o no E-SET
            if($rootScope.esetMode){
            	//Modo E-SET
                var profilesResponsibleTransaction = [];
                var responsiblesTransaction = [];
                if(TramNewFactory.Procedure && TramNewFactory.Procedure.dossierResponsibleProfiles && TramNewFactory.Procedure.dossierResponsibleProfiles.length > 0){
                    profilesResponsibleTransaction = TramNewFactory.Procedure.dossierResponsibleProfiles;
                }else if(TramNewFactory.Procedure && TramNewFactory.Procedure.procedureStartProfiles && TramNewFactory.Procedure.procedureStartProfiles.length > 0){
                    profilesResponsibleTransaction = TramNewFactory.Procedure.procedureStartProfiles;
                }
                if(profilesResponsibleTransaction) {
                	_.forEach(profilesResponsibleTransaction, function(value){
                    	value.profile.userProfiles = $linq(value.profile.userProfiles).where("x => x.user.deleted == false && x.user.expiryDate == null").toArray();
                    });
                }
                if(profilesResponsibleTransaction && profilesResponsibleTransaction.length == 1){
                    _.forEach(profilesResponsibleTransaction[0].profile.userProfiles, function(value){
                    	if(!value.user.deleted && !value.user.expiryDate){
                    		responsiblesTransaction.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
                    	}
                    });
                }else {
                    fields.profilesTransaction = form.createField(
                        'profilesTransaction',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.initialProfile',
                            'id',
                            Language.getActiveColumn(),
                            $linq(profilesResponsibleTransaction).select("x => x.profile").toArray(),
                            true,
                            undefined,
                            function($item, $model) {
                                if($item.userProfiles && $item.userProfiles.length > 0) {
                                    responsiblesTransaction.length = 0;
                                    _.forEach($item.userProfiles, function(value){
                                    	if(!value.user.deleted && !value.user.expiryDate) {
                                    		responsiblesTransaction.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
                                    	}
                                    });
                                }else{
                                    responsiblesTransaction.length = 0;
                                }
                            }
                        ),
                        data
                    );
                }
                fields.responsibleTransaction = form.createField(
                    'responsibleTransaction',
                    'annexaRadioCheckboxRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.initialuserResponsible',
                        'id',
                        'name',
                        responsiblesTransaction,
                        true,
                        'radio'
                    ),
                    data
                );
                
            } else {
	            fields.profiles = [];
                var defaultProfileUserEXP = ((TramNewFactory.userDefaultEntityProfile && TramNewFactory.userDefaultEntityProfile.profile)?TramNewFactory.userDefaultEntityProfile.profile:undefined);
				var responsiblesTransaction = [];
                if(defaultProfileUserEXP) {
                    if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureResponsibleProfiles && TramNewFactory.Procedure.procedureResponsibleProfiles.length > 0) {

                        var tramResponsible = TramNewFactory.Procedure.procedureResponsibleProfiles;
                        var tramResponsibleProfile = $linq(tramResponsible).select("x => x.profile").toArray();

                        fields.profiles = form.createField(
                            'profiles', 'annexaMultipleSelectRow', '',
                            new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
                                Language.getActiveColumn(), tramResponsibleProfile, true),
                            data);
                    }
                    else {
                        if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureStartProfiles && TramNewFactory.Procedure.procedureStartProfiles.length > 0) {

                            var tramStart = TramNewFactory.Procedure.procedureStartProfiles;
                            var tramStartProfile = $linq(tramStart).select("x => x.profile").toArray();
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                            //Si el procedimiento no tiene definidos perfiles responsables, solo los perfiles iniciadores que tiene el usuario. 
                            tramStartProfile = $linq(tramStartProfile).intersect(userProfiles, "(x,y) => x.id == y.id").toArray();

                            fields.profiles = form.createField(
                                'profiles', 'annexaMultipleSelectRow', '',
                                new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
                                    Language.getActiveColumn(), tramStartProfile, true),
                                data);
                        }
                        // si no existen los que inicializa y tampoco responsables.
                        else {
                            fields.profiles = form.createField(
                                'profiles', 'annexaLabelRow', 'col-sm-6 col-md-6 col-xl-6 col-lg-6',
                                new AnnexaFormlyFieldLabelTemplateOptions('global.literals.initialProfiles',
                                    defaultProfileUserEXP.profile[Language.getActiveColumn()]),
                                data);
                        }
                    }
                }
                else{
                    if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureResponsibleProfiles && TramNewFactory.Procedure.procedureResponsibleProfiles.length > 0) {

                        var tramRespon = TramNewFactory.Procedure.procedureResponsibleProfiles;
                        var tramResponProfile = $linq(tramRespon).select("x => x.profile").toArray();

                        fields.profiles = form.createField(
                            'profiles', 'annexaMultipleSelectRow', '',
                            new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
                                Language.getActiveColumn(), tramResponProfile, true),
                            data);
                    }
                    else {
                        if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureStartProfiles && TramNewFactory.Procedure.procedureStartProfiles.length > 0) {

                            var tramInicia = TramNewFactory.Procedure.procedureStartProfiles;
                            var tramIniciaProfile = $linq(tramInicia).select("x => x.profile").toArray();
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                            //Si el procedimiento no tiene definidos perfiles responsables, solo los perfiles iniciadores que tiene el usuario. 
                            tramIniciaProfile = $linq(tramIniciaProfile).intersect(userProfiles, "(x,y) => x.id == y.id").toArray();

                            fields.profiles = form.createField(
                                'profiles', 'annexaMultipleSelectRow', '',
                                new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
                                    Language.getActiveColumn(), tramIniciaProfile, true),
                                data);
                        }
                    }
                }
				if($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
						($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')){
					var isRequired =  (($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')?true:false);
					fields.responsibleTransaction = form.createField(
	                    'responsibleTransaction',
	                    'annexaRadioCheckboxRow',
	                    '',
	                    new AnnexaFormlyFieldSelectTemplateOptions(
	                        'global.literals.initialuserResponsible',
	                        'id',
	                        'name',
	                        [],
	                        isRequired,
	                        'radio'
	                    ),
	                    data
	                );
					fields.responsibleTransaction.watcher = [
                        {
                            type: '$watchCollection',
                            expression: 'model.profiles',
                            listener: function(field, _new, aux,scope) {
								if(!field.templateOptions.options){
									field.templateOptions.options = [];
								}else{
									field.templateOptions.options.length = 0;
								}
								if(_new && _new.length > 0 && scope.fields){
									var profileField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == 'profiles'")
									if(profileField && profileField.type == "annexaMultipleSelectRow"){
										if(profileField.templateOptions && profileField.templateOptions.options){
											var opts = $linq(profileField.templateOptions.options).intersect(_new, "(x,y) => x == y.id").toArray();
											if(opts && opts.length > 0){
												_.forEach(opts, function(opt){
													if(opt.userProfiles){
														_.forEach(opt.userProfiles, function(value){
					                                    	if(value.user && !value.user.deleted && !value.user.expiryDate) {
																if(!$linq(field.templateOptions.options).contains(value.user.id, "(x,y) => x.id == y")){
					                                    			field.templateOptions.options.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
																}
					                                    	}
					                                    });
													}
												});
											}
										}
									}else if(profileField && profileField.type == "annexaLabelRow"){
										if(TramNewFactory.userDefaultEntityProfile && TramNewFactory.userDefaultEntityProfile.profile  && dTramNewFactory.userDefaultEntityProfile.profile.userProfiles){
											_.forEach(TramNewFactory.userDefaultEntityProfile.profile.userProfiles, function(value){
		                                    	if(value.user && !value.user.deleted && !value.user.expiryDate) {
													if(!$linq(field.templateOptions.options).contains(value.user.id, "(x,y) => x == y.id")){
		                                    			field.templateOptions.options.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
													}
		                                    	}
		                                    });
										}
									}else if(!profileField && field.templateOptions && field.templateOptions.userProfilesAdded && field.templateOptions.userProfilesAdded.length > 0){
										_.forEach(field.templateOptions.userProfilesAdded, function(value){
		                                    	if(value.user && !value.user.deleted && !value.user.expiryDate) {
													if(!$linq(field.templateOptions.options).contains(value.user.id, "(x,y) => x == y.id")){
		                                    			field.templateOptions.options.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
													}
		                                    	}
		                                    });
									}
								}else if(!_new || (_new && _new.length == 0)){
									scope.model.responsibleTransaction = undefined;
								}
							}
                    	}
					]
				}
            }
            
            if(HelperService.isSuperAdmin()) {
            	var userSections = GlobalDataFactory.sections;
            } else {
            	var userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
            }
            var addSection = false;

            if(userSections && userSections.length == 1) {
                var sectionValue = userSections[0];
                var field13 = form.createField(
                    'section',
                    'annexaHidden',
                    colClass,
                    new AnnexaFormlyFieldTemplateOptions(
                        'hidden',
                        '',
                        true
                    ),
                    data,
                    undefined,
                    sectionValue
                )
                fields.section2 = field13;
            } else {
                fields.section1 = form.createField(
                    'section',
                    'annexaSelectRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.section',
                        'id',
                        Language.getActiveColumn(),
                        ((userSections)?userSections:[]),
                        true
                    ),
                    data
                );
            }
            
            var requiredTramClassification = false;
            var showTramClassification = false;
            if($rootScope.app.configuration.tram_classification_dossier) {
                requiredTramClassification = $rootScope.app.configuration.tram_classification_dossier.required;
                showTramClassification = $rootScope.app.configuration.tram_classification_dossier.show;
            }
                        
            if(showTramClassification){
                fields.tramClassification = form.createField('tramClassification','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramClassification','id',Language.getActiveColumn(),GlobalDataFactory.tramClassifications,requiredTramClassification), data);
            }
            
            return fields;
        };

        var tramNewForm = function(saveOkState, scope) {
            var form = new AnnexaFormly();
            
            if(form && !form.model){
                form.model = {};
            }
            var fields = tramFormFields('col-sm-12');

            if(fields.subject) {
                form.fields.push(fields.subject);
            }
            if(fields.externalCode){
            	form.fields.push(fields.externalCode);
            }
            if(fields.archiveClassification){
                form.fields.push(fields.archiveClassification);
            }
            if(fields.councillor){
                form.fields.push(fields.councillor);
            }
            if(fields.tramClassification){
                form.fields.push(fields.tramClassification);
            }
            if(fields.classification) {
                form.fields.push(fields.classification);
            }
            if(fields.numSeqManual) {
                form.fields.push(fields.numSeqManual);
            }
            if(fields.yearManualNumbering) {
                form.fields.push(fields.yearManualNumbering);
            }
            if(fields.allowUserChangeMaxDays) {
                form.fields.push(fields.allowUserChangeMaxDays);
            }
            if(fields.allowUserChangeMaxDaysExpireType) {
                form.fields.push(fields.allowUserChangeMaxDaysExpireType);
            }
            if(fields.transactionTypes) {
                form.fields.push(fields.transactionTypes);
            }
            if(fields.tramitationType && !$rootScope.esetMode){
                form.fields.push(fields.tramitationType);
            }

            // Para el formulario de Iniciar Nuevo expediente, agregamos perfiles por defecto si tiene.
            if( fields.profiles ) {
                var defaultProfileUserEXP = ((TramNewFactory.userDefaultEntityProfile)?TramNewFactory.userDefaultEntityProfile.profile:undefined);
                form.model.profiles = [];

                if (defaultProfileUserEXP) {

                    if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureResponsibleProfiles && TramNewFactory.Procedure.procedureResponsibleProfiles.length > 0) {

                        var tramResponsible = TramNewFactory.Procedure.procedureResponsibleProfiles;
                        var tramResponsibleProfile = $linq(tramResponsible).select("x => x.profile").toArray();

                        // si alguno de los responsables es tambien el de por defecto del usuario, hacemos ese por defecto en el campo del Select.
                        for (var i = 0; i < tramResponsibleProfile.length; i++) {
                            if (tramResponsibleProfile[i].id === defaultProfileUserEXP.id) {
                                form.model.profiles.push(defaultProfileUserEXP.id);
                            }
                        }
                    }
                    else {
                        if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureStartProfiles && TramNewFactory.Procedure.procedureStartProfiles.length > 0) {

                            var tramStart = TramNewFactory.Procedure.procedureStartProfiles;
                            var tramStartProfile = $linq(tramStart).select("x => x.profile").toArray();

                            // si alguno de los inicializa es tambien el de por defecto del usuario, hacemos ese por defecto en el campo del Select.
                            for (var cont = 0; cont < tramStartProfile.length; cont++) {
                                if (tramStartProfile[cont].id === defaultProfileUserEXP.id) {
                                    form.model.profiles.push(defaultProfileUserEXP.id);
                                }
                            }
                        }
                    }
                }
                
                if (form.model.profiles.length == 0 &&
                		fields.profiles.templateOptions &&
                		fields.profiles.templateOptions.options && 
                		fields.profiles.templateOptions.options.length == 1) {
                	//Si solo puedo asignar un perfil se selecciona automáticamente
                	form.model.profiles.push(fields.profiles.templateOptions.options[0].id);
					if(fields.responsibleTransaction && fields.responsibleTransaction.templateOptions){
						fields.responsibleTransaction.templateOptions.userProfilesAdded = ((fields.profiles.templateOptions.options[0].userProfiles)?fields.profiles.templateOptions.options[0].userProfiles:[]);
					}
                } else {
                	//Solo se muestra el campo si hay mas de un perfil para serleccionar
                	form.fields.push(fields.profiles);
                }
				if(fields.responsibleTransaction) {
                    form.fields.push(fields.responsibleTransaction);
                }
            }
            if(fields.section1) {
                form.fields.push(fields.section1);
            }else if(fields.section2){
                form.fields.push(fields.section2);
            }
            
            if($rootScope.esetMode) {
                if(fields.profilesTransaction) {
                    form.fields.push(fields.profilesTransaction);
                }
                if(fields.responsibleTransaction) {
                    form.fields.push(fields.responsibleTransaction);
                }
            }
            
            if(fields.documentTypeCanSee){
                form.fields.push(fields.documentTypeCanSee);
                if (TramNewFactory.Procedure && TramNewFactory.Procedure.procedureDocumentTypeCanSee && TramNewFactory.Procedure.procedureDocumentTypeCanSee.length > 0) {
                	form.model.documentTypeCanSee = [];
                    var documentTypeCanSee = TramNewFactory.Procedure.procedureDocumentTypeCanSee;
                    var documentType = $linq(documentTypeCanSee).select("x => x.documentType").toArray();

                    for (var i = 0; i < documentType.length; i++) {
                        if (documentType[i].id) {
                            form.model.documentTypeCanSee.push(documentType[i].id);
                        }
                    }
                }
            }
            
            form.fields.push(fields.observations);
            
            var openModalAddress = function (){
            	var modal = angular.copy(globalModals.editAddressCF);
            	
            	var defaultCountry = $rootScope.app.configuration.default_country;
            	if(defaultCountry && defaultCountry.value){
                    defaultCountry = defaultCountry.value;
                }else{
                    defaultCountry = '';
                }
            	
                modal.annexaFormly.model = {};
                modal.cfValueId = 'cfValue_' + this.step;
                modal.cfId = 'cf_' + this.step;
                modal.renderAddress = factory.postalAddressRender;
                
                angular.forEach(form.fields,function (field, key) {
           		   if(field.key == modal.cfId) {
           			   modal.cfAddressField = field;
           		   }
           		   if(field.key == modal.cfValueId) {
           			   modal.cfAddressValue = field;
           		   }
           	   	});
                modal.annexaFormly.model.modal_body = {};
                
                if(modal.cfAddressField && modal.cfAddressField.value()) {
                	modal.annexaFormly.model.modal_body = modal.cfAddressField.value();
                }
                
                if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                    modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                }
                
                modal.annexaFormly.model.modal_body.hasStates = true;
                modal.annexaFormly.model.modal_body.hasCities = true;
                modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                
                modal.extra = this;
                
                
                var submitFunction = function() { 
                	var self = this;
                	
                	var addressCf = {};
                	var address = {};
                	addressCf.cfId = this.cfId;
                    var addressAux = this.annexaFormly.model.modal_body;
                    
                    if(addressAux.country) {
                    	address.country = addressAux.country;
                    }
                    if(addressAux.hasStates) {
                        if (addressAux.validState && addressAux.validState.id) {
                        	address.validState = addressAux.validState;
                        }
                    }else{
                    	address.stateAddress = addressAux.stateAddress;
                    }
                    if(addressAux.hasCities){
                        if(addressAux.validCity && addressAux.validCity.id) {
                        	address.validCity = addressAux.validCity;
                        }
                    }else{
                    	address.city = addressAux.city;
                    }
                    if(addressAux.hasStreetTypes && addressAux.streetType) {
                    	address.streetType = addressAux.streetType;
                    }
                    
                    address.street = addressAux.street;
                    address.numberAddres = addressAux.numberAddres;
                    address.postalCode = addressAux.postalCode;
                	address.isValidNumber = false;
                    address.floor = addressAux.floor;
                    address.block = addressAux.block;
                    address.door = addressAux.door;
                    address.stair = addressAux.stair;
                    
                    modal.cfAddressValue.value(modal.renderAddress(address));
                    modal.cfAddressField.value(address);
                	
                	self.close();	
                }
                
                AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
            };
			if(scope){
				scope.$on('newTransactionTypeSelectedOnStart', function (event, args) {
                    if (args.item && args.item.id != undefined && form && form.fields) {
						var ffNew = [];
                    	_.forEach(form.fields, function(ff){
							if(!(ff.key && ff.key.startsWith('cf_') && ff.templateOptions && ff.templateOptions.ttypeAdded && ff.templateOptions.ttypeAdded !== args.item.id)){
								ffNew.push(ff);
							}
						});
						form.fields.length = 0;
						_.forEach(ffNew, function(ff){
							form.fields.push(ff);
						});
						
						var cffields = [];
						if(TramNewFactory.Procedure && TramNewFactory.Procedure.customFields){
							var cffieldsAux = $linq(TramNewFactory.Procedure.customFields).where(function(x){
								if(x && ((x.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE' || x.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' || x.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' || x.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY') && x.transactionTypes 
								&& $linq(x.transactionTypes).contains(args.item.id, "(x, y) => x.transactionType && x.transactionType.id == y"))){
									return true;
								}else if(x && x.customFieldType != 'DOSSIER' && x.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && x.customFieldType != 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' && x.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && x.customFieldType !== 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY'){  
									return true;
								}else{
									return false;
								}
							}).toArray();
							if(cffieldsAux && cffieldsAux.length > 0){
								_.forEach(cffieldsAux, function(cf){
									cf.ttypeAdded = args.item.id;
								});
								if(cffields && cffields.length > 0){
									cffields = $linq(cffields).union(cffieldsAux, "(x,y) => x.customField && y.customField && x.customField.id == y.customField.id").orderBy("x => x.viewOrder").toArray();
								}else{
									cffields = $linq(cffieldsAux).orderBy("x => x.viewOrder").toArray();
								}
							}
						}
						if(TramNewFactory.initialTransactioTypeCustomFields && TramNewFactory.initialTransactioTypeCustomFields[args.item.id]){
							_.forEach(TramNewFactory.initialTransactioTypeCustomFields[args.item.id], function(cf){
								cf.ttypeAdded = args.item.id;
			                	cf.originType = 'TransactionType';
							});
							if(cffields && cffields.length > 0){
								cffields = $linq(cffields).union(TramNewFactory.initialTransactioTypeCustomFields[args.item.id], "(x,y) => x.customField && y.customField && x.customField.id == y.customField.id").orderBy("x => x.viewOrder").toArray();
							}else{
								cffields = $linq(TramNewFactory.initialTransactioTypeCustomFields[args.item.id]).orderBy("x => x.viewOrder").toArray();
							}
						}
						if(cffields && cffields.length > 0){
							cffields = angular.copy(cffields);
							var descriptionLanguageColumn = "descriptionL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
							var nameLanguageColumn = "nameL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
							angular.forEach(cffields,function (value, key) {
								var existCfInActualList = $linq(form.customFieldsAdded).firstOrDefault(undefined, "x => x.id == "+value.id);
								if(!existCfInActualList){
									if(value && !value.hiddenField) {
										form = CustomFieldFactory.addFormlyFieldRow(form, value.customField, value.required, value.customField.id, value.noEditable, TramNewFactory.Procedure, "PROCEDURE", true, value, cffields);
										if(form && form.fields){
											var formCFActual =  $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_"+value.customField.id+"'");
											if(!formCFActual){
												formCFActual =  $linq(form.fields).firstOrDefault(undefined,"x => x.key == '"+value.customField.templateTag+"'");
											}
											if(formCFActual){
												if(value[nameLanguageColumn]){
													formCFActual.templateOptions.label = value[nameLanguageColumn];
												}
												if(value[descriptionLanguageColumn]){
													formCFActual.templateOptions.label = formCFActual.templateOptions.label + ' ('+ value[descriptionLanguageColumn] + ')';
												}
												formCFActual.templateOptions.ttypeAdded = value.ttypeAdded;
												if(TramNewFactory.Procedure && TramNewFactory.Procedure.initCfieldWhenSaved){
													formCFActual.hideExpression = function($viewValue, $modelValue, scope) {
														return true;
													}
													formCFActual.hide = true;
												}
											}
											if(value.customField && value.customField.frontendType === "CF_GROUP"){
											   var formCFActualAux = $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_"+value.customField.id+"_Label'");
											   if(!formCFActualAux){
												   formCFActualAux = $linq(form.fields).firstOrDefault(undefined,"x => x.key == '"+value.customField.templateTag+"_Label'");
											   }
											   if(formCFActualAux){
												   formCFActualAux.templateOptions.ttypeAdded = value.ttypeAdded;
											   }   
											   if(TramNewFactory.Procedure && TramNewFactory.Procedure.initCfieldWhenSaved){ 
												   formCFActualAux.hideExpression = function($viewValue, $modelValue, scope) {
														return true;
												   }
												   formCFActualAux.hide = true;
											   }
										   }
										}
										if(value.customField.backendType == 'ADDRESS') {
											angular.forEach(form.fields,function (field, key) {
												if(field.key == 'cfValue_' + value.customField.id) {
													field.templateOptions.disabled = true;
													if(value.value) {
														form.model['cfValue_' + value.customField.id] = factory.postalAddressRender(JSON.parse(value.value));
														form.model['cf_' + value.customField.id] = JSON.parse(value.value);
													}
												}
												if(field.key == 'addressBtn_' + value.customField.id) {
													field.templateOptions.executeFunction = openModalAddress;
												}
											});
										} else {
											form.model['cf_' + value.customField.id] = CustomFieldFactory.calculateValueCustomField(value, form.model, ('cf_' +  value.customField.id));
										}
									}
								}
							});
						}
						form.customFieldsAdded = cffields;	
                    }
                });
			}
            var cfields = [];
            if(TramNewFactory.initialTransaction) {
                cfields = $linq(TramNewFactory.initialTransaction.customFields).orderBy("x => x.viewOrder").toArray();
                _.forEach(cfields, function(cfield){
                	cfield.originType = 'Transaction';
                });
            }
			if(TramNewFactory.Procedure && TramNewFactory.Procedure.customFields){
				var cfieldsAux = $linq(TramNewFactory.Procedure.customFields).where(function(x){
					if(x && (x.customFieldType == 'ALL_DOSSIER_TRANSACTIONS' || x.customFieldType == 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' || x.customFieldType == 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY' || x.customFieldType == 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY')){
						return true;
					}else{
						return false;
					}
				}).toArray();
				if(cfields && cfields.length > 0){
					cfields = $linq(cfields).union(cfieldsAux, "(x,y) => x.customField && y.customField && x.customField.id == y.customField.id").orderBy("x => x.viewOrder").toArray();
				}else{
					cfields = $linq(cfieldsAux).orderBy("x => x.viewOrder").toArray();
				}
				var cfieldsAux = $linq(TramNewFactory.Procedure.customFields).where(function(x){
					if(x && TramNewFactory.initialTransaction && TramNewFactory.initialTransaction.transactionType && TramNewFactory.initialTransaction.transactionType.id &&
						 ((x.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE' || x.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE') && x.transactionTypes && $linq(x.transactionTypes).contains(TramNewFactory.initialTransaction.transactionType, "(x, y) => x.transactionType && x.transactionType.id == y.id"))){
						return true;
					
					}else{
						return false;
					}
				}).toArray();
				if(cfieldsAux && cfieldsAux.length > 0){
					_.forEach(cfieldsAux, function(cf){
						cf.ttypeAdded = TramNewFactory.initialTransaction.transactionType.id;
					});
					if(cfields && cfields.length > 0){
						cfields = $linq(cfields).union(cfieldsAux, "(x,y) => x.customField && y.customField && x.customField.id == y.customField.id").orderBy("x => x.viewOrder").toArray();
					}else{
						cfields = $linq(cfieldsAux).orderBy("x => x.viewOrder").toArray();
					}
				}
				
			}
			if(TramNewFactory.initialTransactioTypeCustomFields && TramNewFactory.initialTransaction && TramNewFactory.initialTransaction.transactionType && TramNewFactory.initialTransaction.transactionType.id 
					&& TramNewFactory.initialTransactioTypeCustomFields[TramNewFactory.initialTransaction.transactionType.id]){
				_.forEach(TramNewFactory.initialTransactioTypeCustomFields[TramNewFactory.initialTransaction.transactionType.id], function(cf){
					cf.ttypeAdded = TramNewFactory.initialTransaction.transactionType.id;
					cf.originType = 'TransactionType';
				});
				if(cfields && cfields.length > 0){
					cfields = $linq(cfields).union(TramNewFactory.initialTransactioTypeCustomFields[TramNewFactory.initialTransaction.transactionType.id], "(x,y) => x.customField && y.customField && x.customField.id == y.customField.id").orderBy("x => x.viewOrder").toArray();
				}else{
					cfields = $linq(TramNewFactory.initialTransactioTypeCustomFields[TramNewFactory.initialTransaction.transactionType.id]).orderBy("x => x.viewOrder").toArray();
				}
			}
			if(cfields && cfields.length > 0){
				cfields = angular.copy(cfields);
				var descriptionLanguageColumn = "descriptionL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
				var nameLanguageColumn = "nameL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
				angular.forEach(cfields,function (value, key) {
                   if(value && !value.hiddenField) {
                       form = CustomFieldFactory.addFormlyFieldRow(form, value.customField, value.required, value.customField.id, value.noEditable, TramNewFactory.Procedure, "PROCEDURE", true, value, cfields);
					   if(form && form.fields){
						   var formCFActual = $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_"+value.customField.id+"'");
						   if(!formCFActual){
							   formCFActual = $linq(form.fields).firstOrDefault(undefined,"x => x.key == '"+value.customField.templateTag+"'");
						   }
						   if(formCFActual){
							   if(value[nameLanguageColumn]) {
								   formCFActual.templateOptions.label = value[nameLanguageColumn];
							   }
							   if(value[descriptionLanguageColumn]){
								   formCFActual.templateOptions.label = formCFActual.templateOptions.label + ' ('+ value[descriptionLanguageColumn] + ')';
		                       }
							   formCFActual.templateOptions.ttypeAdded = value.ttypeAdded;
							   if(TramNewFactory.Procedure && TramNewFactory.Procedure.initCfieldWhenSaved){
								   formCFActual.hideExpression = function($viewValue, $modelValue, scope) {
									   return true;
						   }
							   }
						   }
						   if(value.customField && value.customField.frontendType === "CF_GROUP"){
							   var formCFActualAux = $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_"+value.customField.id+"_Label'");
							   if(!formCFActualAux){
								   formCFActualAux = $linq(form.fields).firstOrDefault(undefined,"x => x.key == '"+value.customField.templateTag+"_Label'");
							   }
							   if(formCFActualAux){
								   formCFActualAux.templateOptions.ttypeAdded = value.ttypeAdded;
							   }   
							   if(TramNewFactory.Procedure && TramNewFactory.Procedure.initCfieldWhenSaved){
								   formCFActualAux.hideExpression = function($viewValue, $modelValue, scope) {
									   return true;
						   		   }
					   		   }
						  }
					   }
					   var cfFromTask;
					   if (TramNewFactory.Task && TramNewFactory.Task.customFields && TramNewFactory.Task.customFields.length > 0) {
						   cfFromTask = $linq(TramNewFactory.Task.customFields).firstOrDefault(undefined,"x => x.customField && x.customField.id == " + value.customField.id);
					   }
					   if (cfFromTask) {
                    	   if(cfFromTask.customField.frontendType == 'CHECKBOX'){
                               if(cfFromTask.customField.listValues){
                                   _.forEach(cfFromTask.customField.listValues, function(option){
                                       if(form.model != undefined) {
                                           if (_.contains(cfFromTask.valueFromJSON, option['value'])) {
                                        	   form.model[('cf_' + value.customField.id) + '_' + option.value] = true;
                                           } else {
                                        	   form.model[('cf_' + value.customField.id) + '_' + option.value] = false;
                                           }
                                       }
                                   });
                               }
                    	   } else if(value.customField.backendType == 'ADDRESS') {
	                    	   angular.forEach(form.fields,function (field, key) {
	                    		   if(field.key == 'cfValue_' + value.customField.id) {
	                    			   field.templateOptions.disabled = true;
	                    			   if(cfFromTask.valueFromJSON) {
	                    				   form.model['cfValue_' + value.customField.id] = factory.postalAddressRender(cfFromTask.valueFromJSON);
	                    				   form.model['cf_' + value.customField.id] = cfFromTask.valueFromJSON;
	                    			   }
	                    		   }
	                    		   if(field.key == 'addressBtn_' + value.customField.id) {
	                    			   field.templateOptions.executeFunction = openModalAddress;
	                    		   }
	                    	   });
                           } else {
                        	   form.model['cf_' + value.customField.id] = cfFromTask.valueFromJSON;
                           }
					   } else {
	                       if(value.customField.backendType == 'ADDRESS') {
	                    	   angular.forEach(form.fields,function (field, key) {
	                    		   if(field.key == 'cfValue_' + value.customField.id) {
	                    			   field.templateOptions.disabled = true;
	                    			   if(value.value) {
	                    				   form.model['cfValue_' + value.customField.id] = factory.postalAddressRender(JSON.parse(value.value));
	                    				   form.model['cf_' + value.customField.id] = JSON.parse(value.value);
	                    			   }
	                    		   }
	                    		   if(field.key == 'addressBtn_' + value.customField.id) {
	                    			   field.templateOptions.executeFunction = openModalAddress;
	                    		   }
	                    	   });
	                       } else {
	                           form.model['cf_' + value.customField.id] = CustomFieldFactory.calculateValueCustomField(value, form.model, ('cf_' +  value.customField.id));
	                       }
					   }
                   }
                });
			}
			form.customFieldsAdded = cfields;	
            //modificacion el valor de archiveClassification para que no nos solape el form.model
            form.model.archiveClassification={};
			form.model.councillor={};
            if(fields.allowUserChangeMaxDays) {
                form.model.maxDays = TramNewFactory.Procedure.maxDays;
            }
            if(fields.allowUserChangeMaxDaysExpireType) {
            	form.model.expireType = TramNewFactory.Procedure.expireType;
            }
            if (fields.numSeqManual) {
            	form.model.numSeqManual = TramNewFactory.nextNumSeqDossier;
            }
            if (fields.yearManualNumbering) {
            	form.model.yearManualNumbering = new Date().getFullYear();
            }
            if(TramNewFactory.Register) {
                if((TramNewFactory.Procedure.showSubject == 'OPTIONAL' || TramNewFactory.Procedure.showSubject == 'REQUIRED') && TramNewFactory.Register.extract) {
                    form.model.subject = TramNewFactory.Register.extract;
                }

                if(TramNewFactory.Register.observations) {
                    form.model.observations = TramNewFactory.Register.observations;
                }
                
                if(TramNewFactory.Register.tramClassification && TramNewFactory.Register.tramClassification.id){
                	form.model.tramClassification = TramNewFactory.Register.tramClassification;
                }
            }
            if(TramNewFactory.Task) {
                if((TramNewFactory.Procedure.showSubject == 'OPTIONAL' || TramNewFactory.Procedure.showSubject == 'REQUIRED') && TramNewFactory.Task.description) {
                	form.model.subject = TramNewFactory.Task.description;
                }
            }
            if(TramNewFactory.Procedure && TramNewFactory.Procedure.archiveClassification){
                form.model.archiveClassification.$selected = {id:TramNewFactory.Procedure.archiveClassification.id, title: TramNewFactory.Procedure.archiveClassification[Language.getActiveColumn()]};
            }
            if(TramNewFactory.Procedure && TramNewFactory.Procedure.councillor){
                form.model.councillor.$selected = {id:TramNewFactory.Procedure.councillor.id, title: TramNewFactory.Procedure.councillor[Language.getActiveColumn()]};
            }
            if(fields.tramitationType) {
                form.model.tramitationType = TramNewFactory.Procedure.tramitationType;
            }

            var submitNewTram = function() {
                form.form.$setSubmitted();
				
                if(form.form.$valid) {
                	var calculateCFTGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(ng){
                    			_.forEach(ng.groups, function(g){
                    				g.groups = calculateCFTGroups(g.newGroups); 
                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                    				groups.push(g);
                    			})
                    		})
                    	}
                    	return groups;
                    }
                	
                	var calculateCFTGroupsWithoutNewGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(g){
                   				g.groups = calculateCFTGroupsWithoutNewGroups(g.groups); 
                   				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                   				groups.push(g);
                    		})
                    	}
                    	return groups;
                    }
                	
                    var boxesModel = getTramBoxesModel(form.extra);
					var cfOk = true;
					var thirdOk = true;
					if(boxesModel && boxesModel.customFields){
						var errorCF = $linq(boxesModel.customFields).where("x => x.customField && x.customField.frontendType != 'CF_GROUP' && x.required && !x.value").toArray();
						if((!TramNewFactory.Procedure || !TramNewFactory.Procedure.initCfieldWhenSaved) && errorCF && errorCF.length > 0){
							cfOk = false;
						}
						if(cfOk){
							var validateGroup = function(ng, g){
								var valid = true;
								var gs = [];
								if(ng.groups){
									_.forEach(ng.groups, function(ngg){
										if(ngg.relatedCustomField && ngg.relatedCustomField.frontendType !== 'CF_GROUP' && ngg.required && !ngg.value){
											valid = false;
										}else if(ngg.relatedCustomField && ngg.relatedCustomField.frontendType === 'CF_GROUP'){
											ngg.groups = [];
											if(ngg.newGroups && valid){
												_.forEach(ngg.newGroups, function(ngAux){
													if(valid && !validateGroup(ngAux, ngg)){
														valid = false;
													}
												});
											}
										}
										if(valid){
											gs.push(ngg);
										}
									});
								}
								if(valid){
									_.forEach(gs, function(item){
										g.groups.push(item);
									});
								}
								return valid;
							}
							var groups = $linq(boxesModel.customFields).where("x => x.customField && x.customField.frontendType == 'CF_GROUP'").toArray();
							if((!TramNewFactory.Procedure || !TramNewFactory.Procedure.initCfieldWhenSaved) && groups){
								_.forEach(groups, function(g){
									g.groups = [];
									if(g.newGroups && cfOk){
										_.forEach(g.newGroups, function(ng){
											if(cfOk && !validateGroup(ng, g)){
												cfOk = false;
											}
										});
									}
								});
							}
						}
					}
					if(cfOk && (!TramNewFactory.Procedure || !TramNewFactory.Procedure.initCfieldWhenSaved) && form.customFieldsAdded){
						var custom_fields = $linq(form.customFieldsAdded).where("x => x.customField && x.customField.frontendType == 'CF_GROUP'").toArray();
	                    if(custom_fields && custom_fields.length > 0){
	                        _.forEach(custom_fields, function(field) {
	                            var fieldModif = $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_" + field.customField.id+"'");
	                            if(fieldModif && fieldModif.data && fieldModif.data.customField){
	                            	fieldModif.data.customField.groups = [];
									if(fieldModif.data.customField.newGroups && cfOk){
										_.forEach(fieldModif.data.customField.newGroups, function(ng){
											if(cfOk && !validateGroup(ng, fieldModif.data.customField)){
												cfOk = false;
											}
										});
									}
	                            }
	                        })
	                    }
					}
					if(boxesModel && boxesModel.receivers){
						if(TramNewFactory.Procedure && TramNewFactory.Procedure.forceHaveThird && boxesModel.receivers.length < 1){
							thirdOk = false;
						}
					}
					if(cfOk && thirdOk){
	                    var archiveClassification ;
	                    if(form.model.archiveClassification && form.model.archiveClassification.$selected && form.model.archiveClassification.$selected.id){
	                        archiveClassification = {id:form.model.archiveClassification.$selected.id};
	                    }
						var councillor ;
	                    if(form.model.councillor && form.model.councillor.$selected && form.model.councillor.$selected.id){
	                        councillor = {id:form.model.councillor.$selected.id};
	                    }
                    	if(TramNewFactory.Procedure.guided || $rootScope.esetMode){
                        	form.model.tramitationType = TramNewFactory.Procedure.tramitationType;
	                    }
	                    
	                    if ($rootScope.esetMode && fields.profilesTransaction) {
	                    	form.model.profiles = [];
	                    	form.model.profiles.push(fields.profilesTransaction.value());
	                    }
	                    
                    	var newDossier = new dossier(TramNewFactory.Procedure, form.model.subject, form.model.observations, form.model.classification, archiveClassification, form.model.tramitationType, form.model.profiles, councillor, form.model.externalCode);
	                    if(fields.profiles){
	                        newDossier.profiles = [];
	                        if(form.model && form.model.profiles) {
	                            _.forEach(form.model.profiles, function (profile) {
	                                newDossier.profiles.push({"id": profile});
	                            });
	                        }
	                    } else{
	                        newDossier.profiles = [];
	                        if(form.model && form.model.profiles) {
	                            _.forEach(form.model.profiles, function (profile) {
	                                newDossier.profiles.push({"id": profile});
	                            });
	                        }
	                    }
	                    
	                    if(fields.documentTypeCanSee){
	                        newDossier.dossierDocumentTypeCanSee = [];
	                        if(form.model && form.model.documentTypeCanSee) {
	                            _.forEach(form.model.documentTypeCanSee, function (docType) {
	                                newDossier.dossierDocumentTypeCanSee.push({"documentType":{"id": docType}});
	                            });
	                        }
	                    } else{
	                        newDossier.dossierDocumentTypeCanSee = [];
	                        if(form.model && form.model.documentTypeCanSee) {
	                            _.forEach(form.model.documentTypeCanSee, function (docType) {
	                                newDossier.dossierDocumentTypeCanSee.push({"documentType":{"id": docType}});
	                            });
	                        }
	                    }
	                    
	                    if(form.model.section) {
	                        if(form.model.section.id) {
	                            newDossier.section = {id: form.model.section.id};
	                        } else {
	                            newDossier.section= {id: form.model.section};
	                        }
	                    }
	                    if(form.model.tramClassification) {
	                        if(form.model.tramClassification.id) {
	                            newDossier.tramClassification = {id: form.model.tramClassification.id};
	                        } else {
	                            newDossier.tramClassification = {id: form.model.tramClassification};
	                        }
	                    }
	                    // MRS-F
	
	                    if(form.model.numSeqManual && form.model.yearManualNumbering) {
	                        newDossier.numSeqManual = form.model.numSeqManual;
	                        newDossier.yearManualNumbering = form.model.yearManualNumbering;
	                    }
	                    if(fields.allowUserChangeMaxDays) {
	                        newDossier.maxDays = form.model.maxDays;
	                    }
	                    if(fields.allowUserChangeMaxDaysExpireType) {
	                    	newDossier.expireType = form.model.expireType;
	                    }
	                    var cf = CustomFieldFactory.getModelValues(form.model);
						if(form.customFieldsAdded){
		                    var custom_fields = $linq(form.customFieldsAdded).where("x => x.customField && x.customField.frontendType == 'CHECKBOX'").toArray();
		                    custom_fields = angular.copy(custom_fields);
		                    if(custom_fields && custom_fields.length > 0){
		                        _.forEach(custom_fields, function(field) {
		                            var index_custom_field =  $linq(cf).indexOf("x => x.id == 'cf_" + field.customField.id+"'");
		                            var custom_field = undefined;
		                            if(index_custom_field == -1){
		                                custom_field = {id:"cf_" + field.customField.id, value:[]};
		                                cf.push(custom_field);
		                            }else{
		                                custom_field = cf[index_custom_field];
		                                if(custom_field){
		                                    custom_field.value = [];
		                                }
		                            }
		                            var custom_field_selected = $linq(cf).where(function(x){
		                                if(x.id.startsWith("cf_" + field.customField.id+"_")){return true;}else{return false;}}
		                            ).toArray();
		                            if(custom_field_selected && custom_field_selected.length > 0){
		                                custom_field.value = [];
		                                _.forEach(custom_field_selected, function(value){
		                                    if(value.value) {
		                                        if(field.customField && field.customField.listValues && field.customField.listValues.length > 0) {
		                                            var custom_field_value_selected = $linq(field.customField.listValues).where(function(x){
		                                                if(value.id.endsWith("_"+x.value)){return true;}else{return false;}}
		                                            ).toArray();
		                                            if(custom_field_value_selected && custom_field_value_selected.length > 0){
		                                                custom_field.value.push(custom_field_value_selected[0].value);
		                                            }
		                                        }
		                                    }
		                                });
		                            }
		                        });
		                    }
		                    var custom_fields = $linq(form.customFieldsAdded).where("x => x.customField && x.customField.frontendType == 'STRUCTURAL_SELECT'").toArray();
		                    custom_fields = angular.copy(custom_fields);
		                    if(custom_fields && custom_fields.length > 0){
		                        _.forEach(custom_fields, function(field) {
		                            var index_custom_field =  $linq(cf).indexOf("x => x.id == 'cf_" + field.customField.id+"'");
		                            var custom_field = undefined;
		                            if(index_custom_field == -1){
		                                custom_field = {id:"cf_" + field.customField.id, value:''};
		                                cf.push(custom_field);
		                            }else{
		                                custom_field = cf[index_custom_field];
		                                if(custom_field){
		                                    custom_field.value = '';
		                                }
		                            }
		                        });
		                    }
		                    custom_fields = $linq(form.customFieldsAdded).where("x => x.customField && x.customField.frontendType == 'CF_GROUP'").toArray();
		                    custom_fields = angular.copy(custom_fields);
		                    if(custom_fields && custom_fields.length > 0){
		                        _.forEach(custom_fields, function(field) {
		                            var fieldModif = $linq(form.fields).firstOrDefault(undefined,"x => x.key == 'cf_" + field.customField.id+"'");
		                            if(fieldModif && fieldModif.data && fieldModif.data.customField){
		                            	var cfModif = $linq(cf).firstOrDefault(undefined,"x => x.id == 'cf_" + field.customField.id+"'");
			                            if(cfModif){
			                            	var grs = angular.copy(fieldModif.data.customField.newGroups);
			                            	grs = calculateCFTGroups(grs);
			                            	if(TramNewFactory.Procedure && TramNewFactory.Procedure.initCfieldWhenSaved  && fieldModif.data.customField && fieldModif.data.customField.groups && fieldModif.data.customField.groups.length > 0){
			                            		grs = angular.copy(fieldModif.data.customField.groups);
			                            		grs = calculateCFTGroupsWithoutNewGroups(grs);
			                            	}
			                            	cfModif.groups = {procedures:((field.originType !== 'TransactionType' && field.originType !== 'Transaction')?grs:[]), transactionTypes:((field.originType === 'TransactionType')?grs:[]), transaction:((field.originType === 'Transaction')?grs:[])}
			                            }else{
			                            	var grs = angular.copy(field.groups);
			                            	grs = calculateCFTGroupsWithoutNewGroups(grs);
			                            	cf.push({id:'cf_'+field.customField.id, groups: {procedures:((field.originType !== 'TransactionType' && field.originType !== 'Transaction')?grs:[]), transactionTypes:((field.originType === 'TransactionType')?grs:[]), transaction:((field.originType === 'Transaction')?grs:[])}});
			                            }
		                            }
		                        })
		                    }
						}
	                    var dossierForm = {};
	                    dossierForm.newDossier = newDossier;
	                    dossierForm.cf =  cf;
	                    dossierForm.receivers =  boxesModel.receivers;
	                    dossierForm.registerInputs =  boxesModel.registerInputs;
	                    dossierForm.registerOutputs =  boxesModel.registerOutputs;
	                    dossierForm.dossiers =  boxesModel.dossiers;
	                    dossierForm.documents =  boxesModel.documents;
	                    dossierForm.addresses =  boxesModel.addresses;
						dossierForm.cfDossierValues = [];
						if(boxesModel.customFields && boxesModel.customFields.length > 0){
							_.forEach(boxesModel.customFields, function(value){
								if(value.customField && value.customField.frontendType === 'CF_GROUP'){
									var grs = angular.copy(value.newGroups);
									dossierForm.cfDossierValues.push({id:'cf_'+((value.customField && value.customField.id)?value.customField.id:''), groups:{procedures:calculateCFTGroups(grs)}});
								}else{
									dossierForm.cfDossierValues.push({id:'cf_'+((value.customField && value.customField.id)?value.customField.id:''), value:CustomFieldFactory.calculateValueCustomFieldDossier(value)});
								}
							});
						}
	                    if(form.model.transactionType){
	                        dossierForm.transactionType = form.model.transactionType;
	                    }
	                    if(form.model.responsibleTransaction){
	                        dossierForm.responsibleTransactionUser = form.model.responsibleTransaction;
	                    }
	                    newTram(dossierForm, saveOkState);
					}else if(!cfOk){
						DialogsFactory.error($filter('translate')('global.literals.customFieldOblig'));
					}else if(!thirdOk){
						DialogsFactory.error($filter('translate')('global.literals.forceHaveThirds'));
					}
                }
            };

            form.addSubmitCallback(submitNewTram);

            return form;
        };

        var tramBoxes = function (scope) {
            var retBoxes = [];
            var receiverBox = getBox('receiver', 'receiver-tram', 'global.literals.receivers', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers', true, true, true, true);
            receiverBox.boxDefinition.setPrincipal = function (objectId, index) {
                var actualPrincipal = -1;
                if (receiverBox.boxDefinition && receiverBox.boxDefinition.content && receiverBox.boxDefinition.content.length > index) {
                    angular.forEach(receiverBox.boxDefinition.content, function (val, key) {
                        if (val.principal) {
                            receiverBox.boxDefinition.content[key].principal = false;
                        }
                    });
                    receiverBox.boxDefinition.content[index].principal = true;
                }
            };
            receiverBox.boxDefinition.editFunction = function (objectId, index) {
                openEditReciverModal(index, receiverBox.boxDefinition.content);
            }
            receiverBox.boxDefinition.deleteFunction = function (objectId, index) {
                receiverBox.boxDefinition.content.splice(index, 1);
                if (receiverBox.boxDefinition.content && receiverBox.boxDefinition.content.length > 0) {
                    receiverBox.boxDefinition.content[0].principal = true;
                }
            };
            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(receiverBox);
            }


            var documentBox = getBox('documentChild', 'document-tram', 'global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', true, true, true, true);
            documentBox.boxDefinition.fromTemplate = function () {
                factory.showNewDocumentFromTemplateModal(TramNewFactory.initialTransaction, new SortedArray(TramFactory.TramTemplates, Language.getActiveColumn()).sort(), GlobalDataFactory.documentTypes, documentBox.boxDefinition);
            };
            documentBox.boxDefinition.actualTransaction = TramNewFactory.initialTransaction;
			documentBox.boxDefinition.showAssociateThirdDocuments = true;
			documentBox.boxDefinition.associateThirdDocuments = function(){
				DccumentsFactory.associateThirdDocuments(documentBox.boxDefinition.content, receiverBox.boxDefinition.content, 'tram');
            }
			documentBox.boxDefinition.thirds = receiverBox.boxDefinition.content;
            var updateDocumentInList = function (document) {
                if (document && document.id && documentBox && documentBox.boxDefinition && documentBox.boxDefinition.content) {
                    var index = $linq(documentBox.boxDefinition.content).indexOf("x => x.document.id == " + document.id);

                    if (index != -1) {
                        documentBox.boxDefinition.content[index].document.urgent = document.urgent;
                        documentBox.boxDefinition.content[index].document.docStatus = document.docStatus;
                        documentBox.boxDefinition.content[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
                        documentBox.boxDefinition.content[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
                        documentBox.boxDefinition.content[index].document.sizePDFDoc = document.sizePDFDoc;
                    }
                }
            };
            if (scope) {
                scope.$on('SendToSignModalFinished', function (event, args) {
                    if (args.data && args.data.length > 0) {
                        updateDocumentInList(args.data[0]);
                    }
                });
                scope.$on('documentActionButtonExecuted', function(event, args) {
                    if(args.button) {
                        switch (args.button) {
                            case 'returnToDraft':
                            case 'complete':
                                updateDocumentInList(args.data);
                                break;
                            case 'createRegisterEntry':
                                if(args.data){
                                    if(args.data.entryType == 'INPUT' && registerInputBox && registerInputBox.boxDefinition){
                                        registerInputBox.boxDefinition.searchedValue = {id: args.data.id, object: args.data};
                                        registerInputBox.boxDefinition.searchAdd();
                                    }else if(args.data.entryType == 'OUTPUT' && registerOutputBox && registerOutputBox.boxDefinition){
                                        registerOutputBox.boxDefinition.searchedValue = {id: args.data.id, object: args.data};
                                        registerOutputBox.boxDefinition.searchAdd();
                                    }
                                }
                                break;

                        }
                    }
                });
 				scope.$on('associateThirdDocumentsTramFinish', function (event, args) {
                    if (args.docs && args.docs.length > 0 && args.origin === 'tram') {
						_.forEach(args.docs, function(value){
	                        documentBox.boxDefinition.content.push({
	                            document:value,
	                            relationType: 'DOCUMENT',
	                            publicDocument: false
                        	});
	                    });
						$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                        $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: documentBox.boxDefinition.content, relationType: 'DOCUMENT' });
                        if(args.modal){
							args.modal.close()
						}
                    }
                });
            }
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBox);
            }

			var addRegisterEntrySave = function(registerEntry) {
				if(!Array.isArray(registerEntry)){
					registerEntry = [registerEntry];
				}
				DialogsFactory.confirm('global.tram.literals.addDocumentsThirdsMessage', 'global.tram.literals.addDocumentsThirdsMessageConfirm')
	            .then(function (data) {
					$http({
	                    url: './api/reg/register_entry_diligence/thirds_documents_from_register_entry/' + $linq(registerEntry).select("x => x.id").toArray(),
	                    method: 'GET'
	                }).then(function (dataAux) {
						if(dataAux && dataAux.data){
							dataAux = JSOG.decode(dataAux.data);
							var receivers = [];
							if(dataAux.thirds && dataAux.thirds.length > 0){
								_.forEach(dataAux.thirds, function(item) {
		                            receivers.push({
		                                id: item.third.id,
		                                allName: '<span style="display:inline">' + ThirdFactory.getThirdCompleteName(item.third) + ' <span class="label label-xxs">' + ThirdFactory.getRelatedThirdType(item) + '</span></span>'
		                            });
		                        });
							}
							var documentToAttach = [];
							if(dataAux.documents && dataAux.documents.length > 0){
							 	documentToAttach = $linq(dataAux.documents).select("x => x.document").toArray();
							}
		                    var attachDossierModal = {
		                        title: 'global.reg.literals.attachExp',
		                        size: '',
		                        icon: 'fa fa-paperclip',
		                        fieldsDefinition: [
		                            { type: 'field', id: 'receivers', fieldType: 'select_multiple_html', colClass: 'daughter-label-strong', labelProp: 'allName', data: receivers, label: 'global.literals.remitents' },
		                            { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
		                                data: {
		                                    title: 'global.literals.documents',
		                                    documents: documentToAttach,
											documentsIdSelected: (($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default && $rootScope.app.configuration.diligence_attach_to_dossier_documents_all_default.value && documentToAttach && documentToAttach.length > 0)? $linq(documentToAttach).select("x => x.id").toArray() : undefined)	
		                                }
		                            }
		                        ],
		                        alerts: [],
		                        submitModal: function () {
		                        }
		                    }
		
		                    var submitAttachDossier = function() {
		                        var self = this;
		                        this.form.$setSubmitted();
		                        var selectedDocumentsAux = angular.copy($linq(this.fieldsDefinition[1].data.documents).where("x => x.selected").toArray());
		                        var selectedDocuments = [];
		                        if(selectedDocumentsAux && selectedDocumentsAux.length > 0){
		                        	_.forEach(selectedDocumentsAux, function(doc){
										var docAux = $linq(dataAux.documents).firstOrDefault(undefined, "x => x.document.id == "+doc.id);
		                        		if(docAux){
											selectedDocuments.push(docAux.id);
										}
		                        	});
		                        }
								var selectedReceivers = [];
		                        if(this.model.receivers && this.model.receivers.length > 0){
		                        	_.forEach(this.model.receivers, function(third){
										var thirdAux = $linq(dataAux.thirds).firstOrDefault(undefined, "x => x.third.id == "+third);
		                        		if(thirdAux){
											selectedReceivers.push(thirdAux.id);
										}
		                        	});
		                        }
								var addedRegisters = [];
								if(registerEntry && registerEntry.length){
									_.forEach(registerEntry, function(reg){
										addedRegisters.push(reg.id);
		                        	});
								}
								if (this.form.$valid) {
									$http({
						                url: './api/tram/attach_register_to_dossier/'+ CommonService.getParameterList(addedRegisters)+'/'+ CommonService.getParameterList(selectedReceivers)+'/'+CommonService.getParameterList(selectedDocuments),
						                method: 'GET'
						            }).then(function(data) {
										try{
											var addedDocuments = [];
											var addedReceivers = [];
											if(data && data.data){
												data = JSOG.decode(data.data);
												addedDocuments = data.documents;
												addedReceivers = data.thirds;
											}
											if(registerEntry && registerEntry.length > 0){
												_.forEach(registerEntry, function(reg){
													if(reg && reg.entryType && reg.entryType == 'INPUT'){
														var existReg = $linq(registerEntryInputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
														if(!existReg){
															registerEntryInputBox.content.push(reg);
														}
													}else if(reg && reg.entryType && reg.entryType == 'OUTPUT'){
														var existReg = $linq(registerEntryOutputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
														if(!existReg){
															registerEntryOutputBox.content.push(reg);
														}
													}
												});
											}
											if(addedReceivers && addedReceivers.length > 0 && receiverBox && receiverBox.boxDefinition && receiverBox.boxDefinition.content){
												_.forEach(addedReceivers, function(rec){
													if(rec.third && rec.third.id){
														var existRec = $linq(receiverBox.boxDefinition.content).firstOrDefault(undefined, "x => x.third && x.third.id == "+rec.third.id);
														if(!existRec){
															receiverBox.boxDefinition.content.push(rec);
														}
													}
												});
											}
											if(addedDocuments && addedDocuments.length > 0 && documentBox && documentBox.boxDefinition && documentBox.boxDefinition.content){
												_.forEach(addedDocuments, function(doc){
													if(doc.document && doc.document.id){
														var existDoc = $linq(documentBox.boxDefinition.content).firstOrDefault(undefined, "x => x.document && x.document.id == "+doc.document.id);
														if(!existDoc){
															documentBox.boxDefinition.content.push(doc);
														}
													}
												});
												$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
			                                    $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: documentBox.boxDefinition.content, relationType: 'DOCUMENT', transactionId:undefined});
											}
						                	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
						                			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
						                		registerEntryInputBox.content = $linq(registerEntryInputBox.content).orderBy("x => x.registerDate").toArray();
						                    	registerEntryOutputBox.content = $linq(registerEntryOutputBox.content).orderBy("x => x.registerDate").toArray();
						                	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
						    	        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
						                		registerEntryInputBox.content = $linq(registerEntryInputBox.content).orderByDescending("x => x.registerDate").toArray();
						                    	registerEntryOutputBox.content = $linq(registerEntryOutputBox.content).orderByDescending("x => x.registerDate").toArray();
						                	}
											attachDossierModal.close();
										}catch(e){
											$state.reload();
										}
						            }).catch(function(error) {
						                attachDossierModal.alerts.push({ msg: 'global.errors.unknownAttachtoExp' });
						            });
		                        }
			                }
			                AnnexaFormlyFactory.showAnnexaFormModal('modalAttachDossier', attachDossierModal, submitAttachDossier);
						}
					}).catch(function (error) {
						console.log(error);
					});
	            }).catch(function (data) {
                	if(registerEntry && registerEntry.length > 0){
						_.forEach(registerEntry, function(reg){
							if(reg && reg.entryType && reg.entryType == 'INPUT'){
								var existReg = $linq(registerEntryInputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
								if(!existReg){
									registerEntryInputBox.content.push(reg);
								}
							}else if(reg && reg.entryType && reg.entryType == 'OUTPUT'){
								var existReg = $linq(registerEntryOutputBox.content).firstOrDefault(undefined, "x => x.id == "+reg.id);
								if(!existReg){
									registerEntryOutputBox.content.push(reg);
								}
							}
						});
					}
                	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
                			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'asc') {
                		registerEntryInputBox.content = $linq(registerEntryInputBox.content).orderBy("x => x.registerDate").toArray();
                    	registerEntryOutputBox.content = $linq(registerEntryOutputBox.content).orderBy("x => x.registerDate").toArray();
                	} else if ($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.order_box_register_dossier && 
    	        			$rootScope.app.configuration.order_box_register_dossier.value && $rootScope.app.configuration.order_box_register_dossier.value == 'desc') {
                		registerEntryInputBox.content = $linq(registerEntryInputBox.content).orderByDescending("x => x.registerDate").toArray();
                    	registerEntryOutputBox.content = $linq(registerEntryOutputBox.content).orderByDescending("x => x.registerDate").toArray();
                	}
	            });
            };

			var completeAddRegisterEntryInputSave = function() {
        		completeAddRegisterEntrySave(this, registerEntryInputBox.content);
            };
			var completeAddRegisterEntryOutputSave = function() {
                completeAddRegisterEntrySave(this, registerEntryOutputBox.content);
                
            };
			var completeAddRegisterEntrySave = function(selfReg, content) {
				var newContent = [];
            	if(selfReg.searchedValue && selfReg.searchedValue.object && Array.isArray(selfReg.searchedValue.object)){
            		_.forEach(selfReg.searchedValue.object, function(obj){
            			newContent.push(JSOG.decode(obj));
            		});
            	}else{
            		newContent.push(JSOG.decode(selfReg.searchedValue.object));
            	}

        		selfReg.searchedValue = { id: -1, value: '' };
                selfReg.saveSearch(newContent);
            };       
			var registerEntryInputBox = {
                boxTitle: 'global.literals.reg_input',
                permissions: { new: 'new_input_entry_register', view: ['view_input_register','view_all_input_register'] },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: addRegisterEntrySave, completeAdd: completeAddRegisterEntryInputSave},
                new: {},
                content:  [],
                config: { thirds: receiverBox.boxDefinition.content, documents: documentBox.boxDefinition.content },
                origin: 'register-entry',
                searchByEntryType: true,
                entryType: 'INPUT'
            };

            retBoxes.push(registerEntryInputBox);

            var registerEntryOutputBox = {
                boxTitle: 'global.literals.reg_output',
                permissions: { new: 'new_output_entry_register', view: 'view_output_register' },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: addRegisterEntrySave, completeAdd: completeAddRegisterEntryOutputSave},
                new: {},
                content:  [],
                config: { thirds: receiverBox.boxDefinition.content, documents: documentBox.boxDefinition.content },
                origin: 'register-entry',
                searchByEntryType: true,
                entryType: 'OUTPUT'
            };

            retBoxes.push(registerEntryOutputBox);

            var dossierBox = getBox('dossier-related', 'dossier-tram', 'global.literals.related_dossiers', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers', false, true, true);
            retBoxes.push(dossierBox);
            
            var checkRegisterAddresses = false;
            var checkTaskAddresses = false;
            if($state.params && $state.params.register && $state.params.register.addresses && $state.params.register.addresses.length > 0){
            	checkRegisterAddresses = true;
            }
            if($state.params && $state.params.task && $state.params.task.addresses && $state.params.task.addresses.length > 0){
            	checkTaskAddresses = true;
            }
            
            if(TramNewFactory.Procedure && (TramNewFactory.Procedure.allowHaveTerritorialAddress || checkRegisterAddresses || checkTaskAddresses)){
	            if(TramNewFactory.Procedure && TramNewFactory.Procedure.allowHaveTerritorialAddress){
	            	var addressBox = {
	    	                boxTitle: 'global.territory.list.toptitle',
	    	                permissions: { new: ['start_dossier'], view:['start_dossier']},
	    	                search: { placeholder: 'global.literals.placeholderSearchAddressBox', advanced: true, saveSearch: undefined },
	    	                new: {},
	    	                content:  [],
	    	                config: {},
	    	                origin: 'dossier'
	    	            };
	            }else{
	            	var addressBox = {
	    	                boxTitle: 'global.territory.list.toptitle',
	    	                permissions: { new: ['start_dossier'], view:['start_dossier']},
	    	                content:  [],
	    	                config: {},
	    	                origin: 'dossier'
	    	            };
	            }
            	
	            if($state.params && $state.params.register && $state.params.register.addresses){
		            _.forEach($state.params.register.addresses, function(address){
		            	if(address && address.address && address.address.id){
			            	var addressAux = {
	                    		address:angular.copy(address.address)
	                        };
	                        addressBox.content.push(addressAux)
		            	}
		            });
				}
	            
	            if($state.params && $state.params.task && $state.params.task.addresses){
		            _.forEach($state.params.task.addresses, function(address){
		            	if(address && address.address && address.address.id){
			            	var addressAux = {
	                    		address:angular.copy(address.address)
	                        };
	                        addressBox.content.push(addressAux)
		            	}
		            });
				}

	            if(!TerritoryFactory.canCreateAddress()) {
	            	addressBox.new = undefined;
	            }
            	retBoxes.push(addressBox);
            }

			var dossierCFs = [];
			if(TramNewFactory.Procedure && TramNewFactory.Procedure.customFields){
				dossierCFs = $linq(TramNewFactory.Procedure.customFields).where(function(x){
					if(x && !x.noEditable && !x.hiddenField && (x.customFieldType == 'DOSSIER' || x.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' || x.customFieldType == 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' || x.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' || x.customFieldType == 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY')){
						return true;
					}else{
						return false;
					}
				}).toArray();
			}
			
            var getCustomFieldTaskValue = function(field){
                var valueLiteral = '';
                if(field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX'){
                    angular.forEach(field.valueFromJSON,function (valueJ, keyJ) {
                        angular.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == valueJ) {
                                if(valueLiteral) {
                                    valueLiteral = valueLiteral +','+ value.id;
                                }else{
                                    valueLiteral = value.id;
                                }
                            }
                        });
                    });
                } else if(field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO') {
                    angular.forEach(field.customField.listValues, function (value, key) {
                        if (value.value == field.valueFromJSON) {
                            valueLiteral = value.id;
                        }
                    });
                } else if(field.customField.frontendType == 'INPUT') {
                    if(field.customField.backendType == "DATETIME") {
                        valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                    } else {
                        valueLiteral = field.valueFromJSON;
                    }
                } else if(field.customField.frontendType == 'STRUCTURAL_SELECT') {
                	if(field && field.customField && field.customField.listValues){
                    	angular.forEach(field.customField.listValues, function (value, key) {
                            if (field.valueFromJSON && field.valueFromJSON.model && field.valueFromJSON.model.$selected && field.valueFromJSON.model.$selected.id && field.valueFromJSON.model && value.value == field.valueFromJSON.model.$selected.id) {
                                valueLiteral = value.id;
                            }
                        });
                	}
                } else {
                	valueLiteral = field.valueFromJSON;
                }
                return valueLiteral;
            };
			
			if (TramNewFactory.Task && TramNewFactory.Task.customFields && TramNewFactory.Task.customFields.length > 0 && dossierCFs && dossierCFs.length > 0) {
				angular.forEach(dossierCFs,function (dossierCF, key) {
	                if(dossierCF && !dossierCF.hiddenField) {
					   var cfFromTask = $linq(TramNewFactory.Task.customFields).firstOrDefault(undefined,"x => x.customField && x.customField.id == " + dossierCF.customField.id);
					   if (cfFromTask) {
						   dossierCF.value = getCustomFieldTaskValue(cfFromTask);
					   }
	                }
	            });
			}
			
 			var dossierCustomFieldsBox = {
                boxTitle: 'global.literals.customFields',
                permissions: { view: 'start_dossier' },
                content:  dossierCFs,
                config: {showBox: (!TramNewFactory.Procedure || !TramNewFactory.Procedure.initCfieldWhenSaved)},
                origin: 'dossier-cf'
            };
			retBoxes.push(dossierCustomFieldsBox);
 			if(TramNewFactory.Register) {
                if (TramNewFactory.Register.thirds) {
                    angular.forEach(TramNewFactory.Register.thirds, function (value, key) {
                        // modificat per apop 10/01/2018
                        var dossierThird = new ThirdAddress(value.third, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);

                        receiverBox.boxDefinition.content.push({
                            third: value.third,
                            interested: value.interested,
                            roleInterested: value.roleInterested,
                            representative: value.representative,
                            principal: value.principal,
                            addresses: dossierThird.getAdressesHtml(),
                            representated: value.representated
                        });
                    });
                }

                if (TramNewFactory.Register.entryType == 'INPUT') {
                    registerEntryInputBox.content.push(TramNewFactory.Register);
                } else if (TramNewFactory.Register.entryType == 'OUTPUT') {
                    registerEntryOutputBox.content.push(TramNewFactory.Register);
                }
                if(TramNewFactory.Register.documents && TramNewFactory.Register.documents.length > 0) {
                    angular.forEach(TramNewFactory.Register.documents, function(value, key){
                    	var relType;
                    	if(value.relationType){
                    		relType = value.relationType;
                    	} else {
                    		relType = "DOCUMENT";
                    	}
                        var doc = {
                    		document:value.document,
                            publicDocument: false,
                            relationType: relType,
                            paperBin: false
                        };
                        documentBox.boxDefinition.content.push(doc);
                    });
                }

            }

 			if(TramNewFactory.Task) {
                if (TramNewFactory.Task.thirds) {
                    angular.forEach(TramNewFactory.Task.thirds, function (value, key) {
                        var dossierThird = new ThirdAddress(value.third, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);

                        receiverBox.boxDefinition.content.push({
                            third: value.third,
                            interested: true,
                            representative: false,
                            principal: false,
                            addresses: dossierThird.getAdressesHtml(),
                        });
                    });
                }

                if (TramNewFactory.Task.registerEntryInputs) {
                    registerEntryInputBox.content = TramNewFactory.Task.registerEntryInputs;
                }
                if (TramNewFactory.Task.registerEntryOutputs) {
                    registerEntryOutputBox.content = TramNewFactory.Task.registerEntryOutputs;
                }

                if(TramNewFactory.Task.documents && TramNewFactory.Task.documents.length > 0) {
                    angular.forEach(TramNewFactory.Task.documents, function(value, key){
                        var doc = {
                    		document:value,
                            publicDocument: false,
                            relationType: "DOCUMENT",
                            paperBin: false
                        };
                        documentBox.boxDefinition.content.push(doc);
                    });
                }
 			}
 			
            return retBoxes;
        };

        var newTram = function(model, okstate, processFunc) {
            var deferrend = $q.defer();
            _.forEach(model.receivers, function(value, key) {
                if(value.representated) {
                    delete value.representated.label;
                }
            });

            TramNewFactory.newDossier(model.newDossier, model.cf, $rootScope.LoggedUser, model.receivers, model.registerInputs, model.dossiers, model.registerOutputs, model.transactionType, model.documents, model.responsibleTransactionUser, model.profiles, model.addresses, model.cfDossierValues).then(function(data) {
                if(okstate) {
                	if (okstate == 'annexa.reg.input.diligences.edit' && $state.params && $state.params.diligence && $state.params.register) {
                		$state.transitionTo(okstate, { input: $state.params.register.id, diligence: $state.params.diligence.id });
                	} else if ($state.params && $state.params.task && $state.params.task.callback && okstate == $state.params.task.callback) {
                		$state.transitionTo(okstate, { task: $state.params.task.id });
                	} else {
                		$state.transitionTo(okstate);
                	}
                }
                if(processFunc) {
                    processFunc(data);
                }
            }).catch(function (error) {
				if(error && error.data && error.data.message === "Custom field is required"){
					DialogsFactory.error($filter('translate')('global.literals.customFieldOblig'));	
				}else{
                	deferrend.reject(ErrorFactory.getErrorMessage('register', 'new', error));
				}
            });
            return deferrend.promise;
        };
        
        var openEditReciverModal = function(index, content) {
        	var receiver = content[index];
            if (receiver.interested) {
        		var receiverThirdType = 'interested';
        	} else if (receiver.representative) {
        		var receiverThirdType = 'representative';
        	}
        	if (receiver.representated) {
        		var idReceiverRepresentated = receiver.representated.id;
        		if (!receiver.representated.label) {
                    if(receiver.representated.name) {
                    	receiver.representated.label = receiver.representated.name + ((receiver.representated.surname1Particles)? ' ' + receiver.representated.surname1Particles : '') + ' ' + receiver.representated.surename1 + ((receiver.representated.surname2Particles)? ' ' + receiver.representated.surname2Particles : '') + ((receiver.representated.surename2)? ' ' + receiver.representated.surename2 : '') + ' (' + receiver.representated.identificationDocument + ')';
                    }else{
                    	receiver.representated.label = receiver.representated.corporateName + ' (' + receiver.representated.identificationDocument + ')';
                    }
        		}
        	}
            
        	var modalReceiverAdd = angular.copy(globalModals.receiverAdd);
        	modalReceiverAdd.alerts = [];
            modalReceiverAdd.size = 'modal-md';
            modalReceiverAdd.annexaFormly.model = {};
            modalReceiverAdd.annexaFormly.model.modal_body = {
            		idReceiver: receiver.id,
            		thirdType: receiverThirdType, 
            		representated: receiver.representated, 
            		roleInterested: receiver.roleInterested,
            		third: receiver.third.id,
					notifiable: receiver.notifiable,
					canSelectNotifiable: true,
					additionalInformation: receiver.additionalInformation
            };
            modalReceiverAdd.annexaFormly.options = {};
            modalReceiverAdd.annexaFormly.options.formState = {
            		readOnly: false, 
            		data: content, 
            		editReceiver: true, 
            		index: index,
            		idReceiver: receiver.id, 
            		idThird: receiver.third.id,
            		idReceiverRepresentated: idReceiverRepresentated
            };
         
            var submitFunction = function() {
            	var content = this.annexaFormly.options.formState.data;
            	var index = this.annexaFormly.options.formState.index;
            	var modal_data = this.annexaFormly.model.modal_body;
            	
            	
            	var idReceiver = modal_data.idReceiver;
            	var thirdType = modal_data.thirdType;
            	var representated = modal_data.representated;
            	var roleInterested = modal_data.roleInterested;
            	var additionalInformation = modal_data.additionalInformation;
            	
            	if (idReceiver) {
            		//Guarda en BBDD
            		return $http({
                        url: './api/dossier_third/' + idReceiver,
                        method: 'GET'
                    }).then(function (data) {
                        var dossierThird = JSOG.decode(data.data);
                        
                        if (thirdType == 'interested') {
                        	dossierThird.interested = true;
                        	dossierThird.representated = undefined;
                            dossierThird.roleInterested = roleInterested;
                        } else {
                        	dossierThird.interested = false;
                        }
                        
                        if (thirdType == 'representative') {
                        	dossierThird.representative = true;
                        	if (representated) {
                            	dossierThird.representated = representated;
                            }
                        	dossierThird.roleInterested = undefined;
                        } else {
                        	dossierThird.representative = false;
                        }
                        dossierThird.notifiable = modal_data.notifiable;
                        dossierThird.additionalInformation = additionalInformation;
                        TramFactory.updateDossierThird(idReceiver, dossierThird)
                            .then(function(data) {
                            	var receiver = data;
                            	receiver.addresses = content[index].addresses;
                            	
                            	//Guarda el destinatario en angular
                            	content[index] = receiver;
                            	TramFactory.Dossier.thirds[index] = receiver;
                            	
                            	modalReceiverAdd.close();
                            }).catch(function (error) {
                                console.error(error);
                                modalReceiverAdd.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
                        });
                        
                    }).catch(function (error) {
                        console.error(error);
                        modalReceiverAdd.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
                    });
            	} else {
            		//Guarda en angular
            		var receiver = content[index];
            		
            		 if (thirdType == 'interested') {
            			 receiver.interested = true;
            			 receiver.representated = undefined;
                     	if (roleInterested) {
                     		receiver.roleInterested = roleInterested;
                         }
                     } else {
                    	 receiver.interested = false;
                     }
                     
                     if (thirdType == 'representative') {
                    	 receiver.representative = true;
                     	if (representated) {
                     		receiver.representated = representated;
                         }
                     	receiver.roleInterested = undefined;
                     } else {
                    	 receiver.representative = false;
                     }
                     receiver.notifiable = modal_data.notifiable;
                     receiver.additionalInformation = additionalInformation;
                     modalReceiverAdd.close();
            	}
            };
            
            AnnexaFormlyFactory.showModal("receiverAdd", modalReceiverAdd, submitFunction, false);
        }

        factory.getNewTramForm = function (scope, diligence, task) {
            var entity = new AnnexaEntity('new-dossier', 'form', tramNewForm(diligence?'annexa.reg.input.diligences.edit':(task&&task.callback)?task.callback:'annexa.tram.pending', scope), tramBoxes(scope)).setTitle('global.literals.basicInfo').setCancelState(diligence?'annexa.reg.input.diligences.edit':(task&&task.callback)?task.callback:'annexa.tram');
            if(entity){
            	entity.valuesFromLinkedCustomFields = {parentId:((TramNewFactory.Procedure)?TramNewFactory.Procedure.id:undefined), objectParent:"PROCEDURE", inConfig:true};
            }
            return entity;
        };
        
        
        factory.postalAddressRender = function(address) {
        	var langColumn = Language.getActiveColumn();
            var value = '';
            if(address.validStreet) {
                if(address.validStreet.streetType){
                	value += address.validStreet.streetType[langColumn]+ ' ';
                }
                value += address.validStreet.name+ ' ';
            } else {
                if(address.streetType) {
                	value += ' ' + address.streetType[langColumn];
                }
                if(address.street) {
                	value += ' ' + address.street + ', ';
                }
            }
            if(address.kilometer) {
            	value += 'Km. ' + address.kilometer + " ";
            }
            if(address.numberAddres) {
            	value += 'Num. ' + address.numberAddres + "  ";
            }
            if(address.block) {
            	value += 'Bl. ' + address.block + "  ";
            }
            if(address.stair) {
            	value += 'Esc. ' + address.stair + "  ";
            }
            if(address.floor) {
            	value += 'Pla. ' + address.floor + "  ";
            }
            if(address.door) {
            	value += 'Pta. ' + address.door + " ";
            }
            if(address.postalCode) {
            	value += 'C.P. ' + address.postalCode + " ";
            }
            if(address.validCity) {
            	value += address.validCity.name + " ";
            } else {
                if(address.city) {
                	value += address.city + " ";
                }
            }
            if(address.validState) {
            	value += address.validState[langColumn] + " " ;
            } else if(address.stateAddress){
                value += address.stateAddress + " ";
            }
            if(address.country) {
            	value += ' ('+ address.country[langColumn] + ')';
            }

            return value;
        }
        
        //endregion

        //region Task
        var getTaskBoxesModel = function (formExtra) {
            return {
                comments: formExtra[0].boxDefinition.content,
                dossierTransactions: formExtra[1].boxDefinition.content,
                registerInputs: formExtra[2].boxDefinition.content,
                registerOutputs: formExtra[3].boxDefinition.content,
                documents: formExtra[4].boxDefinition.content,
                thirds: formExtra[5].boxDefinition.content,
                users: formExtra[6].content,
                addresses: formExtra[7].content,
                relatedTasks: formExtra[8].content
            };
        };

        var taskFromFields = function(colClass) {
            var form = new AnnexaFormly();

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong small m-b-0 ',
                extralabel: true
            };

            var fields = {};
            var descriptionLanguageColumn = "descriptionLanguage" + Language.getActiveColumn().substr(Language.getActiveColumn().length - 1);
            var field1 = form.createField('template','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.template','id',descriptionLanguageColumn,GlobalDataFactory.taskTemplates,false),data);
            fields.template = field1;

            fields.assignTo = form.createField('assignTo', 'annexaRadioCheckboxRow', 'col-sm-12', 
            		new AnnexaFormlyFieldSelectTemplateOptions('global.literals.assign_to', 'id', 'description', 
            				[{id: 'user', description: $filter('translate')('global.literals.user')}, {id: 'profile', description: $filter('translate')('global.literals.profile')}], 
            				true, 'radio'), 
            			data
            		);
            
            var field2 = form.createField('user','annexaLoadUserRow','col-sm-12',new AnnexaFormlyLoadUserFieldTemplateOptions('text','global.literals.assigned_to',true,false,[], CommonService.loadUsers),data);
            field2.hideExpression = function($viewValue, $modelValue, scope) {
            	if (scope.model.assignTo && scope.model.assignTo == 'user') {
            		return false;
            	}
            	return true; 
            };

            fields.user = field2;
            
            var allProfiles = new SortedArray($linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray(), Language.getActiveColumn()).sort();
            var field2p = form.createField('profile','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.assigned_to', 'id', Language.getActiveColumn(), allProfiles, true), data);
            field2p.hideExpression = function($viewValue, $modelValue, scope) {
            	if (scope.model.assignTo && scope.model.assignTo == 'profile') {
            		return false;
            	}
            	return true; 
            };

            fields.profile = field2p;

            var field3 ;

            if($rootScope.esetMode) {
                field3 = form.createField(
                    'description',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                        'text',
                        'global.literals.title',
                        true,
                        undefined,
                        undefined,
                        undefined,
                        true
                    ),
                    data
                );
            } else {
                field3 = form.createField('description', 'annexaTextAreaRow', 'col-sm-12', new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.description', 3, true, undefined, undefined, true), data);
            }

            fields.description = field3;

            var field4 ;

            if($rootScope.esetMode) {
                field4 = form.createField(
                    'taskEndDate',
                    'annexaDatepickerRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldDatepickerTemplateOptions(
                        'text',
                        'global.literals.days',
                        true,
                        {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1,
                            minDate: new Date()
                        }
                    ),
                    data
                );
            } else {
                field4 = form.createField('days', 'annexaInputRow', 'col-sm-12', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.days', true, true), data);
            }

            fields.days = field4;

            var field5 = form.createField('taskType','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskType','id',Language.getActiveColumn(),GlobalDataFactory.taskTypes,true),data);
            fields.taskType = field5;
            
            var field6 = form.createField('status','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',Language.getActiveColumn(),[],true),data);
            fields.status = field6;
            
            var field7 = form.createField('origin','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskOrigin','id',Language.getActiveColumn(),GlobalDataFactory.taskOrigins,false),data);
            fields.origin = field7;
            
            var emailValidator = {
	                "mailInvalid": {
	                    "expression": function (viewValue, modelValue, scope) {
	                        if (modelValue) {
	                        	if (modelValue != '' && !HelperService.validarEmail(modelValue)) {
	                                return false;
	                            } else {
	                                return true;
	                            }
	                        } else {
	                            return true;
	                        }
	                    }
	                }
	            };
            
            var field8 = form.createField('email','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptionsValidator('text', 'global.tasks.literals.email', false, false, undefined, emailValidator, 50),data);
            fields.email = field8;
            
            var field9 = form.createField('phone','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text', 'global.tasks.literals.phone', false, false, undefined, 50),data);
            fields.phone = field9;
            
            var field10 = {key: 'taskCustomFields',className: '',templateOptions: {},fieldGroup: [],
                    hideExpression: function ($viewValue, $modelValue, scope) {
                    	if(scope.model && scope.model.taskCustomFieldsShow){
                    		return false;
                    	}else{
                    		return true;
                    	}
                    }};
            fields.tasksCustomFields = field10;
            
            
            return fields;
        };

        var newTaskForm = function(saveOkState) {
            var form = new AnnexaFormly();

            form.model = {days:30, description:'', user:'', assignTo:'user', taskCustomFields: {}, origTaskType:undefined};

            var fields = taskFromFields('col-sm-12');
            form.fields.push(fields.template);
            fields.template.templateOptions.onSelected = function(template){
            	if (template) {
                    form.model.days = template.days;
                    form.model.description = template[fields.template.templateOptions.labelProp];
                    form.model.taskType = template.type.id;
                    fields.taskType.templateOptions.onSelected(template.type, template);
                    form.model.status = template.status.id;
            	} else {
            		form.model.days = 30;
            		form.model.description = '';
            		form.model.taskType = undefined;
            		form.model.status = undefined;
            	}
            };
            form.fields.push(fields.taskType);
            var statusOnSelected = function(status) {
            	if (status && (status.user || status.profile)) {
            		assignUserOrProfileChangeStateTask(form, status, true);
            	} else {
            		assignUserOrProfileChangeStateTask(form, undefined, false);
            	}
            }
            fields.taskType.templateOptions.onSelected = function(taskType, template){
            	var indexFieldStatus = $linq(form.fields).indexOf("x => x.key == 'status'");
                if(indexFieldStatus != -1){
                	form.fields.splice(indexFieldStatus, 1);
                }

            	var statuses = [];
            	if (taskType) {
            		if (taskType.statuses) {
            			statuses = taskType.statuses;
            		}
            		if (taskType.parent && taskType.parent.statuses) {
            			statuses = statuses.concat(taskType.parent.statuses);
            			statuses = $linq(statuses).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
            		}

            		var defaultStatus = $linq(statuses).firstOrDefault(undefined, "x => x.defaultValue == true");
            		if (defaultStatus) {
            			form.model.status = defaultStatus.id;
            			fields.status.templateOptions.onSelected(defaultStatus);
            		} else {
            			form.model.status = undefined;
            			fields.status.templateOptions.onSelected(undefined);
            		}

                	// Procedència tipus tasca
                	if (taskType.origin)
                		form.model.origin = taskType.origin.id;
                	else
                		form.model.origin = undefined;
            	}
                var data = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: 'label-strong small m-b-0 ',
                    extralabel: true
                };
            	fields.status = form.createField('status','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',Language.getActiveColumn(),statuses,true),data);
                fields.status.templateOptions.onSelected = statusOnSelected;
            	form.fields.splice(indexFieldStatus, 0, fields.status);
            	if(taskType && form.model.origTaskType !== taskType.id){
            		if(form.model.taskCustomFields){
            			var keys = Object.keys(form.model.taskCustomFields);
                        _.forEach(keys, function(key){
                        	delete form.model.taskCustomFields[key];
                        });
            		}else{
            			form.model.taskCustomFields = {};
            		}
            		form.model.taskCustomFieldsShow = false;
            		var taskCustomFieldsField = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'taskCustomFields'");
            		if(taskCustomFieldsField && taskCustomFieldsField.fieldGroup){
            			taskCustomFieldsField.fieldGroup.length = 0;
            		}
            		$rootScope.loadingDiv = true;
	        		try{
	        			var promises = [];
		                promises.push(RestService.findOne('TaskType', taskType.id));
		                promises.push(TaskFactory.calculateTaskTypeCustomField(taskType.id));
		                if(template && !template.ngModelAttrs && template.id){
		                	promises.push(RestService.findOne('TaskTemplate', template.id));
		                }
		                $q.all(promises)
		                .then(function(data) {
		                	if(data && data[0].data){
				        		var taskTypeAux = JSOG.decode(data[0].data);
				        		taskType.customFields = taskTypeAux.customFields;
				        		taskType.parent = taskTypeAux.parent;
				        		var typeGDF = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + taskType.id);
				        		if(typeGDF){
				        			typeGDF.customFields = taskTypeAux.customFields;
					        		typeGDF.parent = taskTypeAux.parent;
				        		}
				        	}
		                	if(template && !template.ngModelAttrs && template.id && data[2].data){
		                		var templateAux = JSOG.decode(data[2].data);
		                		template.customFields = templateAux.customFields;
		                	}
		                	if((!taskType && form.model.origTaskType) || (taskType && form.model.origTaskType !== taskType.id)){
            		if(taskType.customFields){
            			var deleteValueFromGroups = function(groups){
            				if(groups && groups.length > 0){
            					_.forEach(groups, function(g){
            						if(g.groups && g.groups.length > 0){
            							deleteValueFromGroups(g.groups);
            						}else{
            							g.value = undefined;
            						}
            					});
            				}
            			}
            			_.forEach(taskType.customFields, function(ttcf){
            				if(ttcf.groups && ttcf.groups.length > 0){
            					deleteValueFromGroups(ttcf.groups);
            				}
            			});
            		}
		                		
		                		TaskFactory.updateFormCustomFields(taskCustomFieldsField, taskType, form, undefined, JSOG.decode(data[1])).then(function(data) {
            			if(data.haveTaskCustomFields){
            				form.model.taskCustomFieldsShow = true;
            				if (template.customFields && !template.ngModelAttrs) {
            					try{
	                            	_.forEach(template.customFields, function(cfTemplate) {
	                            		var jsonCfValue = JSON.parse(cfTemplate.value);
	                            		if (cfTemplate.customField.frontendType == 'CHECKBOX') {
	                                        angular.forEach(cfTemplate.customField.listValues, function (value, key) {
	                                        	form.model.taskCustomFields[jsonCfValue.id + '_' + value.value] = false;
	                                        	angular.forEach(jsonCfValue.value,function (valueJ, keyJ) {
	                                                if (value.value == valueJ) {
	                                                	form.model.taskCustomFields[jsonCfValue.id + '_' + value.value] = true;
	                                                }
	                                            });
	                                        });
	                            		} else {
	                                        if(cfTemplate.customField.backendType == "DATETIME") {
	                                        	if(jsonCfValue.value){
	                                        		form.model.taskCustomFields[jsonCfValue.id] = new Date(jsonCfValue.value);
	                                        	}else{
	                                        		form.model.taskCustomFields[jsonCfValue.id] = jsonCfValue.value;
	                                        	}
	                                        } else {
	                                        	form.model.taskCustomFields[jsonCfValue.id] = jsonCfValue.value;
	                                        }
	                            		}
	                            	});
            					}catch(e){
								}
                            }
            			}
		                			$rootScope.loadingDiv = false;
            		}).catch(function() {
            			taskCustomFieldsField.fieldGroup.length = 0;
		                			$rootScope.loadingDiv = false;
                    });
				        	}else{
				        		$rootScope.loadingDiv = false;
            	}
            	form.model.origTaskType = ((taskType)?taskType.id:undefined);
		                }).catch(function(error){
			        		$rootScope.loadingDiv = false;
			        		console.error(error);
			        		form.model.origTaskType = ((taskType)?taskType.id:undefined);
			        	});
	        		}catch(e){
	        			$rootScope.loadingDiv = false;
	        			console.error(e);
	        			form.model.origTaskType = ((taskType)?taskType.id:undefined);
	        		}
            	}else{
            		form.model.origTaskType = ((taskType)?taskType.id:undefined);
            	}
            };
            fields.taskType.data.clear = function ($event, model, key, $select, to) {
                $event.stopPropagation();
                model[key] = undefined;
                if ($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
                if(to.onSelected) {
                    to.onSelected(undefined);
                }
            }
            form.fields.push(fields.status);
            fields.status.templateOptions.onSelected = statusOnSelected;
            form.fields.push(fields.assignTo);
            form.fields.push(fields.user);
            form.fields.push(fields.profile);
            form.fields.push(fields.origin);
            form.fields.push(fields.description);
            form.fields.push(fields.days);
            form.fields.push(fields.email);
            form.fields.push(fields.phone);
            form.fields.push(fields.tasksCustomFields);
            var submitNewTask = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var boxesModel = getTaskBoxesModel(form.extra);
                    var task = {};
                    if($rootScope.esetMode) {
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, $rootScope.LoggedUser.entity.holidays);
                        }else{
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, undefined);
                        }
                    } else {
                        task.days = form.model.days;
                    }
                    task.description = form.model.description;
                    if (form.model.assignTo && form.model.assignTo == 'user') {
                    	task.user = form.model.user ? { id: form.model.user.id } : undefined;
                    	task.profile = undefined;
                    } else if (form.model.assignTo && form.model.assignTo == 'profile') {
                    	task.profile = form.model.profile ? { id: form.model.profile } : undefined;
                    	task.user = undefined;
                    }
                    task.finishOk = false;
                    task.endedTask = false;
                    task.createdUser = {id:$rootScope.LoggedUser.id};
                    task.taskComments = boxesModel.comments;
                    task.documents = boxesModel.documents;
                    task.registerEntryInputs = boxesModel.registerInputs;
                    task.registerEntryOutputs = boxesModel.registerOutputs;
                    task.dossierTransactions = boxesModel.dossierTransactions;
                    task.relatedTasks = getRelatedTasks(boxesModel.relatedTasks);
                    task.thirds = getThirds(boxesModel.thirds);
                    task.users = getUsers(boxesModel.users);
                    task.addresses = getAddresses(boxesModel.addresses);
                    task.status = { id: form.model.status };
                    task.type = { id: form.model.taskType };
                    task.origin = form.model.origin ? { id: form.model.origin } : undefined;
                    task.email = form.model.email ? form.model.email : null;
                    task.phone = form.model.phone ? form.model.phone : null;
                    var calculateCFTGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(ng){
                    			_.forEach(ng.groups, function(g){
                    				g.groups = calculateCFTGroups(g.newGroups); 
                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                    				groups.push(g);
                    			})
                    		})
                    	}
                    	return groups;
                    }
                	var setValueCustomFields = function(customFields, taskType, cf) {
                        _.forEach(customFields, function(taskTypeField) {
                        	var taskCustomField = angular.copy(taskTypeField);
                        	taskCustomField.id = undefined;
                            taskCustomField.customField = { id: taskTypeField.customField.id };
                            taskCustomField.taskType = { id: taskTypeField.taskType.id };
                            taskCustomField.groups = calculateCFTGroups(taskCustomField.newGroups);
                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + taskType.id  + "_" + taskTypeField.customField.id + "'");

                            if(cfValue) {
                                if(taskTypeField.customField.frontendType == 'CHECKBOX'){
                                    var custom_field_selected = $linq(cf).where(function(x){
                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                    ).toArray();
                                    if(custom_field_selected && custom_field_selected.length > 0){
                                        taskCustomField.value = '[';
                                        _.forEach(custom_field_selected, function (item, key) {
                                            if(item.value) {
                                                if(taskTypeField.customField && taskTypeField.customField.listValues && taskTypeField.customField.listValues.length > 0) {
                                                    var custom_field_value_selected = $linq(taskTypeField.customField.listValues).where(function(x){
                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                    ).toArray();
                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                        taskCustomField.value += ((taskCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                    }
                                                }
                                            }
                                        });
                                        taskCustomField.value += ']';
                                    }
                                }else if(Array.isArray(cfValue.value)) {
                                    if(taskTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                        taskCustomField.value = '[';

                                        _.forEach(cfValue.value, function (item) {
                                            taskCustomField.value += ((taskCustomField.value == '[') ? item : ',' + item);
                                        });

                                        taskCustomField.value += ']';
                                    } else {
                                        taskCustomField.value = cfValue.value[0];
                                    }
                                } else {
                                    if(cfValue.value instanceof Date) {
                                        taskCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                    } else {
                                        taskCustomField.value = cfValue.value;
                                    }
                                }
                            }

                            task.customFields.push(taskCustomField);
                        });
                	}
                    var cf = CustomFieldFactory.getModelValues(form.model.taskCustomFields);
                    task.customFields = [];
                    var taskType = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + task.type.id);
                    if(taskType && cf && cf.length > 0) {
                    	setValueCustomFields(taskType.customFields, taskType, cf);
                    	//setegem també els valors dels customField dels tipus de tasca pare
                        if (taskType.parent) {
                        	setValueCustomFields(taskType.parent.customFields, taskType, cf);
                        }
                    }
                    
                    newTask(task, saveOkState);
                }
            };

            form.addSubmitCallback(submitNewTask);

            form.options = { watchAllExpressions: true };

            return form;
        };

        factory.getTaskBoxNewForm = function(modal, processFunc) {
            return taskBoxNewForm({}, modal, processFunc);
        };

        var assignUserOrProfileChangeStateTask = function(form, status, disabled) {
        	if (status) {
            	if (status.user) {
                	form.model.assignTo = 'user';
                	var name = status.user.name + ' ' + status.user.surename1;
                    if(status.user.surename2) {
                        name += ' ' + status.user.surename2;
                    }
                    form.model.user = { 'id':  status.user.id, 'user': status.user, 'value': name };
                } else if (status.profile) {
                	form.model.assignTo = 'profile';
                	form.model.profile = status.profile.id;
                }
        	}
        	
        	_.forEach(form.fields, function(fieldGroup){
				if(fieldGroup && fieldGroup.templateOptions && (fieldGroup.key == 'assignTo' || fieldGroup.key == 'user' || fieldGroup.key == 'profile' )){
					fieldGroup.templateOptions.disabled = disabled;
				}
        	});
        }

        var taskBoxNewForm = function(boxDefinition, modal, processFunc) {
            var form = new AnnexaFormly();

            form.model = {days:30, description:'', user:'', assignTo:'user'};

            var fields = taskFromFields('col-sm-6');
            form.fields.push(fields.template);
            fields.template.templateOptions.onSelected = function(template){
            	if (template) {
                    form.model.days = template.days;
                    form.model.description = template[fields.template.templateOptions.labelProp];
                    form.model.taskType = template.type.id;
                    fields.taskType.templateOptions.onSelected(template.type, template);
                    form.model.status = template.status.id;
            	} else {
            		form.model.days = 30;
            		form.model.description = '';
            		form.model.taskType = undefined;
            		form.model.status = undefined;
            	}
            };
            form.fields.push(fields.assignTo);
            form.fields.push(fields.user);
            form.fields.push(fields.profile);
            form.fields.push(fields.description);
            form.fields.push(fields.days);
            form.fields.push(fields.taskType);
            var statusOnSelected = function(status) {
            	if (status && (status.user || status.profile)) {
            		assignUserOrProfileChangeStateTask(form, status, true);
            	} else {
            		assignUserOrProfileChangeStateTask(form, undefined, false);
            	}
            }
            fields.taskType.templateOptions.onSelected = function(taskType, template){
            	var indexFieldStatus = $linq(form.fields).indexOf("x => x.key == 'status'");
                if(indexFieldStatus != -1){
                	form.fields.splice(indexFieldStatus, 1);
                }

            	var statuses = [];
            	if (taskType) {
            		if (taskType.statuses) {
            			statuses = taskType.statuses;
            		}
            		if (taskType.parent && taskType.parent.statuses) {
            			statuses = statuses.concat(taskType.parent.statuses);
            			statuses = $linq(statuses).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
            		}

            		var defaultStatus = $linq(statuses).firstOrDefault(undefined, "x => x.defaultValue == true");
            		if (defaultStatus) {
            			form.model.status = defaultStatus.id;
            			fields.status.templateOptions.onSelected(defaultStatus);
            		} else {
            			form.model.status = undefined;
            			fields.status.templateOptions.onSelected(undefined);
            		}

                	// Procedència tipus tasca
                	if (taskType.origin)
                		form.model.origin = taskType.origin.id;
                	else
                		form.model.origin = undefined;
            	}

                var data = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: 'label-strong small m-b-0 ',
                    extralabel: true
                };
            	
            	fields.status = form.createField('status','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.taskStatus','id',Language.getActiveColumn(),statuses,true),data);
                fields.status.templateOptions.onSelected = statusOnSelected;
            	form.fields.splice(indexFieldStatus, 0, fields.status);
            	if(taskType && form.model.origTaskType !== taskType.id){
            		if(form.model.taskCustomFields){
            			var keys = Object.keys(form.model.taskCustomFields);
                        _.forEach(keys, function(key){
                        	delete form.model.taskCustomFields[key];
                        });
            		}else{
            			form.model.taskCustomFields = {};
            		}
            		form.model.taskCustomFieldsShow = false;
            		var taskCustomFieldsField = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'taskCustomFields'");
            		if(taskCustomFieldsField && taskCustomFieldsField.fieldGroup){
            			taskCustomFieldsField.fieldGroup.length = 0;
            		}
            		$rootScope.loadingDiv = true;
	        		try{
	        			var promises = [];
		                promises.push(RestService.findOne('TaskType', taskType.id));
		                promises.push(TaskFactory.calculateTaskTypeCustomField(taskType.id));
		                if(template && !template.ngModelAttrs && template.id){
		                	promises.push(RestService.findOne('TaskTemplate', template.id));
		                }
		                $q.all(promises)
		                .then(function(data) {
		                	if(data && data[0].data){
				        		var taskTypeAux = JSOG.decode(data[0].data);
				        		taskType.customFields = taskTypeAux.customFields;
				        		taskType.parent = taskTypeAux.parent;
				        	}
		                	if(template && !template.ngModelAttrs && template.id && data[2].data){
		                		var templateAux = JSOG.decode(data[2].data);
		                		template.customFields = templateAux.customFields;
		                	}
		                	if((!taskType && form.model.origTaskType) || (taskType && form.model.origTaskType !== taskType.id)){
            		if(taskType.customFields){
            			var deleteValueFromGroups = function(groups){
            				if(groups && groups.length > 0){
            					_.forEach(groups, function(g){
            						if(g.groups && g.groups.length > 0){
            							deleteValueFromGroups(g.groups);
            						}else{
            							g.value = undefined;
            						}
            					});
            				}
            			}
            			_.forEach(taskType.customFields, function(ttcf){
            				if(ttcf.groups && ttcf.groups.length > 0){
            					deleteValueFromGroups(ttcf.groups);
            				}
            			});
            		}
		                		TaskFactory.updateFormCustomFields(taskCustomFieldsField, taskType, form, undefined, JSOG.decode(data[1])).then(function(data) {
            			if(data.haveTaskCustomFields){
            				form.model.taskCustomFieldsShow = true;
            				if (template.customFields && !template.ngModelAttrs) {
            					try{
	                            	_.forEach(template.customFields, function(cfTemplate) {
	                            		var jsonCfValue = JSON.parse(cfTemplate.value);
	                            		if (cfTemplate.customField.frontendType == 'CHECKBOX') {
	                                        angular.forEach(cfTemplate.customField.listValues, function (value, key) {
	                                        	form.model.taskCustomFields[jsonCfValue.id + '_' + value.value] = false;
	                                        	angular.forEach(jsonCfValue.value,function (valueJ, keyJ) {
	                                                if (value.value == valueJ) {
	                                                	form.model.taskCustomFields[jsonCfValue.id + '_' + value.value] = true;
	                                                }
	                                            });
	                                        });
	                            		} else {
	                                        if(cfTemplate.customField.backendType == "DATETIME") {
	                                        	if(jsonCfValue.value){
	                                        		form.model.taskCustomFields[jsonCfValue.id] = new Date(jsonCfValue.value);
	                                        	}else{
	                                        		form.model.taskCustomFields[jsonCfValue.id] = jsonCfValue.value;
	                                        	}
	                                        } else {
	                                        	form.model.taskCustomFields[jsonCfValue.id] = jsonCfValue.value;
	                                        }
	                            		}
	                            	});
            					}catch(e){
								}
                            }
            			}
		                			$rootScope.loadingDiv = false;
            		}).catch(function() {
            			taskCustomFieldsField.fieldGroup.length = 0;
		                			$rootScope.loadingDiv = false;
                    });
		                	}else{
				        		$rootScope.loadingDiv = false;
            	}
            	form.model.origTaskType = ((taskType)?taskType.id:undefined);
		                }).catch(function(error){
			        		$rootScope.loadingDiv = false;
			        		console.error(error);
			        		form.model.origTaskType = ((taskType)?taskType.id:undefined);
			        	});
	        		}catch(e){
	        			$rootScope.loadingDiv = false;
	        			console.error(e);
	        			form.model.origTaskType = ((taskType)?taskType.id:undefined);
	        		}
            	}else{
            		form.model.origTaskType = ((taskType)?taskType.id:undefined);
            	}
            };
            fields.taskType.data.clear = function ($event, model, key, $select, to) {
                $event.stopPropagation();
                model[key] = undefined;
                if ($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
                if(to.onSelected) {
                    to.onSelected(undefined);
                }
            }
            form.fields.push(fields.status);
            fields.status.templateOptions.onSelected = statusOnSelected;
            form.fields.push(fields.origin);
            form.fields.push(fields.email);
            form.fields.push(fields.phone);
            form.fields.push(fields.tasksCustomFields);

            var submitNewTask = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var boxesModel = getTaskBoxesModel(form.extra);
                    var task = {};
                    if($rootScope.esetMode) {
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, $rootScope.LoggedUser.entity.holidays);
                        }else{
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, undefined);
                        }
                    } else {
                        task.days = form.model.days;
                    }
                    task.description = form.model.description;
                    if (form.model.assignTo && form.model.assignTo == 'user') {
                    	task.user = form.model.user ? { id: form.model.user.id } : undefined;
                    	task.profile = undefined;
                    } else if (form.model.assignTo && form.model.assignTo == 'profile') {
                    	task.profile = form.model.profile ? { id: form.model.profile } : undefined;
                    	task.user = undefined;
                    }
                    task.finishOk = false;
                    task.endedTask = false;
                    task.createdUser = {id:$rootScope.LoggedUser.id};
                    task.taskComments = boxesModel.comments;
                    task.documents = boxesModel.documents;
                    task.registerEntryInputs = boxesModel.registerInputs;
                    task.registerEntryOutputs = boxesModel.registerOutputs;
                    task.dossierTransactions = boxesModel.dossierTransactions;
                    task.relatedTasks = getRelatedTasks(boxesModel.relatedTasks);
                    task.thirds = getThirds(boxesModel.thirds);
                    task.users = getUsers(boxesModel.users);
                    task.addresses = getAddresses(boxesModel.addresses);
                    task.status = { id: form.model.status };
                    task.type = { id: form.model.taskType };
                    task.origin = form.model.origin ? { id: form.model.origin } : undefined;
                    task.email = form.model.email ? form.model.email : null;
                    task.phone = form.model.phone ? form.model.phone : null;
                    var calculateCFTGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(ng){
                    			_.forEach(ng.groups, function(g){
                    				g.groups = calculateCFTGroups(g.newGroups);
                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                    				groups.push(g);
                    			})
                    		})
                    	}
                    	return groups;
                    }
                	var setValueCustomFields = function(customFields, taskType, cf) {
                        _.forEach(customFields, function(taskTypeField) {
                            var taskCustomField = angular.copy(taskTypeField);
                            taskCustomField.id = undefined;
                            taskCustomField.customField = { id: taskTypeField.customField.id };
                            taskCustomField.taskType = { id: taskTypeField.taskType.id };
                            taskCustomField.groups = calculateCFTGroups(taskCustomField.newGroups);
                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + taskType.id  + "_" + taskTypeField.customField.id + "'");
                            if(cfValue) {
                                if(taskTypeField.customField.frontendType == 'CHECKBOX'){
                                    var custom_field_selected = $linq(cf).where(function(x){
                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                    ).toArray();
                                    if(custom_field_selected && custom_field_selected.length > 0){
                                        taskCustomField.value = '[';
                                        _.forEach(custom_field_selected, function (item, key) {
                                            if(item.value) {
                                                if(taskTypeField.customField && taskTypeField.customField.listValues && taskTypeField.customField.listValues.length > 0) {
                                                    var custom_field_value_selected = $linq(taskTypeField.customField.listValues).where(function(x){
                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                    ).toArray();
                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                        taskCustomField.value += ((taskCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                    }
                                                }
                                            }
                                        });
                                        taskCustomField.value += ']';
                                    }
                                }else if(Array.isArray(cfValue.value)) {
                                    if(taskTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                        taskCustomField.value = '[';

                                        _.forEach(cfValue.value, function (item) {
                                            taskCustomField.value += ((taskCustomField.value == '[') ? item : ',' + item);
                                        });

                                        taskCustomField.value += ']';
                                    } else {
                                        taskCustomField.value = cfValue.value[0];
                                    }
                                } else {
                                    if(cfValue.value instanceof Date) {
                                        taskCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                    } else {
                                        taskCustomField.value = cfValue.value;
                                    }
                                }
                            }

                            task.customFields.push(taskCustomField);
                        });
                	}
                	var cf = CustomFieldFactory.getModelValues(form.model.taskCustomFields);
                    task.customFields = [];
                    var taskType = $linq(GlobalDataFactory.taskTypes).singleOrDefault(undefined, "x => x.id == " + task.type.id);
                    if(taskType && cf && cf.length > 0) {
                    	setValueCustomFields(taskType.customFields, taskType, cf);
                    	
                    	//setegem també els valors dels customField dels tipus de tasca pare
                        if (taskType.parent) {
                        	setValueCustomFields(taskType.parent.customFields, taskType, cf);
                        }
                    }
                    
                    if(boxDefinition.isTramBox && boxDefinition.isTramBox.id && task.documents && task.documents.length > 0) {
                        var modalSelectProfile = angular.copy(TasksModals.newTaskAddProfileToDocument);
                        var userProfiles = [];
                        if (form && form.model && form.model.user && form.model.user.user &&form.model.user.user.userProfiles) {
                            _.forEach(form.model.user.user.userProfiles, function(userProfile){
                                if(userProfile.profile){
                                    userProfiles.push({id:userProfile.profile.id, language1: userProfile.profile.language1, language2: userProfile.profile.language2, language3: userProfile.profile.language3});
                                }
                            });
                        }
                        modalSelectProfile.annexaFormly.model = {};
                        modalSelectProfile.annexaFormly.model.modal_body = {userProfiles:userProfiles};
                        modalSelectProfile.annexaFormly.options = {};
                        modalSelectProfile.annexaFormly.options.formState = {readOnly: false};
                        var createNewTaskComplete = function(){
                            if(modalSelectProfile.annexaFormly.model.userModifyDocument == 1){
                                newTask(task, undefined, processNewTask, modalSelectProfile.annexaFormly.model.profile.id).then(function (data) {
                                    modalSelectProfile.close();
                                }).catch(function (error) {
                                    modalSelectProfile.alerts.push({msg: error});
                                });
                            }else{
                                newTask(task, undefined, processNewTask).then(function (data) {
                                    modalSelectProfile.close();
                                }).catch(function (error) {
                                    modalSelectProfile.alerts.push({msg: error});
                                });
                            }

                        };
                        AnnexaFormlyFactory.showModal("modalUpdateComentTask", modalSelectProfile, createNewTaskComplete, false);
                    }else{
                        newTask(task, undefined, processFunc ? processFunc : processNewTask, undefined, form ).then(function (data) {
                        }).catch(function (error) {
                            modal.alerts.push({msg: error});
                        });
                    }
                }
            };

            var processNewTask = function(task) {
                boxDefinition.searchedValue = { id: task.id, object: task };
                boxDefinition.searchAdd();
                form.close();
            };

            form.addSubmitCallback(submitNewTask);

            form.options = { watchAllExpressions: true };

            return form;
        };

        var commentTaskBox = function(permissions, enabled) {
            var initialState = '';
            var commentTaskBox = angular.copy(TaskFactory.CommentBox).initialize(AnnexaPermissionsFactory.haveOnePermission(permissions), enabled);
            commentTaskBox.boxDefinition.content = [];
            commentTaskBox.boxDefinition.saveSearch = undefined;
            commentTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                commentTaskBox.boxDefinition.content.splice(index, 1);
            };
            commentTaskBox.boxDefinition.renderType = 'comment-task';
            commentTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return commentTaskBox;
        };

        var attachmentTaskBox = function(permissions, enabled) {
            var initialState = '';
            var attachmentTaskBox = angular.copy(TaskFactory.AttachmentBox).initialize(AnnexaPermissionsFactory.haveOnePermission(permissions), enabled);
            attachmentTaskBox.boxDefinition.content = [];
            attachmentTaskBox.boxDefinition.saveSearch = undefined;
            attachmentTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                attachmentTaskBox.boxDefinition.content.splice(index, 1);
            };
            attachmentTaskBox.boxDefinition.renderType = 'attachment-task';
            attachmentTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return attachmentTaskBox;
        };

        var dossierTransactionTaskBox = function(enabled) {
            var initialState = '';
            var dossierTransactionTaskBox = angular.copy(BoxFactory.DossierTransactionBox).initialize('global.literals.related_transactions','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers',false,enabled,enabled, Language.getActiveColumn());
            dossierTransactionTaskBox.boxDefinition.content = [];
            dossierTransactionTaskBox.boxDefinition.saveSearch = undefined;
            dossierTransactionTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                dossierTransactionTaskBox.boxDefinition.content.splice(index, 1);
            };
            dossierTransactionTaskBox.boxDefinition.renderType = 'dossierTransaction-task';
            dossierTransactionTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return dossierTransactionTaskBox;
        };

        factory.taskBoxes = function () {
            var retBoxes = [];

            retBoxes.push(commentTaskBox(['create_task'], true));

            var dossierTransactionBox = dossierTransactionTaskBox(true);
            dossierTransactionBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            if (dossierModule.length == 1) {
                retBoxes.push(dossierTransactionBox);
            }

            var registerInputBox = getBox('registerEntry', 'registerEntry-task-input', 'global.literals.reg_input','global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', true, true, true, true, 'INPUT', undefined, undefined, undefined, true);
                
            registerInputBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            registerInputBox.boxDefinition.languageColumn = Language.getActiveColumn();

            if(regInputModule.length == 1) {
                retBoxes.push(registerInputBox);
            }

            var registerOutputBox = getBox('registerEntry', 'registerEntry-task-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', true, true, true, true, 'OUTPUT', undefined, undefined, undefined, true);
            registerOutputBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            registerOutputBox.boxDefinition.languageColumn = Language.getActiveColumn();
            
            if(regOutputModule.length == 1) {
                retBoxes.push(registerOutputBox);
            }


            var documentBox = getBox('document', 'document-task', 'global.literals.documents','global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents',true,true,true, true);
            documentBox.boxDefinition.fromTemplate = function () {
                factory.showNewDocumentFromTemplateModal(TramNewFactory.initialTransaction, new SortedArray(TramFactory.TramTemplates, Language.getActiveColumn()).sort(), GlobalDataFactory.documentTypes, documentBox.boxDefinition);
            };
            documentBox.boxDefinition.actualTransaction = TramNewFactory.initialTransaction;
            if (docModule.length == 1) {
                retBoxes.push(documentBox);
            }


            var thirdBox = getBox('third', 'third-task', 'global.literals.thirds', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers', true, true, true, true);
            thirdBox.boxDefinition.deleteFunction = function (objectId, index) {
                thirdBox.boxDefinition.content.splice(index, 1);
            };
            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(thirdBox);
            }
            
            var usersBox = {
            	boxTitle: 'global.users.list.boxTaskUsersToptitle', //boxTitle es opcional, si no se informa utiliza el valor por defecto global.users.list.boxUsersDefaultToptitle
            	permissions: { new: ['create_task'], view: ['create_task'] },
            	search: { 
            		placeholder: 'global.literals.placeholderSearchUsersBox', 
            		advanced: true,
            		additionalFilter: { searchBox: true },
            		saveSearch: undefined
            	},		
                content: [],
                new: {},
                isEdit: false,
                config: {},
                origin: 'users-task'
            };
            retBoxes.push(usersBox);

            var addressBox = {
            	permissions: {},
				search: { placeholder: 'global.literals.placeholderSearchAddressBox', advanced: true, saveSearch: undefined },
				new: {},
				content:  [],
				config: {},
				origin: 'address-task'
			};
            if(!TerritoryFactory.canCreateAddress()) {
            	addressBox.new = undefined;
            }
            retBoxes.push(addressBox);
            
            var taskBox = {
                    permissions: {},
                    search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: undefined },
                    content: [],
                    new: {},
                    config: {},
                    origin: 'task-task'
                };
            retBoxes.push(taskBox);
            
            return retBoxes;
        };

        factory.getEditTaskBox = function (type, enabled, entryType) {
            var box ;
            switch (type) {
                case 'dossierTransaction':
                    box = dossierTransactionTaskBox(enabled);
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                    	DialogsFactory.confirm('global.task.dossierTransaction.deleteMessage', 'global.task.dossierTransaction.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    break;
                case 'registerEntry':
                    if(entryType == 'INPUT') {
                        box = getBox('registerEntry', 'registerEntry-task-input', 'global.literals.reg_input', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', enabled, enabled, enabled, enabled, entryType, undefined, undefined, undefined, true);
                    }else{
                        box = getBox('registerEntry', 'registerEntry-task-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', enabled, enabled, enabled, enabled, entryType, undefined, undefined, undefined, true);
                    }
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                    	DialogsFactory.confirm(entryType == 'INPUT' ? 'global.task.registerEntryInput.deleteMessage' : 'global.task.registerEntryOutput.deleteMessage', 
                    			entryType == 'INPUT' ? 'global.task.registerEntryInput.deleteMessageConfirm' : 'global.task.registerEntryOutput.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    box.boxDefinition.typeAheadSelect = function(item){
                        if(item && item.value.indexOf('</i>') > 0){
                            item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                        }
                    };
                    break;
                case 'document':
                    box = getBox('document', 'document-task', 'global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', enabled, enabled, enabled, enabled);
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                    	DialogsFactory.confirm('global.task.document.deleteMessage', 'global.task.document.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    break;
                case 'comment':
                    box = commentTaskBox(['create_task'], enabled);
                    box.boxDefinition.saveSearch = function (comment) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                    	DialogsFactory.confirm('global.task.comment.deleteMessage', 'global.task.comment.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    break;

                case 'attachment':
                    box = attachmentTaskBox(['create_task'], enabled);
                    box.boxDefinition.saveSearch = function (comment) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                    	DialogsFactory.confirm('global.task.attachment.deleteMessage', 'global.task.attachment.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    break;
                case 'third':
                	box = getBox('third', 'third-task', 'global.literals.thirds', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers', enabled, enabled, enabled, enabled);
                    box.boxDefinition.saveSearch = function (third) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                    	DialogsFactory.confirm('global.task.third.deleteMessage', 'global.task.third.deleteMessageConfirm')
                        .then(function (data) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        }).catch(function (data) {
                        	//Empty
                        });
                    };
                    break;
            }

            return box;
        };

        factory.getNewTaskForm = function () {
            var entity = new AnnexaEntity('new-task', 'form', newTaskForm('annexa.tasks.createdTasks'), factory.taskBoxes()).setTitle('global.literals.basicInfo').setCancelState('annexa.tasks.createdTasks');
            return entity;
        };

        factory.showNewTaskModal = function (boxDefinition) {
            var date = new Date();
            var modal = angular.copy(TasksModals.taskNew);

            var entity = new AnnexaEntity(date.getTime() + '', 'modal', taskBoxNewForm(boxDefinition, modal), factory.taskBoxes());
            if(boxDefinition.entryType && boxDefinition.isEdit){
                if(boxDefinition.entryType == "INPUT" ){
                    entity.boxes[2].boxDefinition.content.push(RegFactory.regEntry);
                }else if(boxDefinition.entryType == "OUTPUT"){
                    entity.boxes[3].boxDefinition.content.push(RegFactory.regEntry);
                }
            }
            if(boxDefinition.isTramBox){
                boxDefinition.isTramBox.dossier = {id: TramFactory.Dossier.id, dossierNumber: TramFactory.Dossier.dossierNumber, endDate: TramFactory.Dossier.endDate, closed: TramFactory.Dossier.closed, subject: TramFactory.Dossier.subject, procedure:{id: ((TramFactory.Dossier.procedure)? TramFactory.Dossier.id : ""), procedureViewProfiles: ((TramFactory.Dossier.procedureViewProfiles)? TramFactory.Dossier.procedureViewProfiles : [])}};
                entity.boxes[1].boxDefinition.content.push(boxDefinition.isTramBox);
            }
            showModal(modal, entity);
        };

        var newTask = function(model, okstate, processFunc, profile, form) {
            var deferrend = $q.defer();

            TaskFactory.addTask(model, profile).then(function(data) {
                if(okstate) {
                    $state.transitionTo(okstate);
                }
                if(processFunc) {
                    processFunc(data, form);
                }
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(ErrorFactory.getErrorMessage('task', 'new', error));
            });
            return deferrend.promise;
        };

        var getRelatedTasks = function(relatedTasks){
            var relatedTasksAux = [];
            if(relatedTasks) {
                _.forEach(relatedTasks, function (item) {
                    relatedTasksAux.push({relatedTask: {id: item.id}});
                });
            }
            return relatedTasksAux;
        };
        
        var getThirds = function(thirds){
            var thirdsAux = [];
            if(thirds) {
                _.forEach(thirds, function (item) {
                	thirdsAux.push({third: {id: item.third.id}});
                });
            }
            return thirdsAux;
        };
        
        var getUsers = function(users){
            var usersAux = [];
            if(users) {
                _.forEach(users, function (item) {
                	usersAux.push({user: {id: item.user.id}});
                });
            }
            return usersAux;
        };

        var getAddresses = function(addresses){
            var addressesAux = [];
            if(addresses) {
                _.forEach(addresses, function (item) {
                	addressesAux.push({address: {id: item.address.id}});
                });
            }
            return addressesAux;
        };
        //endregion
        //region Notifications
        
        var getEvidencesBoxesModel = function (formExtra) {
            var indexDocuments = $linq(formExtra).indexOf("x => x.boxTitle == 'global.literals.evidences'");
            
            return {
                documents: indexDocuments != -1 ? formExtra[indexDocuments].content : []
            };
        };
        factory.evidenceFormFields = function(colClass, status, edit) {
            var form = new AnnexaFormly();

            var fields = {};

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong',
                extralabel: true
            };
            if(edit){
            	//Estat
            	fields.status = form.createField(
                        'status',
                        'annexaLabelRow',
                        colClass,
                        new AnnexaFormlyFieldLabelTemplateOptions('global.literals.state',
                            status.status[Language.getActiveColumn()]),
                        data);
            	//Data del canvi de la notificació
            	fields.createdDate = form.createField(
                        'createdDate',
                        'annexaDatepickerRow',
                        'col-sm-6',
                        new AnnexaFormlyFieldDatepickerTemplateOptions(
                            'text',
                            'global.literals.date',
                            false,
                            {
                                format: 'dd/MM/yyyy',
                                initDate: status.createdDate,
                                showWeeks: false,
                                startingDay: 1
                            }
                        ),
                        data,
                        "false",
                        new Date(status.createdDate)
                    );
            	//Hora del canvi de la notificació
            	fields.createdTime = form.createField(
                        'createdTime',
                        'annexaTimepickerRow',
                        'col-sm-6',
                        new AnnexaFormlyFieldDatepickerTemplateOptions(
                            'text',
                            'global.literals.time',
                            true                    
                        ),
                        data,
                        "false",
                        new Date(status.createdTime)
                    );
            	// Observacions
            	fields.observations = form.createField(
                        'observations',
                        'annexaTextAreaRow',
                        colClass,
                        new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'),
                        data,
                        "false",
                        status.observations
                    );
                        
            }else{
            	// Estat
                var field1 = form.createField(
                    'status',
                    'annexaSelectRow',
                    colClass,
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.state',
                        'id',
                        Language.getActiveColumn(),
                        status,
                        true
                    ),
                    data
                );
                
                fields.status = field1;
              //Data del canvi de la notificació
                var createDateField = form.createField(
                    'createdDate',
                    'annexaDatepickerRow',
                    'col-sm-6',
                    new AnnexaFormlyFieldDatepickerTemplateOptions(
                        'text',
                        'global.literals.date',
                        false,
                        {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1
                        }
                    ),
                    data,
                    "false",
                    new Date()
                );
                fields.createdDate = createDateField;
                
                //Hora del canvi de la notificació
                var createdTimeField = form.createField(
                    'createdTime',
                    'annexaTimepickerRow',
                    'col-sm-6',
                    new AnnexaFormlyFieldDatepickerTemplateOptions(
                        'text',
                        'global.literals.time',
                        true                    
                    ),
                    data,
                    "false",
                    new Date()
                );
                fields.createdTime = createdTimeField;
                
                // Observacions
                var fieldObservations = form.createField(
                    'observations',
                    'annexaTextAreaRow',
                    colClass,
                    new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'),
                    data
                );
                fields.observations = fieldObservations;
            }
            return fields;
        };
        factory.getEvidencesModalForm = function(modal, status, notificationThird, vmNotification, edit) {
            return evidencesModalForm(undefined, modal, status, notificationThird, vmNotification, edit);
        };
        
        var updateComponentStatus = function(vmNotification, data, modal, isDelete, index) {
            var indexThird = $linq(vmNotification.thirds).indexOf("x => x.id == " + data.third.id);

            if (indexThird != -1) {
                var indexStatus = $linq(vmNotification.thirds[indexThird].statuses).indexOf("x => x.id == " + data.id);

                if (indexStatus != -1) {
                    if(!isDelete) {
                        vmNotification.thirds[indexThird].statuses[indexStatus].createdDate = data.createdDate;
                        vmNotification.thirds[indexThird].statuses[indexStatus].observations = data.observations;
                        if(data.evidences){
                        vmNotification.thirds[indexThird].statuses[indexStatus].evidences = data.evidences;
                        }
                    } else {
                        vmNotification.thirds[indexThird].statuses[indexStatus].evidences.splice(index, 1);
                    }
                }
            }
			if(modal && modal.fieldsDefinition){
                var indexFieldEvidences = $linq(modal.fieldsDefinition).indexOf("x => x.id == 'evidences'");

                if (indexFieldEvidences != 1) {
                    if(!isDelete) {
                        modal.fieldsDefinition[indexFieldEvidences].data.value = data.evidences;
                    }
                }
			}
        };
        var updateDocumentsLists = function(vmNotification) {
        	RestService.findOne('Notification', vmNotification.id)
                .then(function(data) {
                	$rootScope.$broadcast('update_documents_lists', { notification: JSOG.decode(data)});
                })
        };
        
        var evidencesModalForm = function(boxDefinition, modal, status, notificationThird, vmNotification, edit) {
            var fields = factory.evidenceFormFields('col-sm-12', status, edit);

            var form = new AnnexaFormly();
            form.addGroup('row1', '', [fields.status], 'annexaRow');
            form.addGroup('row2', '', [fields.createdDate, fields.createdTime], 'annexaRow');
            form.addGroup('row3', '', [fields.observations], 'annexaRow');

            var submitNotification = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var notificationModel = {notification: {}};

                    if(form.model.row1) {
                        notificationModel.notification.status = form.model.row1.status;
                    }

                    if(form.model.row2) {
                        notificationModel.notification.createdDate = form.model.row2.createdDate;
                        notificationModel.notification.createdTime = form.model.row2.createdTime;
                    }

                    if(form.model.row3) {
                        notificationModel.notification.observations = form.model.row3.observations;
                    }

                    var boxesModel = getEvidencesBoxesModel(form.extra);
                    if(boxesModel.documents && boxesModel.documents.length>0){
                    	notificationModel.documents = [];
                        _.forEach(boxesModel.documents, function(documentEvidence){
                        	notificationModel.documents.push({document: {id: documentEvidence.document.id}, evidence: documentEvidence.document.idDocumentManager, name: documentEvidence.document.name, relationType: documentEvidence.relationType});
                        });
                    }
                    
                    if(!edit){
                    	var request = {
	                            third: { id: notificationThird.id },
	                            status: { id: notificationModel.notification.status },
	                            createdDate: notificationModel.notification.createdDate && notificationModel.notification.createdTime ?
	                                new Date(Date.UTC(
	                                    notificationModel.notification.createdDate.getFullYear(),
	                                    notificationModel.notification.createdDate.getMonth(),
	                                    notificationModel.notification.createdDate.getDate(),
	                                    notificationModel.notification.createdTime.getUTCHours(),
	                                    notificationModel.notification.createdTime.getUTCMinutes())) : undefined,
	                            observations: notificationModel.notification.observations ? notificationModel.notification.observations : undefined,
	                            evidences: notificationModel.documents
                            }
                        NotificationFactory.createNotificationThirdStatus(request)
                        .then(function(data) {
                            var indexThird = $linq(vmNotification.thirds).indexOf("x => x.id == " + data.third.id);

                            if(indexThird != -1) {
                            	vmNotification.thirds[indexThird].statuses.push(data);
                            }

                            vmNotification.globalStatus = data.third.notification.globalStatus;

                            $rootScope.$broadcast('docContentLoaded', { id: vmNotification.id, globalStatus: data.third.notification.globalStatus});
                            $rootScope.$broadcast('updateNotificationState', { id: vmNotification.id, globalStatus: data.third.notification.globalStatus});
                            $rootScope.$broadcast('reloadNotificationTable', {});
                            $rootScope.$broadcast('annexaBoxDossierNotificationsReloadTable',{});
                            updateDocumentsLists(vmNotification);
                            modal.close();
                        }).catch(function(error) {
                        	modal.alerts.push({msg: 'global.errors.unknownNew' });
                    })
                    }else{
                    	var request = {
                    			id: status.id,
                    			third: { id: status.third.id },
                                status: { id: status.status.id },
                                createdDate: notificationModel.notification.createdDate && notificationModel.notification.createdTime ?
                                        new Date(Date.UTC(
                                                notificationModel.notification.createdDate.getFullYear(),
                                                notificationModel.notification.createdDate.getMonth(),
                                                notificationModel.notification.createdDate.getDate(),
                                                notificationModel.notification.createdTime.getUTCHours(),
                                                notificationModel.notification.createdTime.getUTCMinutes())) : undefined,
                                observations: notificationModel.notification.observations ? notificationModel.notification.observations : undefined
                           }
                    	RestService.update('./api/reg/notification_third_status/' + status.id, request)
                        .then(function(data) {
                            updateComponentStatus(vmNotification, data);
                            modal.close();
                        }).catch(function (error) {
                            console.error(error);
                        })
                    	}
                }
            };

            form.addSubmitCallback(submitNotification);

            return form;
        };
        
        factory.getEvidencesBoxes = function(status) {
            return evidencesBoxes(status);
        };
        
        factory.getEditEvidencesBoxes = function(status, notification, scope){
        	var retBoxes = [];
        	 var addDocumentSave = function (document) {
         		NotificationFactory.updateEvidence(document, status.id).then(function(data) {
         			updateDocumentsLists(notification);
         			documentBox.content.length =0;
                	_.forEach(data.evidences, function(evidence){
                		 documentBox.content.push(evidence);
                	});
                	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: documentBox.content, relationType: 'DOCUMENT'});
                    $rootScope.loading(false);
                 }).catch(function (error) {
                	 documentBox.content.pop();
                     var err = dialogs.error(
                         $filter('translate')('DIALOGS_ERROR_MSG'),
                         $filter('translate')(ErrorFactory.getErrorMessage('evidence', 'update', error.data )),
                         {
                             animation: true,
                             backdrop: 'static',
                             keyboard: true,
                             size: ''
                         }
                     );
                 });
             };
             scope.$on('annexaBoxDocumentsDelete', function (event, args) {
                 if(args.origin && args.origin == 'documents-evidence' && documentBox.isEdit) {
                 	 NotificationFactory.deleteEvidence(status.id, args.removedId)
                         .then(function () {
                             //Empty
                         }).catch(function(error) {
                             console.error(error);
                     });
                 }
             });
        	var documentBox = {
                    permissions: {new: 'new_document', view: 'view_documents'},
                    search: {
                        placeholder: 'global.literals.placeholser_search_documents',
                        advanced: true,
                        saveSearch: addDocumentSave,
                        additionalFilter: {documentBoxNormalSearch: true}
                    },
                    boxTitle: 'global.literals.evidences',
                    closeBox: {value:true},
                    content: status.evidences,
                    new: { multiple: { scanner: true }},
                    config: { documentTitle: undefined, module: 'EVI'},
                    origin: 'documents-evidence',
                    isEdit:true
                }
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBox);
            }
        	return retBoxes;
            };

        var evidencesBoxes = function (status) {
            var retBoxes = [];
            var documentBoxEvidences = {
                    permissions: {new: 'new_document', view: 'view_documents'},
                    search: {
                        placeholder: 'global.literals.placeholser_search_documents',
                        advanced: true,
                        additionalFilter: {documentBoxNormalSearch: true}
                    },
                    boxTitle: 'global.literals.evidences',
                    closeBox: {value:true},
                    content: [],
                    new: { multiple: { scanner: true }},
                    config: { documentTitle: undefined, module: 'EVI'},
                    origin: 'documents-evidence',
                    isEdit:false
                }
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBoxEvidences);
            }
            
            return retBoxes;
        };
        
        //endregion
        return factory;
    }]);