/**
 * Created by osirvent on 09/11/2016.
 */
angular
    .module('annexaApp')
    .factory('AnnexaPermissionsFactory',['$rootScope', '$q', '$http', 'PermPermissionStore', '$state', 'GlobalDataFactory', 'DialogsFactory', '$filter', function ($rootScope, $q, $http, PermPermissionStore, $state, GlobalDataFactory, DialogsFactory, $filter) {
        var factory = {};

        //region Globals

        factory.allUserPermissions = []
        factory.globalUserPermissions = [];
        factory.enabledUser = undefined;

        //endregion

        //region Menú

        factory.getMenuPermissions = function () {
            var $d = $q.defer();

            var menuPromesas = [];

            angular.forEach($rootScope.allModules, function(module) {
                var httpSettings = {
                    url: './api/permission/module',
                    method: 'GET',
                    params: { module: module.id }
                };

                menuPromesas.push($http(httpSettings));
            });

            $q.all(menuPromesas)
                .then(function (data) {
                    $d.resolve(data);
                }).catch(function (error) {
                $d.reject(error);
            });

            return $d.promise;
        }

        factory.getMenuWithPermissions = function () {
            var $d = $q.defer();

            var menu = [];

            _.forEach($rootScope.allModules, function(module) {
                var perms = $linq(module.actionPermissions).select("x => x.keyId").toArray();
                switch(module.url) {
                    case 'annexa.reg.input':
                        perms.push('diligence_input_register');
                        break;
                    case 'annexa.reg.output':
                        perms.push('view_notifications');
                        break;
                    case 'annexa.tasks':
                        perms.push('view_tasks');
                        break;
                    case 'annexa.bi':
                    case 'annexa.bi.user':
                        perms.push('bi_user');
                        break;
                    case 'annexa.sign':
                    case 'annexa.sign.pending':
                        perms.push('sign');
                        break;
                    case 'annexa.tram':
                        perms.push('dossier_right_access');
                        break;
                    
                }
                module.permissions = perms;
                menu.push(module);
            });

            $d.$$resolve(menu);

            return $d.promise;
        }
        //endregion

        //region Carrega permisos usuari

        factory.enableSecurity = function (user, addAutomaticActionPermissions, activeMultipleRegisterOffice, registerEntryOffices, rolesSuperAdmin) {
            var permissions = [];

            var updatePermissionsByRegisterOffices = function() {
                var haveProfiles = function (profiles) {

                	if(factory.isSuperAdmin()) {
                		return true;
                	}
                    var userProfiles = $linq(user.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                    userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();

                    profiles = Array.isArray(profiles) ? profiles : [profiles];

                    var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

                    return intersectedProfiles.length != 0;
                }

                if(activeMultipleRegisterOffice) {
                    var indexNewInputEntryRegister = $linq(permissions).indexOf("x => x == 'new_input_entry_register'");
                    var indexEditInputEntryRegister = $linq(permissions).indexOf("x => x == 'edit_input_entry_register'");
                    var indexViewInputEntryRegister = $linq(permissions).indexOf("x => x == 'view_input_register'");
                    var indexNewOutputEntryRegister = $linq(permissions).indexOf("x => x == 'new_output_entry_register'");
                    var indexEditOutputEntryRegister = $linq(permissions).indexOf("x => x == 'edit_output_entry_register'");
                    var indexViewOutputEntryRegister = $linq(permissions).indexOf("x => x == 'view_output_register'");

                    if(indexNewInputEntryRegister != -1 || indexEditInputEntryRegister != -1 || indexViewInputEntryRegister != -1 ||
                       indexNewOutputEntryRegister != -1 || indexEditOutputEntryRegister != -1 || indexViewOutputEntryRegister != -1) {
                        var registerEntryOfficeProfiles = []

                        registerEntryOffices = registerEntryOffices ? registerEntryOffices : GlobalDataFactory.registerEntryOffices;

                        _.forEach(registerEntryOffices, function (item) {
                            _.forEach(item.profiles, function (value) {
                                registerEntryOfficeProfiles.push(value.profile);
                            });
                        });


                        if(!haveProfiles(registerEntryOfficeProfiles)) {
                            var fixedPerms = [];

                            _.forEach(permissions, function (item) {
                                if(!_.contains(['new_input_entry_register', 'edit_input_entry_register', 'new_output_entry_register', 'edit_output_entry_register'], item)) {
                                    fixedPerms.push(item);
                                }
                            });

                            permissions = fixedPerms;
                        }
                    }
                }
            }

            angular.forEach(user.userRoles, function (userRole) {
                angular.forEach(userRole.role.actionPermissions, function (perm) {
                    permissions.push(perm.actionPermission.keyId);
                });
            });

            if(addAutomaticActionPermissions) {
                permissions.push('diligence_input_register');
                permissions.push('view_notifications');
                permissions.push('view_tasks');
                permissions.push('bi_user');
                permissions.push('external_view_register_input');
                permissions.push('external_view_register_output');
                permissions.push('sign');
                permissions.push('dossier_right_access');
            }

            updatePermissionsByRegisterOffices();

            factory.globalUserPermissions = angular.copy(permissions);

            angular.forEach(user.userProfiles, function (userProfile) {
                angular.forEach(userProfile.roles, function (role) {
                    angular.forEach(role.actionPermissions, function (roleActionPermission) {
                        var exist = $linq(permissions).count("x => x == '" + roleActionPermission.actionPermission.keyId + "'");
                        if(exist == 0) {
                            switch (roleActionPermission.actionPermission.keyId) {
                                case 'view_documents':
                                    permissions.push('view_documents');
                                    break;
                                case 'new_document':
                                    permissions.push('new_document');
                                    break;
                                case 'process_dossier':
                                    permissions.push('process_dossier');
                                    break;
                                case 'view_dossier':
                                    permissions.push('view_dossier');
                                    break;
                                case 'start_dossier':
                                    permissions.push('start_dossier');
                                    break;
                                case 'create_task':
                                    permissions.push('create_task');
                                    break;
                                case 'see_all_dossier':
                                    permissions.push('see_all_dossier');
                                    break;
                            }
                        }
                    })
                })
            });
            
            if(rolesSuperAdmin && rolesSuperAdmin.length > 0) {
            	angular.forEach(rolesSuperAdmin, function (perm) {
                    permissions.push(perm.keyId);
                });
            }

            factory.allUserPermissions = angular.copy(permissions);
            factory.enabledUser = angular.copy(user);

            PermPermissionStore.defineManyPermissions(permissions, function (permissionName, transitionProperties) {
                return true;
            });

            var getAuthorizeDossierState = function (toStateName) {
                var authorizedState;

                switch(toStateName) {
                    case 'annexa.tram.pending':
                        if(factory.haveSinglePermission('view_dossier') || factory.haveSinglePermission('see_all_dossier')) {
                            authorizedState = 'annexa.tram.view';
                        } else if(factory.haveSinglePermission('start_dossier')) {
                            authorizedState = 'annexa.tram.start';
                        } else if(factory.haveSinglePermission('dossier_right_access')) {
                            authorizedState = 'annexa.tram.dossierRightAccess';
                        }
                        break;
                    case 'annexa.tram.view':
                        if(factory.haveSinglePermission('process_dossier')) {
                            authorizedState = 'annexa.tram.pending';
                        } else if(factory.haveSinglePermission('start_dossier')) {
                            authorizedState = 'annexa.tram.start';
                        } else if(factory.haveSinglePermission('dossier_right_access')) {
                            authorizedState = 'annexa.tram.dossierRightAccess';
                        }
                        break;
                    case 'annexa.tram.start':
                        if(factory.haveSinglePermission('process_dossier')) {
                            authorizedState = 'annexa.tram.pending';
                        } else if(factory.haveSinglePermission('view_dossier') || factory.haveSinglePermission('see_all_dossier')) {
                            authorizedState = 'annexa.tram.view';
                        } else if(factory.haveSinglePermission('dossier_right_access')) {
                            authorizedState = 'annexa.tram.dossierRightAccess';
                        }
                        break;
                    case 'annexa.tram.dossierRightAccess':
                        if(factory.haveSinglePermission('process_dossier')) {
                            authorizedState = 'annexa.tram.pending';
                        } else if(factory.haveSinglePermission('start_dossier')) {
                            authorizedState = 'annexa.tram.start';
                        }
                        break;
                    case 'annexa.tram.addDossierRightAccess':
                        if(factory.haveSinglePermission('process_dossier')) {
                            authorizedState = 'annexa.tram.pending';
                        } else if(factory.haveSinglePermission('start_dossier')) {
                            authorizedState = 'annexa.tram.start';
                        }else{
                        	authorizedState = 'annexa.dashboard';
                        }
                        break;
                    case 'annexa.tram.manageDossierRightAccess':
                        if(factory.haveSinglePermission('process_dossier')) {
                            authorizedState = 'annexa.tram.pending';
                        } else if(factory.haveSinglePermission('start_dossier')) {
                            authorizedState = 'annexa.tram.start';
                        }else{
                        	authorizedState = 'annexa.dashboard';
                        }
                        break;
                    case 'annexa.archive.finally':
                        if(factory.haveSinglePermission('archive_finally')) {
                            authorizedState = 'annexa.archive.finally';
                        } else if(factory.haveSinglePermission('archive_close')) {
                            authorizedState = 'annexa.archive.close';
                        } else if(factory.haveSinglePermission('archive_transfer')) {
                            authorizedState = 'annexa.archive.transfer';
                        } else if(factory.haveSinglePermission('archive_rejected')) {
                            authorizedState = 'annexa.archive.rejected';
                        } else if(factory.haveSinglePermission('archive_archive')) {
                            authorizedState = 'annexa.archive.archive';
                        }else{
                        	authorizedState = 'annexa.dashboard';
                        }
                        break;
                    default:
                    	authorizedState = 'annexa.dashboard';
                    	break;
                    	
                }

                return authorizedState;
            }

            $rootScope.$on('$stateChangePermissionDenied', function(event, toState, toParams, options) {
                event.preventDefault();
                switch(toState.name) {
                    case 'annexa.reg.input.entries':
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.reg.input.diligences'){
                    		$state.go('annexa.reg.input.diligences', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.reg.input.diligences');
                    	}
                        break;
                    case 'annexa.reg.output.entries':
                        var exist = $linq(factory.allUserPermissions).count("x => x == 'view_notifications' || x == 'view_all_notifications'");
                        if(exist > 0) {
                        	if($state.current && $state.current.name && $state.current.name == 'annexa.reg.output.notifications'){
                        		$state.go('annexa.reg.output.notifications', {}, {reload:true});
                        	}else{
                        		$state.go('annexa.reg.output.notifications');
                        	}
                        }else{
                        	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                        	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                        		$state.go('annexa.dashboard', {}, {reload:true});
                        	}else{
                        		$state.go('annexa.dashboard');
                        	}
                        }
                        break;
                    case 'annexa.reg.input.entries.edit':
                    case 'annexa.reg.output.entries.edit':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.register'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                    	break;
                    case 'annexa.reg.input.diligences.edit':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.diligence'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                    	break;
                    	
                    case 'annexa.tram.start':
                    case 'annexa.tram.pending':
                    case 'annexa.tram.view':
                    case 'annexa.tram.dossierRightAccess':
                        var authState = getAuthorizeDossierState(toState.name);

                        if(authState && authState == 'annexa.dashboard') {
                        	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                        	if($state.current && $state.current.name && $state.current.name == authState){
                        		$state.go(authState, {}, {reload:true});
                        	}else{
                        		$state.go(authState);
                        	}
                        }else if(authState){
                        	if($state.current && $state.current.name && $state.current.name == authState){
                        		$state.go(authState, {}, {reload:true});
                        	}else{
                        		$state.go(authState);
                        	}
                        }
                        break;
                        
                    case 'annexa.tram.view.viewdossier':
                    case 'annexa.tram.dossierRightAccess.viewdossier':
                    case 'annexa.tram.pending.viewdossier':
                    case 'annexa.tram.responsable.viewdossier':
                    
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.dossier', {num:""}), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                     	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                     		$state.go('annexa.dashboard', {}, {reload:true});
                     	}else{
                     		$state.go('annexa.dashboard');
                     	}
                        break;
                    case 'annexa.sec.proposals':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    case 'annexa.sec.proposals.edit':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.proposal'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    case 'annexa.sec.sessions':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    case 'annexa.sec.sessions.view':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.sessions'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    case 'annexa.querys.executeQuery':
                        if(factory.havePermission(['si_report_execute'])){
                        	if($state.current && $state.current.name && $state.current.name == 'annexa.querys.executeReport'){
                        		$state.go('annexa.querys.executeReport', {}, {reload:true});
                        	}else{
                        		$state.go('annexa.querys.executeReport');
                        	}
                        }else{
                        	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                            $state.go('annexa.dashboard', {}, { reload: 'annexa.dashboard' });
                        }
                        break;
                    case 'annexa.querys.executeReport':
                        if(factory.havePermission(['si_query_execute'])){
                        	if($state.current && $state.current.name && $state.current.name == 'annexa.querys.executeQuery'){
                        		$state.go('annexa.querys.executeQuery', {}, {reload:true});
                        	}else{
                        		$state.go('annexa.querys.executeQuery');
                        	}
                        }else{
                        	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                            $state.go('annexa.dashboard', {}, { reload: 'annexa.dashboard' });
                        }
                        break;
                    case 'annexa.archive.finally':
                    case 'annexa.archive.close':
                    case 'annexa.archive.transfer':
                    case 'annexa.archive.rejected':
                    case 'annexa.archive.archive':
                        var authState = getAuthorizeDossierState(toState.name);

                        if(authState && authState == 'annexa.dashboard') {
                        	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                        	if($state.current && $state.current.name && $state.current.name == authState){
                        		$state.go(authState, {}, {reload:true});
                        	}else{
                        		$state.go(authState);
                        	}
                        }else if(authState){
                        	if($state.current && $state.current.name && $state.current.name == authState){
                        		$state.go(authState, {}, {reload:true});
                        	}else{
                        		$state.go(authState);
                        	}
                        }
                        break;
                    case 'annexa.thirds.edit':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.third'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    case 'annexa.doc.documents.view':
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.document'), $filter('translate')('global.permissions.literals.DIALOGS_PERM_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                    default:
                    	DialogsFactory.error($filter('translate')('global.permissions.errors.general'), $filter('translate')('global.permissions.literals.DIALOGS_ACCESS_TITLE'));
                    	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
                        break;
                }
            });

            $rootScope.$on('$stateChangePermissionStart', function (event, toState, toParams, options) {
                var a = 0;
            });
        }

        //endregion

        //region Avaluació de permisos
        
        factory.isSuperAdmin = function() {
        	if($rootScope && $rootScope.LoggedUser) {
            	var loggedUser = $rootScope.LoggedUser;
            	if($rootScope.app.configuration.id_users_superadmin && $rootScope.app.configuration.id_users_superadmin.id){
            		var idsSuperAdmin = $rootScope.app.configuration.id_users_superadmin.id;
            		return _.contains(idsSuperAdmin, loggedUser.id);
            	}
        	}
        	return false;
        }

        //region Sense perfil

        factory.listPermissions = function (global) {
            if(global) {
                return factory.globalUserPermissions;
            } else {
                return factory.allUserPermissions;
            }
        }

        factory.havePermission = function(permission, global) {
        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            if(angular.isString(permission)) {
                return factory.haveSinglePermission(permission, global);
            } else {
                return factory.haveOnePermission(permission, global);
            }
        }

        factory.haveSinglePermission = function(permission, global) {
        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            var existPermission = $linq(factory.listPermissions(global)).count("x => x == '" + permission + "'");

            return existPermission != 0;
        }

        factory.haveOnePermission = function (permissions, global) {
        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            var intersect = $linq(factory.listPermissions(global)).intersect(permissions).toArray();

            return intersect.length != 0;
        }

        factory.notHaveOnePermission = function (permissions,global) {
            var intersect = $linq(factory.listPermissions(global)).intersect(permissions).toArray();

            return intersect == 0;
        }

        //endregion

        //region Amb perfil

        //Métode només a la factoria
        factory.haveSinglePermissionFromProfile = function(permission, profile_id) {
            var ret = false;
            var userProfiles = [];

        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            
            if(Array.isArray(profile_id)) {
                userProfiles = $linq(factory.enabledUser.userProfiles).intersect(profile_id, "(x, y) => x.profile.id == y.profile.id").toArray();
            } else {
                var singleUserProfile = $linq(factory.enabledUser.userProfiles).singleOrDefault({id: -1}, "x => x.profile.id == " + profile_id);
                if(singleUserProfile.id != -1) {
                    userProfiles.push(singleUserProfile);
                }
            }



            _.forEach(userProfiles, function(userProfile) {
                _.forEach(userProfile.roles, function (role) {
                    var exist = $linq(role.actionPermissions).count("x => x.actionPermission.keyId == '" + permission + "'");
                    if(exist != 0) {
                        ret = true;
                    }
                })
            })

            return ret;
        }

        //Métode només a la factoria
        factory.haveSinglePermissionFromProfiles = function (permission) {
            var have = false;

        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            
            angular.forEach(factory.enabledUser.userProfiles, function (userProfile) {
                have = have || factory.haveSinglePermissionFromProfile(permission, userProfile.profile.id);
            });

            return have;
        }


        factory.haveSinglePermissionDual = function (permission, profile_id) {
        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            if(!factory.haveSinglePermission(permission)){
                if(profile_id) {
                    return factory.haveSinglePermissionFromProfile(permission, profile_id);
                } else {
                    return factory.haveSinglePermissionFromProfiles(permission);
                }
            } else {
                if(profile_id) {
                    var checkProfiles = Array.isArray(profile_id) ? $linq(profile_id).select("x => x.profile").toArray() : [profile_id];

                    return factory.haveProfile(checkProfiles);
                }

                return true;
            }
        }

        //endregion

        //endregion

        //region Avaluació de perfils

        factory.haveProfile = function (profiles) {
        	if(factory.isSuperAdmin()) {
        		return true;
        	}
            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();

            profiles = Array.isArray(profiles) ? profiles : [profiles];

            var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

            return intersectedProfiles.length != 0;
        }

        factory.filterByUserProfiles = function (collection, profileProperty) {
            var userProfiles = $linq(factory.enabledUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();

            var result = [];
            angular.forEach(collection, function (item) {
                var existProfile = $linq(userProfiles).count("x => x.id == " + item[profileProperty].id);
                if(existProfile != 0) {
                    result.push(item);
                }
            });

            return result;
        }

        factory.getSingleProfileDual = function(permission, profile_id, global){
            var profiles = factory.getSingleProfile(permission, global);

            if(profiles && profiles.length > 0) {
                return profiles;
            } else {
                if(profile_id) {
                    return factory.getProfilesFromSinglePermission(permission, profile_id);
                } else {
                    return factory.getProfilesFromSinglePermissions(permission);
                }
            }
        }

        factory.getSingleProfile = function(permission, global) {
            var profiles = [];
            if(factory.haveSinglePermission(permission, global)){
                angular.forEach(factory.enabledUser.userProfiles, function (userProfile) {
                   profiles.push(userProfile.profile);
                });
            }
            return profiles;
        }


        factory.getProfilesFromSinglePermissions = function (permission) {
            var profiles = [];
            angular.forEach(factory.enabledUser.userProfiles, function (userProfile) {
                if(factory.haveSinglePermissionFromProfile(permission, userProfile.profile.id)){
                    profiles.push(userProfile.profile);
                };
            });
            return profiles;
        }

        factory.getProfilesFromSinglePermission = function(permission, profile_id) {
            var profiles = [];
            var userProfile = $linq(factory.enabledUser.userProfiles).singleOrDefault({id: -1}, "x => x.profile.id == " + profile_id);

            if(userProfile.id != -1) {
                if(factory.haveSinglePermissionFromProfile(permission, userProfile.profile.id)){
                    profiles.push(userProfile.profile);
                }
            }

            return profiles;
        }


        //endregion

        //region Expedients
        
        factory.canViewOrTramitTransaction = function (transaction, dossier) {
            var dossierObj = dossier ? dossier : ((transaction)?transaction.dossier : undefined);
            if(dossierObj) {
                if (dossierObj.responsibleUser.id == $rootScope.LoggedUser.id) {
                    return true;
                }

                var responsibleProfiles = factory.filterByUserProfiles(dossierObj.dossierResponsibleProfiles, 'profile');

                if (responsibleProfiles.length > 0) {
                    return true;
                }

                var viewProfiles = factory.filterByUserProfiles(dossierObj.procedure.procedureViewProfiles, 'profile');

                if (viewProfiles.length > 0) {
                    return true;
                }

                var processProfiles = factory.filterByUserProfiles(transaction.processProfiles, 'profile');

                if (processProfiles.length > 0) {
                    return true;
                }
            }
            return false;
        }
        
        //endregion

        return factory;
    }]);