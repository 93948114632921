/**
 * Created by osirvent on 26/01/2016.
 */
angular
    .module('annexaApp')
    .factory('EmailFactory',['$http', 'api', '$q', function ($http, api, $q) {
        return {
            sendEmail: function(email,doc,attachments,multipleTo,multiple,multipleCC,multipleCCBool,multipleCCO,multipleCCOBool) {
                var deferred = $q.defer();
                var send = {
                    to:email.to,
                    cc:email.cc,
                    cco:email.cco,
                    subject:email.subject,
                    text:email.text,
                    idUser:doc.idUser,
                    docs:[],
                    attachments: attachments,
                    multipleTo:multipleTo,
                    multiple:multiple,
                    multipleCC:multipleCC,
                    multipleCCBool:multipleCCBool,
                    multipleCCO:multipleCCO,
                    multipleCCOBool:multipleCCOBool,
                    dossier:doc.dossier,
                    transaction:doc.transaction
                };
                if(Array.isArray(doc.idDoc)){
                    send.docs = doc.idDoc;
                }else{
                    send.docs.push(doc.idDoc);
                }

                $http({
                    url:  './api/email',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
        };
    }]);