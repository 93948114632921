/**
 * Created by osirvent on 27/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMInspectorFactory', ['$filter', 'ABMInspectorHelperFactory', 'Language', '$timeout', 'ABMShapesFactory', '$rootScope', 'ABMModelFactory', 'CommonAdminModals', 'AnnexaModalFactory', 'dialogs', 'CommonService', 'TramNewFactory', 'GlobalDataFactory', function($filter, ABMInspectorHelperFactory, Language, $timeout, ABMShapesFactory, $rootScope, ABMModelFactory, CommonAdminModals, AnnexaModalFactory, dialogs, CommonService, TramNewFactory, GlobalDataFactory) {
        var factory = {};

        factory.graph = undefined;
        factory.scope = undefined;
        factory.internalControl = false;
        if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
        	factory.internalControl = true;
        }
        factory.isActiveCouncillor = false;
        if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
        	factory.isActiveCouncillor = true;
		}
        
        var optnhos = $linq(GlobalDataFactory.proposalTypes).where("x => x.proposalTypeSubtype =='PROPOSAL'").select("x => x.id").toArray();
        factory.organsProposalTypesNotHiddenOrgans = ((optnhos && optnhos.length)?optnhos:[]);

        //region General

        factory.InitializeInspectorEvents = function(graph, scope) {
            factory.graph = graph;
            factory.scope = scope;
            if(factory.scope && factory.scope.model  && factory.scope.model.transactions){
                var trans = [];
                for(var key in factory.scope.model.transactions){
                    if(factory.scope.model.transactions[key].tramitationType == 'INITIAL'){
                        trans.push({id:factory.scope.model.transactions[key].graphNodeId, language1:factory.scope.model.transactions[key].language1, language2:factory.scope.model.transactions[key].language2, language3:factory.scope.model.transactions[key].language3});
                    }
                }
				//Afegim altres tipus Responsable de l'expedient i tramit anterior
				trans.push({id:'RESPONSIBLE_PROFILE_DOSSIER', language1: $filter('translate')('global.literals.responsibleProfileDossier'), language2:$filter('translate')('global.literals.responsibleProfileDossier'), language3:$filter('translate')('global.literals.responsibleProfileDossier')});
				trans.push({id:'LAST_DOSSIER_TRANSACTION', language1: $filter('translate')('global.literals.lastDossierTransaction'), language2:$filter('translate')('global.literals.lastDossierTransaction'), language3:$filter('translate')('global.literals.lastDossierTransaction')}); 
                factory.scope.options.userStateInheritance = trans;
            }
            $rootScope.$on("BPMInspectorChange", function (event, args) {
                if(!args.origin) {
                    procedureEventChange(args.field, args.value);
                } else {
                    switch(args.origin.type) {
                        case 'transaction':
                            transactionEventChange(args.origin.id, args.field, args.value);
                            break;
                        default:
                            procedureEventChange(args.field, args.value);
                    }
                }
            });
            $rootScope.$on('AnnexaProcedureCustomFieldUpdated', function(event, args) {
				if(args && args.item) {
					var cf = JSOG.decode(args.item);
					if(cf.customField && !cf.deleted) {
						if($linq(factory.scope.options.customFieldProfile).contains(cf.customField.id, function(x,y){
   	   						if(x && x.customFieldId && y == x.customFieldId) { return true; } else { return false; } 
   	   					})) {
							var updateCF = $linq(factory.scope.options.customFieldProfile).firstOrDefault(undefined, "x => x.customFieldId == " + cf.customField.id);
        					if(updateCF) {
        						updateCF.id = cf.id;
        						updateCF.literal = cf.customField[Language.getActiveColumn()];
        						updateCF.customFieldId = cf.customField.id;
        					}
						} else {
							factory.scope.options.customFieldProfile.push({
			                	id: cf.id,
			                	literal: cf.customField[Language.getActiveColumn()],
			                	customFieldId: cf.customField.id
			                });
						}
					} else if(cf.deleted) {
						var indexDeleted = $linq(factory.scope.options.customFieldProfile).indexOf("x => x.customFieldId == " + cf.customField.id);
						if(indexDeleted >= 0) {
							factory.scope.options.customFieldProfile.splice(indexDeleted, 1);
						}
					}
					factory.scope.options.customFieldUser = factory.scope.options.customFieldProfile;
				}
	        });
        }

        //endregion

        //region Events

        var procedureEventChange = function(field, value) {
            switch(field) {
                case 'showClassification':
                    if(value == 'DISABLED') {
                        angular.element('#procedureClassifications').hide();
                    } else {
                        angular.element('#procedureClassifications').show();
                    }
                    break;
                case 'operationsActive':
                    if(value) {
                        angular.element('#operationTypesBPMField').show();
                    } else {
                        angular.element('#operationTypesBPMField').hide();
                        factory.scope.model.operationTypes = undefined;
                    }
                    break;
                case 'inspectionIntervention':
                    if(value && factory.internalControl) {
                        angular.element('#inspectionInterventionTypeBPMField').show();
                    } else {
                        angular.element('#inspectionInterventionTypeBPMField').hide();
                        factory.scope.model.inspectionInterventionType = undefined;
                    }
                    break;
                case 'proposalType':
                    if(_.contains(factory.organsProposalTypesNotHiddenOrgans, value)) {
                        angular.element('#organsAgreementBPMField').show();
                        angular.element('#organsOpinionBPMField').show();
                        if(factory.isActiveCouncillor){
                        	angular.element('#organsAgreementDelegationTypeBPMField').show();
                        }else{
                        	angular.element('#organsAgreementDelegationTypeBPMField').hide();
                        }
                    } else {
                        angular.element('#organsAgreementBPMField').hide();
                        angular.element('#organsOpinionBPMField').hide();
                        angular.element('#organsAgreementDelegationTypeBPMField').hide();
                        if(factory.scope.model.organsOpinion){
                        	factory.scope.model.organsOpinion.length = 0;
                        }else{
                        	factory.scope.model.organsOpinion = [];
                        }
                        factory.scope.model.organsAgreement = undefined;
                        factory.scope.model.organsAgreementDelegationType = undefined;
                    }
                    break;
            }
        };
        var tramPhaseMult = undefined;
        var transactionEventChange = function(cellId, field, value) {
            if(factory.graph && factory.scope.model.transactions[cellId]) {
                var cell = factory.graph.getCell(cellId);
                
                if(cell) {
                    switch (field) {
                        case 'assignationType':
                            ABMShapesFactory.setCellType(cell, value, ABMModelFactory.getTramitationType(cell));
                            var elements = document.getElementsByClassName("group");
                            var element = undefined;
                            if(elements && elements.length > 0){
                            	_.forEach(elements, function(elem){
                            		var elemAux = angular.element(elem);
                            		if(elemAux && elemAux.data && elemAux.data("name") == "permissions"){
                            			element = elemAux;
                            		}
                            	});
                            }
                            if(value == ABMModelFactory.AT_NONGUIDED) {
                                angular.element('#subprocedureNameBPMField').show();
                                angular.element('#subprocedureBPMField').hide();
                                if(element){
                                	angular.element(element).hide();
                                }
                            } else {
                                angular.element('#subprocedureBPMField').show();
                                angular.element('#subprocedureNameBPMField').hide();
                                if(element){
                                	angular.element(element).show();
                                }
                            }
                            break;
                        case 'tramitationType':
                            ABMShapesFactory.setCellType(cell, ABMModelFactory.getAssignationType(cell), value);
                            break;
                        case 'subprocedure':
                            var selected = $linq(factory.scope.options.subprocedure).singleOrDefault(undefined, "x => x.id == " + value);

                            if(selected) {
                               ABMShapesFactory.setCellContent(cell, selected[Language.getActiveColumn()]);
                            }
                            break;
                        case 'subprocedureName':
                            ABMShapesFactory.setCellContent(cell, Language.getActiveColumn());
                            break;
                        case 'transactionType':
                            var transType = $linq(factory.scope.options.transactionType).singleOrDefault(undefined, "x => x.id == " + value);
                            if(transType){
                                ABMModelFactory.setTransactionModel(cell, 'maxDays', transType.days);
                                ABMModelFactory.setTransactionModel(cell, 'expireType', transType.expireType);
                                ABMModelFactory.setTransactionModel(cell, 'daysComputeGlobal', ((transType.countDays)?'YES':'NO'));
                                if(transType.phases.length == 1){
                                	ABMModelFactory.setTransactionModel(cell, 'phase', transType.phases[0].tramitationPhase.id);
                                }else if(transType.phases.length > 1){
                                	ABMModelFactory.setTransactionModel(cell, 'phase', undefined);
                                }else{
                                	ABMModelFactory.setTransactionModel(cell, 'phase', undefined);
                                }
                            }
                            break;
                        case 'assignationTypeTram':
                        	if(factory.scope.model.transactions[cellId] && factory.scope.model.transactions[cellId].graphNodeType != ABMShapesFactory.SN && factory.scope.model.transactions[cellId].graphNodeType != ABMShapesFactory.SFN){
	                            if(value == ABMModelFactory.AT_NONGUIDED){
	                                ABMModelFactory.setTransactionModel(cell, 'assignationType', ABMModelFactory.AT_NONGUIDED);
	                                ABMShapesFactory.setCellType(cell, ABMModelFactory.AT_NONGUIDED, ABMModelFactory.getTramitationType(cell));
	                            }else{
	                                ABMModelFactory.setTransactionModel(cell, 'assignationType', ABMModelFactory.AT_GUIDED);
	                                ABMShapesFactory.setCellType(cell, ABMModelFactory.AT_GUIDED, ABMModelFactory.getTramitationType(cell));
	                            }
							}
                            if(value == ABMModelFactory.AT_GUIDED){
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', false);
                                angular.element('#processProfilesBPMField').show();
                				angular.element('#useCustomFieldProfileBPMField').show();
                				angular.element('#customFieldProfileBPMField').hide();
                				angular.element('#useCustomFieldUserBPMField').show();
                				angular.element('#customFieldUserBPMField').hide();
                                angular.element('#userStateInheritanceBPMField').hide();
                            }else if(value == ABMModelFactory.AT_INHERITED){
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', false);
                                angular.element('#processProfilesBPMField').hide();
                				angular.element('#useCustomFieldProfileBPMField').hide();
                				angular.element('#customFieldProfileBPMField').hide();
                				angular.element('#useCustomFieldUserBPMField').hide();
                				angular.element('#customFieldUserBPMField').hide();
                                angular.element('#userStateInheritanceBPMField').show();
                            }else{
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', true);
                                angular.element('#processProfilesBPMField').hide();
                				angular.element('#useCustomFieldProfileBPMField').hide();
                				angular.element('#customFieldProfileBPMField').hide();
                				angular.element('#useCustomFieldUserBPMField').hide();
                				angular.element('#customFieldUserBPMField').hide();
                                angular.element('#userStateInheritanceBPMField').hide();
                            }
                            break;
                        case 'useCustomFieldProfile':
                        	if(value == 'YES') {
                        		var selectableCF = [];
                                _.forEach(factory.scope.model.customFields, function (value, key) {
                                	selectableCF.push({
                                        id: value.id,
                                        literal: value.customField[Language.getActiveColumn()],
                                        customFieldId: value.customField.id
                                    });
                                });
                                factory.scope.options.customFieldProfile = selectableCF;
                                angular.element('#customFieldProfileBPMField').show();
                        	} else {
                				angular.element('#customFieldProfileBPMField').hide();
                        	}
                            break;
                        case 'useCustomFieldUser':
                        	if(value == 'YES') {
                        		var selectableCF = [];
                                _.forEach(factory.scope.model.customFields, function (value, key) {
                                	selectableCF.push({
                                        id: value.id,
                                        literal: value.customField[Language.getActiveColumn()],
                                        customFieldId: value.customField.id
                                    });
                                });
                                factory.scope.options.customFieldUser = selectableCF;
                                angular.element('#customFieldUserBPMField').show();
                        	} else {
                				angular.element('#customFieldUserBPMField').hide();
                        	}
                            break;
                    }
                }

                factory.scope.model.transactions[cellId].graphNodeType = ABMShapesFactory.getShapeType(cell);
            }
        }

        //endregion

        //region Inspector Creation

        factory.ElementInspector = function(cell, scope, $inspectorHolder) {
            if(_.contains([ABMShapesFactory.TI, ABMShapesFactory.TEN, ABMShapesFactory.TEF, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.TI, ABMShapesFactory.SN, ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell))) {
                factory.TransactionInspector(cell, scope, $inspectorHolder);
            }else if(cell.get('type') === 'bpmn.Flow'){
				factory.FlowInspector(cell, scope, $inspectorHolder);
			} else {
                factory.GatewayInspector(cell, scope, $inspectorHolder);
            }
        };

        //region Procedure

        factory.ModelerInspector = function(cell, scope, $inspectorHolder) {
            $timeout(function(){
                joint.ui.Inspector.create($inspectorHolder, {
                    cell: cell,
                    inputs: {
                        procedureType: { type: 'UISelect', label: 'global.literals.type', group: 'basicinfo', index: 1, scope: scope, field: 'procedureType', required: true, showClear: false },
                        language1: { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 2, scope: scope, field: '', required: true },
                        descriptionLanguage1: { type: 'InputLanguage', label: 'global.literals.description', group: 'basicinfo', index: 3, scope: scope, field: 'descriptionLanguage', required: true },
                        acronym: { type: 'InputText', label: 'global.literals.acronym', group: 'basicinfo', index: 4, scope: scope, maxlength: 20, field: 'acronym', required: true },
                        showSubject: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.showSubject', group: 'basicinfo', index: 5, scope: scope, field: 'showSubject', required: true, showClear: false },
                        maxDays: { type: 'InputNumber', label: 'global.literals.expire', group: 'basicinfo', index: 6, scope: scope, field: 'maxDays', min: 1},
                        expireType: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.expireType', group: 'basicinfo', index: 7, scope: scope, field: 'expireType', required: true, showClear: false },
                        expirationDaysNotification: { type: 'InputNumber', label: 'global.literals.expirationDaysNotification', group: 'basicinfo', index: 8, scope: scope, field: 'expirationDaysNotification', min: 1},
                        allowUserChangeExpirationDaysNotification: { type: 'UISelect', label: 'global.literals.allowUserChangeExpirationDaysNotification', group: 'basicinfo', index: 9, scope: scope, field: 'allowUserChangeExpirationDaysNotification', required: true, showClear: false },
                        allowHaveTerritorialAddress: { type: 'UISelect', label: 'global.literals.allowHaveTerritorialAddress', group: 'basicinfo', index: 10, scope: scope, field: 'allowHaveTerritorialAddress', required: true, showClear: false },
                        accessLevelNotification: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.accessLevelNotification', group: 'basicinfo', index: 11, scope: scope, field: 'accessLevelNotification', required: false, showClear: false },
                        daysBeforeAlertDossier: { type: 'InputNumber', label: 'global.commonAdmin.modal.unguidedProcedure.daysBeforeAlertDossier', group: 'basicinfo', index: 12, scope: scope, field: 'daysBeforeAlertDossier', min: 1},
                        daysBeforeAlertTransaction: { type: 'InputNumber', label: 'global.commonAdmin.modal.unguidedProcedure.daysBeforeAlertTransaction', group: 'basicinfo', index: 13, scope: scope, field: 'daysBeforeAlertTransaction', min: 1},
                        allowUserChangeMaxDays: { type: 'UISelect', label: 'global.literals.allowUserChangeMaxDays', group: 'basicinfo', index: 14, scope: scope, field: 'allowUserChangeMaxDays', required: true, showClear: false },
                        active: { type: 'UISelect', label: 'global.literals.active', group: 'basicinfo', index: 15, scope: scope, field: 'active', required: true, showClear: false },
                        procedureRoleInterested: { type: 'UISelectMultiple', label: 'global.literals.roleInterestedProcedure', group: 'basicinfo', index: 16, scope: scope, field: 'procedureRoleInterested', required: false, labelProp: Language.getActiveColumn() },
                        operationsActive: { type: 'UISelect', label: 'global.literals.activateOperationModule', group: 'basicinfo', index: 17, scope: scope, field: 'operationsActive', required: true, showClear: false, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?false:true) },
                        operationTypes: { type: 'UISelectMultiple', label: 'global.literals.operationTypes', group: 'basicinfo', index: 18, scope: scope, field: 'operationTypes', required: false, labelProp: Language.getActiveColumn(), hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active && factory.scope && factory.scope.model && factory.scope.model.operationsActive == 'YES')?false:true) },
                        allowManualDossierNumbering: { type: 'UISelect', label: 'global.literals.allowManualDossierNumbering', group: 'basicinfo', index: 19, scope: scope, field: 'allowManualDossierNumbering', required: true, showClear: false },
                        forceHaveThird: { type: 'UISelect', label: 'global.literals.forceHaveThird', group: 'basicinfo', index: 20, scope: scope, field: 'forceHaveThird', required: true, showClear: false },
                        procedureDocumentTypeCanSee: { type: 'UISelectMultiple', label: 'global.literals.documentTypeCanSeeProcedure', group: 'basicinfo', index: 21, scope: scope, field: 'procedureDocumentTypeCanSee', required: false, labelProp: Language.getActiveColumn(), hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.useEmgdeSecurityToFilter && $rootScope.app.configuration.useEmgdeSecurityToFilter.filterDocuments)?false:true) },
                        family: { type: 'SelectTree', label: 'global.literals.family', group: 'classifications', index: 1, scope: scope, field: 'family', required: true },
                        councillor: { type: 'SelectTree', label: 'global.literals.councillor', group: 'classifications', index: 2, scope: scope, field: 'councillor', required: true, onlyLastLevelClick: true },
                        archiveClassification: { type: 'SelectTree', label: 'global.literals.classificationBox', group: 'classifications', index: 3, scope: scope, field: 'archiveClassification' },
                        showClassification: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.showClassification', group: 'classifications', index: 4, scope: scope, field: 'showClassification', required: true, showClear: false },
                        procedureClassifications: { type: 'LanguageFieldSet', label: 'global.literals.classification', group: 'classifications', index: 5, scope: scope, field: 'procedureClassifications', placeholder: 'global.commonAdmin.modal.unguidedProcedure.placeholderClassification', hidden: true },
                        procedureStartProfiles: { type: 'UISelectMultiple', label: 'global.literals.initialize', group: 'profiles', index: 1, scope: scope, field: 'procedureStartProfiles', required: true, labelProp: Language.getActiveColumn() },
                        procedureViewProfiles: { type: 'UISelectMultiple', label: 'global.literals.showProcedure', group: 'profiles', index: 2, scope: scope, field: 'procedureViewProfiles', required: false, labelProp: Language.getActiveColumn() },
                        procedureResponsibleProfiles: { type: 'UISelectMultiple', label: 'global.literals.responsible', group: 'profiles', index: 3, scope: scope, field: 'procedureResponsibleProfiles', required: false, labelProp: Language.getActiveColumn() },
                        procedureTramitationProfiles: { type: 'UISelectMultiple', label: 'global.literals.tramitation', group: 'profiles', index: 4, scope: scope, field: 'procedureTramitationProfiles', required: false, labelProp: Language.getActiveColumn() },
                        informationLanguage1: { type: 'TextAreaLanguage', label: 'global.literals.information', group: 'others', index: 1, scope: scope, field: 'informationLanguage' },
						customFields:  { type: 'CustomFields', label: '', group: 'cusfields', index: 1, scope: scope, field: 'customFields', origin: { type: 'procedure', id: cell.id }},
						proposalType: { type: 'UISelect', label: 'global.sec.literals.proposalType', group: 'secretary', index: 1, scope: scope, field: 'proposalType', required: false, showClear: false, labelProp: Language.getActiveColumn() },
						organsOpinion:((factory.isActiveCouncillor)?
							{ type: 'OrgansOpinionCouncillor', label: 'global.sec.literals.opinionOrgans', group: 'secretary', index: 2, scope: scope, field: 'organsOpinion', required: false, hidden:((factory.scope && factory.scope.model && factory.scope.model.proposalType && _.contains(factory.organsProposalTypesNotHiddenOrgans, ((factory.scope.model.proposalType && factory.scope.model.proposalType.id)?factory.scope.model.proposalType.id:factory.scope.model.proposalType)))?false:true)}
							:
							{ type: 'UISelectMultiple', label: 'global.sec.literals.opinionOrgans', group: 'secretary', index: 2, scope: scope, field: 'organsOpinion', required: false, labelProp: Language.getActiveColumn(), hidden:((factory.scope && factory.scope.model && factory.scope.model.proposalType && _.contains(factory.organsProposalTypesNotHiddenOrgans, ((factory.scope.model.proposalType && factory.scope.model.proposalType.id)?factory.scope.model.proposalType.id:factory.scope.model.proposalType)))?false:true)}
						),
						organsAgreement: { type: 'UISelect', label: 'global.sec.literals.agreementOrgan', group: 'secretary', index:3, scope: scope, field: 'organsAgreement', required: false, showClear: false, labelProp: Language.getActiveColumn(), hidden:((factory.scope && factory.scope.model && factory.scope.model.proposalType && _.contains(factory.organsProposalTypesNotHiddenOrgans, ((factory.scope.model.proposalType && factory.scope.model.proposalType.id)?factory.scope.model.proposalType.id:factory.scope.model.proposalType)))?false:true)},
						organsAgreementDelegationType: {type: 'UISelect', label: 'global.commonAdmin.literals.councillorDelegationType', group: 'secretary', index:4, scope: scope, field: 'organsAgreementDelegationType', required: false, showClear: false, labelProp: Language.getActiveColumn(), hidden:((factory.isActiveCouncillor && factory.scope && factory.scope.model && factory.scope.model.proposalType && _.contains(factory.organsProposalTypesNotHiddenOrgans, ((factory.scope.model.proposalType && factory.scope.model.proposalType.id)?factory.scope.model.proposalType.id:factory.scope.model.proposalType)))?false:true)},
						proposalTitle: { type: 'InputText', label: 'global.sec.literals.proposalTitle', group: 'secretary', index: 5, scope: scope, field: 'proposalTitle', required: false },
						procedureFooterClaims: { type: 'UISelectMultiple', label: 'global.sec.literals.footerClaims', group: 'secretary', index: 6, scope: scope, field: 'procedureFooterClaims', required: false, labelProp: Language.getActiveColumn() },
						notificatorProfile: { type: 'UISelect', label: 'global.sec.literals.notificator_profile', group: 'secretary', index:7, scope: scope, field: 'notificatorProfile', required: false, showClear: false, labelProp: Language.getActiveColumn() },
						internalProfiles: { type: 'UISelectMultiple', label: 'global.literals.internalCommunication', group: 'secretary', index: 8, scope: scope, field: 'internalProfiles', required: false, labelProp: Language.getActiveColumn() },
						ammendmentTransactionType: { type: 'UISelect', label: 'global.sec.literals.amendmentProposal', group: 'secretary', index:9, scope: scope, field: 'ammendmentTransactionType', required: false, showClear: false, labelProp: Language.getActiveColumn() },
						transferTransactionType: { type: 'UISelect', label: 'global.sec.literals.transferProposal', group: 'secretary', index:10, scope: scope, field: 'transferTransactionType', required: false, showClear: false, labelProp: Language.getActiveColumn() },
						cancelTransactionType: { type: 'UISelect', label: 'global.sec.literals.cancelArchiveProposal', group: 'secretary', index:11, scope: scope, field: 'cancelTransactionType', required: false, showClear: false, labelProp: Language.getActiveColumn() },
						inspectionIntervention: { type: 'UISelect', label: 'global.sec.literals.inspectionIntervention', group: 'secretary', index: 12, scope: scope, field: 'inspectionIntervention', required: false, showClear: false },
						spelInspectionIntervention: { type: 'InputText', label: 'global.sec.literals.inspectionInterventionSpel', group: 'secretary', index: 13, scope: scope, field: 'spelInspectionIntervention', required: false, hidden:((factory.scope && factory.scope.model && factory.scope.model.inspectionIntervention && (factory.scope.model.inspectionIntervention == 'CONDITIONAL' || (factory.scope.model.inspectionIntervention && factory.scope.model.inspectionIntervention.id && factory.scope.model.inspectionIntervention.id == 'CONDITIONAL')))?false:true) },
						inspectionInterventionType: { type: 'UISelect', label: 'global.sec.literals.inspectionInterventionType', labelProp:'name', group: 'secretary', index: 14, scope: scope, field: 'inspectionInterventionType', required: true, showClear: false, hidden:((factory.internalControl && factory.scope.model && factory.scope && factory.scope.model.inspectionIntervention)?false:true)},
						responsibleUserInternalControl: { type: 'UISelect', label: 'global.sec.literals.responsibleUserInternalControl', labelProp:'completeName', group: 'secretary', index: 15, scope: scope, field: 'responsibleUserInternalControl', required: false, showClear: false},
						predefinedRecords: { type: 'InputText', label: 'global.sec.literals.predefinedRecords', group: 'secretary', index: 16, scope: scope, field: 'predefinedRecords', required: false },
						buttonProposalSignCircuit: { type: 'UIButton', label: 'global.sec.literals.configureProposalSignCircuit', func: function(procedure){
							if(procedure.id){
								TramNewFactory.configureProposalSignCircuit(undefined, 'Procedure', procedure.id, true);
							}else{
								TramNewFactory.configureProposalSignCircuit(procedure, undefined, procedure);							
							}
						}, group: 'secretary', index: 17, scope: scope, field: 'buttonProposalSignCircuits'},
						buttonSecDocumentation: { type: 'UIButton', label: 'global.sec.literals.configureSecNotificacions', func: function(procedure){
							if(procedure.id){
								TramNewFactory.configureSecNotifications(undefined, 'Procedure', procedure.id, false);
							}else{
								TramNewFactory.configureSecNotifications(procedure, undefined, undefined, false);								
							}
						}, group: 'secretary', index: 18, scope: scope, field: 'buttonSecNotification', hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sec_notification_configuration && $rootScope.app.configuration.sec_notification_configuration.type == 'PARTIAL')?false:true)},
						buttonProcedureProposalType: { type: 'UIButton', label: 'global.sec.literals.configureProcedureProposalTypes', func: function(procedure){
							if(procedure.id){
								TramNewFactory.configureProcedureProposalTypes(undefined, procedure.id, procedure);
							}else{
								TramNewFactory.configureProcedureProposalTypes(procedure, undefined, procedure);							
							}
						}, group: 'secretary', index: 19, scope: scope, field: 'buttonProcedureProposalsType'},
						closeExpireType: { type: 'UISelect', label: 'global.sec.literals.closeExpireType', group: 'archive', index: 1, scope: scope, field: 'closeExpireType', required: false, showClear: false },
						closeExpireQuantity: { type: 'InputNumber', label: 'global.sec.literals.closeExpireQuantity', group: 'archive', index: 2, scope: scope, field: 'closeExpireQuantity', min: 0},
						automaticClose: { type: 'UISelect', label: 'global.archive.automaticClose', group: 'archive', index: 3, scope: scope, field: 'automaticClose', required: false, showClear: false },
						transferExpireType: { type: 'UISelect', label: 'global.sec.literals.transferExpireType', group: 'archive', index: 4, scope: scope, field: 'transferExpireType', required: false, showClear: false, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')?false:true) },
						transferExpireQuantity: { type: 'InputNumber', label: 'global.sec.literals.transferExpireQuantity', group: 'archive', index: 5, scope: scope, field: 'transferExpireQuantity', min: 0, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')?false:true)},
						automaticTransfer: { type: 'UISelect', label: 'global.archive.automaticTransfer', group: 'archive', index: 6, scope: scope, field: 'automaticTransfer', required: false, showClear: false, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE')?false:true) },
						archiveExpireType: { type: 'UISelect', label: 'global.sec.literals.archiveExpireType', group: 'archive', index: 7, scope: scope, field: 'archiveExpireType', required: false, showClear: false, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'))?false:true) },
						archiveExpireQuantity: { type: 'InputNumber', label: 'global.sec.literals.archiveExpireQuantity', group: 'archive', index: 8, scope: scope, field: 'archiveExpireQuantity', min: 0, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'))?false:true)},
						automaticArchive: { type: 'UISelect', label: 'global.archive.automaticArchive', group: 'archive', index: 9, scope: scope, field: 'automaticArchive', required: false, showClear: false, hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'))?false:true) },
						buttonEMGDE: { type: 'UIButton', label: 'global.documents.EMGDE.infoEMGDE', func: function(procedure){
							var modal = angular.copy(CommonAdminModals.updateEMGDETransfer);
				            modal.data = procedure;
				            AnnexaModalFactory.showModal('modaupdateEMGDETransfer', modal);
						}, group: 'others', index: 2, scope: scope, field: 'buttonEMGDE', hidden:(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive && ($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'))?false:true) },
						buttonDocumentationToProvide: { type: 'UIButton', label: 'global.tram.literals.documentationToProvide', func: function(procedure){
							var modal = angular.copy(CommonAdminModals.documentationToProvideBox);
				            modal.data = procedure;
							modal.documentationToProvide = ((procedure.documentationToProvide)?procedure.documentationToProvide:[]);
				            AnnexaModalFactory.showModal('modalDocumentationToProvide', modal);
						}, group: 'others', index: 3, scope: scope, field: 'buttonDocumentationToProvide'}
                    },
                    groups: { 
                        basicinfo: { label: $filter('translate')('global.literals.basicInfo'), index: 1 },
                        classifications: { label: $filter('translate')('global.literals.classifications'), index: 2, closed: true },
                        profiles: { label: $filter('translate')('global.literals.profiles'), index: 3, closed: true },
						cusfields: { label: $filter('translate')('global.literals.customFields'), index: 4, closed: true},
						secretary: { label: $filter('translate')('global.sec.literals.secretaryConfiguration'), index: 5, closed: true},
						archive: { label: $filter('translate')('global.archive.toptitleConfiguration'), index: 6, closed: true},
                        others: { label: $filter('translate')('global.literals.others'), index: 7, closed: true }
                    },
                    renderFieldContent: ABMInspectorHelperFactory.renderCustomFields
                });
            });

            angular.element('.inspector-container').removeClass('inspector-no-data');
            angular.element('.paper-container').removeClass('paper-no-data');
        };

        //endregion

        //region Transaction

        var isInGateway = function(cell) {
            var gatewayPath = cell.get('gatewayPath');

            if(!gatewayPath) {
                return false;
            }

            var initial = $linq(factory.graph.getCells()).singleOrDefault(undefined, function(x) {
                return ABMShapesFactory.getShapeType(x) == ABMShapesFactory.TI;
            });

            if(!initial) {
                return false;
            }

            return initial.get('gatewayId') != gatewayPath.gatewayId;
        }

        factory.TransactionInspector = function(cell, scope, $inspectorHolder) {
        	var createTransactionInspector = function(cell, scope, $inspectorHolder){
        		var groups = {};
        		var inputs = {};
        		var closed = false;
        		var shapeType = ABMShapesFactory.getShapeType(cell);
        		var assignationTypeTramSelect = ABMModelFactory.getTransactionModel(cell, 'assignationTypeTram');
        		if(_.contains([ ABMShapesFactory.TEN, ABMShapesFactory.TEF, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.SN, ABMShapesFactory.SFN], shapeType)) {
        			groups.elementinfo= { label: $filter('translate')('global.literals.elementInfo'), index: 1, closed: closed };
        			closed = true;
        			
        			if(!isInGateway(cell) && shapeType != ABMShapesFactory.SN  && shapeType != ABMShapesFactory.SFN) {
        				inputs.tramitationType = {
        						type: 'UISelect',
        						label: 'global.literals.finallyType',
        						group: 'basicinfo',
        						index: 2,
        						scope: scope,
        						field: 'tramitationType',
        						required: true,
        						showClear: false,
        						origin: {type: 'transaction', id: cell.id}
        				};
        			}
        		}
        		if(factory.scope && factory.scope.model  && factory.scope.model.transactions && factory.scope.options){
        			var addState = false;
        			if(factory.scope.options.userStateInheritance && factory.scope.options.userStateInheritance.length > 0) {
        				var state = $linq(factory.scope.options.userStateInheritance).firstOrDefault(undefined, "x => x.id && x.id != 'RESPONSIBLE_PROFILE_DOSSIER' && x.id != 'LAST_DOSSIER_TRANSACTION'")
        				if(!state){
        					addState = true;	
        				}
        			}else{
        				addState = true;
        			}
        			if(addState) {
        				if(!factory.scope.options.userStateInheritance){
        					factory.scope.options.userStateInheritance = [];	
        				}else{
        					factory.scope.options.userStateInheritance.length = 0;
        				}
        				for(var key in factory.scope.model.transactions){
        					if(factory.scope.model.transactions[key].tramitationType == 'INITIAL'){
        						factory.scope.options.userStateInheritance.push({id:factory.scope.model.transactions[key].graphNodeId, language1:factory.scope.model.transactions[key].language1, language2:factory.scope.model.transactions[key].language2, language3:factory.scope.model.transactions[key].language3});
        					}
        				}
        				factory.scope.options.userStateInheritance.push({id:'RESPONSIBLE_PROFILE_DOSSIER', language1: $filter('translate')('global.literals.responsibleProfileDossier'), language2:$filter('translate')('global.literals.responsibleProfileDossier'), language3:$filter('translate')('global.literals.responsibleProfileDossier')});
        				factory.scope.options.userStateInheritance.push({id:'LAST_DOSSIER_TRANSACTION', language1: $filter('translate')('global.literals.lastDossierTransaction'), language2:$filter('translate')('global.literals.lastDossierTransaction'), language3:$filter('translate')('global.literals.lastDossierTransaction')}); 
        			}
        			var selectableOptions = [];
        			_.forEach(factory.scope.options.allProfiles, function (value, key) {
        				if(value.expiryDate == null || factory.scope.model.transactions[cell.id].processProfiles.includes(value.id)) {
        					selectableOptions.push(value);
        				}
        			});
        			factory.scope.options.processProfiles = $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
        		}
        		if(shapeType != ABMShapesFactory.SN && shapeType != ABMShapesFactory.SFN) {
        			inputs.transactionType = { type: 'UISelect', label: 'global.literals.transactionType', group: 'basicinfo', index: 1, scope: scope, field: 'transactionType', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id } };
        			inputs.phase = { type: 'UISelect', label: 'global.literals.tramitationPhase', group: 'basicinfo', index: 2, scope: scope, field: 'phase', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id } };
        			inputs.language1 = { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 3, scope: scope, field: '', origin: { type: 'transaction', id: cell.id }, required: true };
        			inputs.descriptionLanguage1 = { type: 'InputLanguage', label: 'global.literals.description', group: 'basicinfo', index: 4, scope: scope, field: 'descriptionLanguage', origin: { type: 'transaction', id: cell.id }, required: false };
        			inputs.maxDays = { type: 'InputNumber', label: 'global.literals.expire', group: 'basicinfo', index: 5, scope: scope, field: 'maxDays', min: 1, origin: { type: 'transaction', id: cell.id }, required: true };
        			inputs.expireType = { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.expireType', group: 'basicinfo', index: 6, scope: scope, field: 'expireType', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } },
        			inputs.daysComputeGlobal = { type: 'UISelect', label: 'global.literals.daysComputeGlobal', group: 'basicinfo', index: 7, scope: scope, field: 'daysComputeGlobal', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
        			if(factory.scope && factory.scope.model && (factory.scope.model.procedureType == 'PROCEDURE_SUBPROCEDURE' || factory.scope.model.procedureType == 'SUBPROCEDURE')){
        				if(shapeType == ABMShapesFactory.TI){
        					inputs.deleteInitialNode = { type: 'UISelect', label: 'global.literals.deleteInitial', group: 'basicinfo', index: 8, scope: scope, field: 'deleteInitialNode', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				}
        				if(shapeType == ABMShapesFactory.TFF || shapeType == ABMShapesFactory.TEF){
        					inputs.deleteFinalNode = { type: 'UISelect', label: 'global.literals.deleteFinal', group: 'basicinfo', index: 8, scope: scope, field: 'deleteFinalNode', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				}
        			}
        			if(shapeType != ABMShapesFactory.TI){
        				inputs.assignationTypeTram = { type: 'UISelect', label: 'global.literals.assignationType', group: 'permissions', index: 1, scope: scope, field: 'assignationTypeTram', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }};
        				inputs.processProfiles = { type: 'UISelectMultiple', label: 'global.literals.processProfiles', group: 'permissions', index: 2, scope: scope, field: 'processProfiles', required: true, labelProp: Language.getActiveColumn(), origin: { type: 'transaction', id: cell.id } };
        				inputs.userStateInheritance = { type: 'UISelect', label: 'global.literals.userInitialStateInheritance', group: 'permissions', index: 3, scope: scope, field: 'userStateInheritance', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id }};
        				inputs.useCustomFieldProfile = { type: 'UISelect', label: 'global.literals.useCustomFieldProfile', group: 'permissions', index: 4, scope: scope, field: 'useCustomFieldProfile', required: false, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.customFieldProfile = { type: 'UISelect', label: 'global.literals.customFieldProfile', group: 'permissions', index: 5, scope: scope, field: 'customFieldProfile', required: false, labelProp: 'literal', valueProp: 'customFieldId', showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.useCustomFieldUser = { type: 'UISelect', label: 'global.literals.useCustomFieldUser', group: 'permissions', index: 6, scope: scope, field: 'useCustomFieldUser', required: false, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.customFieldUser = { type: 'UISelect', label: 'global.literals.customFieldUser', group: 'permissions', index: 7, scope: scope, field: 'customFieldUser', required: false, labelProp: 'literal', valueProp: 'customFieldId', showClear: false, origin: { type: 'transaction', id: cell.id } };
        			}
        			inputs.customFields = { type: 'CustomFields', label: '', group: 'cusfields', index: 1, scope: scope, field: 'customFields', origin: { type: 'transaction', id: cell.id }};
        			inputs.informationLanguage1 = { type: 'TextAreaLanguage', label: 'global.literals.information', group: 'others', index: 1, scope: scope, field: 'informationLanguage', origin: { type: 'transaction', id: cell.id } };
        			inputs.buttonTransactionTemplates = { type: 'UIButton', label: 'global.tram.literals.transactionTemplates', func: function(procedure){
        				var transaction = ((cell && cell.id && factory && factory.scope && factory.scope.model && factory.scope.model.transactions && factory.scope.model.transactions[cell.id])?factory.scope.model.transactions[cell.id]:undefined);
        				if(transaction){
        					TramNewFactory.configureTransactionTemplates(procedure, transaction);								
        				}
        			}, group: 'others', index: 2, scope: scope, field: 'buttonTransactionTemplates'};
        			inputs.transactionProcedureProposalTypes = { type: 'UISelectMultiple', label: 'global.sec.literals.validProcedureProposalTypes', group: 'others', index: 3, scope: scope, field: 'transactionProcedureProposalTypes', required: false, labelProp: Language.getActiveColumn(), origin: { type: 'transaction', id: cell.id }, hidden:true };
        		} else {
        			inputs.assignationType = { type: 'UISelect', label: 'global.literals.type', group: 'basicinfo', index: 1, scope: scope, field: 'assignationType', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }};
        			inputs.subprocedureName = { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 2, scope: scope, field: 'subprocedureName', required: true, origin: { type: 'transaction', id: cell.id }};
        			inputs.subprocedure = { type: 'UISelect', label: 'global.literals.name', group: 'basicinfo', index: 3, scope: scope, field: 'subprocedure', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }, labelProp: Language.getActiveColumn()};
        			if(shapeType == ABMShapesFactory.SN){
        				inputs.assignationTypeTram = { type: 'UISelect', label: 'global.literals.assignationType', group: 'permissions', index: 1, scope: scope, field: 'assignationTypeTram', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }};
        				inputs.processProfiles = { type: 'UISelectMultiple', label: 'global.literals.processProfiles', group: 'permissions', index: 2, scope: scope, field: 'processProfiles', required: true, labelProp: Language.getActiveColumn(), origin: { type: 'transaction', id: cell.id } };
        				inputs.userStateInheritance = { type: 'UISelect', label: 'global.literals.userInitialStateInheritance', group: 'permissions', index: 3, scope: scope, field: 'userStateInheritance', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id }};
        				inputs.useCustomFieldProfile = { type: 'UISelect', label: 'global.literals.useCustomFieldProfile', group: 'permissions', index: 4, scope: scope, field: 'useCustomFieldProfile', required: false, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.customFieldProfile = { type: 'UISelect', label: 'global.literals.customFieldProfile', group: 'permissions', index: 5, scope: scope, field: 'customFieldProfile', required: false, labelProp: 'literal', valueProp: 'customFieldId', showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.useCustomFieldUser = { type: 'UISelect', label: 'global.literals.useCustomFieldUser', group: 'permissions', index: 6, scope: scope, field: 'useCustomFieldUser', required: false, showClear: false, origin: { type: 'transaction', id: cell.id } };
        				inputs.customFieldUser = { type: 'UISelect', label: 'global.literals.customFieldUser', group: 'permissions', index: 7, scope: scope, field: 'customFieldUser', required: false, labelProp: 'literal', valueProp: 'customFieldId', showClear: false, origin: { type: 'transaction', id: cell.id } };
        			}
        		}
        		
        		groups.basicinfo = { label: $filter('translate')('global.literals.basicInfo'), index: 2};
        		groups.permissions = { label: $filter('translate')('global.literals.permissions'), index: 3, closed: true };
        		groups.cusfields = { label: $filter('translate')('global.literals.customFields'), index: 4, closed: true};
        		groups.others = { label: $filter('translate')('global.literals.others'), index: 5, closed: true };
        		
        		$timeout(function(){
        			joint.ui.Inspector.create($inspectorHolder, {
        				cell: cell,
        				inputs: inputs,
        				groups: groups,
        				renderFieldContent: ABMInspectorHelperFactory.renderCustomFields,
        			});
        			angular.element('.inspector-container').removeClass('inspector-no-data');
        			angular.element('.paper-container').removeClass('paper-no-data');
        			if(shapeType == ABMShapesFactory.SN){
        				angular.element('#subprocedureBPMField').show();
        				angular.element('#subprocedureNameBPMField').hide();
        			}else if(shapeType == ABMShapesFactory.SFN){
        				angular.element('#subprocedureNameBPMField').show();
        				angular.element('#subprocedureBPMField').hide();
        				cell.attr({'.outer': {'stroke-width': 1}});
        				cell.attr({'.outer': {'stroke-dasharray': 'none'}});
        			}
        			if(assignationTypeTramSelect == ABMModelFactory.AT_GUIDED){
        				angular.element('#processProfilesBPMField').show();
        				angular.element('#useCustomFieldProfileBPMField').show();
        				angular.element('#customFieldProfileBPMField').hide();
        				angular.element('#useCustomFieldUserBPMField').show();
        				angular.element('#customFieldUserBPMField').hide();
        				angular.element('#userStateInheritanceBPMField').hide();
                		var useCustomFieldProfile = ABMModelFactory.getTransactionModel(cell, 'useCustomFieldProfile');
                		var useCustomFieldUser = ABMModelFactory.getTransactionModel(cell, 'useCustomFieldUser');
                		if(useCustomFieldProfile && useCustomFieldProfile == 'YES') {
            				angular.element('#customFieldProfileBPMField').show();
                		}
                		if(useCustomFieldUser && useCustomFieldUser == 'YES') {
            				angular.element('#customFieldUserBPMField').show();
                		}
        			}else if(assignationTypeTramSelect == ABMModelFactory.AT_INHERITED){
        				angular.element('#processProfilesBPMField').hide();
        				angular.element('#useCustomFieldProfileBPMField').hide();
        				angular.element('#customFieldProfileBPMField').hide();
        				angular.element('#useCustomFieldUserBPMField').hide();
        				angular.element('#customFieldUserBPMField').hide();
        				angular.element('#userStateInheritanceBPMField').show();
        			}else{
        				angular.element('#processProfilesBPMField').hide();
        				angular.element('#useCustomFieldProfileBPMField').hide();
        				angular.element('#customFieldProfileBPMField').hide();
        				angular.element('#useCustomFieldUserBPMField').hide();
        				angular.element('#customFieldUserBPMField').hide();
        				angular.element('#userStateInheritanceBPMField').hide();
        			}
        		});
        	}
        	if(factory.scope && factory.scope.model && factory.scope.model.id){
        		ABMModelFactory.transactionProcedureProposalTypes.length = 0;
//        		TramNewFactory.getProcedureProposalTypes(factory.scope.model.id).then(function(data) {
//        			if(data && data.length > 0){
//            			_.forEach(data, function(ppt){
//        					ABMModelFactory.transactionProcedureProposalTypes.push({id:ppt.id, language1: ppt.language1, language2: ppt.language2, language3: ppt.language3});
//            			});
//            		}
//        			createTransactionInspector(cell, scope, $inspectorHolder);
//        		}).catch(function (error) {
        			createTransactionInspector(cell, scope, $inspectorHolder);
//            	});
        	}else{
        		ABMModelFactory.transactionProcedureProposalTypes.length = 0;
//        		if(factory.scope.model.procedureProposalTypes && factory.scope.model.procedureProposalTypes.length > 0){
//        			_.forEach(factory.scope.model.procedureProposalTypes, function(ppt){
//        				if(ppt.proposalType && ppt.proposalType.id){
//        					ABMModelFactory.transactionProcedureProposalTypes.push({id:ppt.proposalType.id, language1: ppt.language1, language2: ppt.language2, language3: ppt.language3});
//        				}
//        			});
//        		}
        		createTransactionInspector(cell, scope, $inspectorHolder);
        	}

        };

        //endregion

        //region Gateway

        factory.GatewayInspector = function(cell, scope, $inspectorHolder) {
            angular.element('.inspector-container').addClass('inspector-no-data');
            angular.element('.paper-container').addClass('paper-no-data');
        }

        //endregion

		//region Flow

        factory.FlowInspector = function(cell, scope, $inspectorHolder) {
           	var groups = {
				basicinfo:{ label: $filter('translate')('global.literals.basicInfo'), index: 1}
			};
            var inputs = {
				spel: { type: 'TextArea', label: 'global.literals.condition', group: 'basicinfo', index: 1, scope: scope, field: 'spel', rows:10, origin: { type: 'transactionTransition', id: cell.id, cell:cell } },
				buttonExp: { type: 'UIButton', label: 'global.literals.expressionEvaluator', func: function(procedure){
					var params = [];
					params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
					params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
					params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
					CommonService.executeExpression(((scope && scope.model && scope.model.transactionTransitionGuideds && scope.model.transactionTransitionGuideds[cell.id])?scope.model.transactionTransitionGuideds[cell.id].spel:undefined), params);	
				}, group: 'basicinfo', index: 2, scope: scope, field: 'buttonExp', hidden:false, origin: { type: 'transactionTransition', id: cell.id, cell:cell }},
				description: { type: 'TextArea', label: 'global.literals.description', group: 'basicinfo', index: 3, scope: scope, field: 'description', rows:4, origin: { type: 'transactionTransition', id: cell.id, cell:cell } }
            };
            
            $timeout(function(){
                joint.ui.Inspector.create($inspectorHolder, {
                    cell: cell,
                    inputs: inputs,
                    groups: groups,
                    renderFieldContent: ABMInspectorHelperFactory.renderCustomFields,
                });

                angular.element('.inspector-container').removeClass('inspector-no-data');
                angular.element('.paper-container').removeClass('paper-no-data');
                angular.element('#subprocedureNameBPMField').hide();
                angular.element('#subprocedureBPMField').hide();
                angular.element('#processProfilesBPMField').hide();
                angular.element('#userStateInheritanceBPMField').hide();
				ABMShapesFactory.unhighlightAllFlows();
				cell.attr({'.connection':{ stroke:'#a94442'}});
				cell.attr({'.connection':{'stroke-width':3}});
				cell.attr({'.marker-target':{stroke:'#a94442'}});
				cell.attr({'.marker-target':{'stroke-width':3}});
            });
        }

        //endregion

        //endregion

    return factory;
}])
    .factory('ABMInspectorHelperFactory',['$compile', 'Language', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'CommonAdminService', 'HelperService', 'ErrorFactory', 'AdminFactory', 'ABMModelFactory', 'GlobalDataFactory', function($compile, Language, $rootScope, CommonAdminModals, AnnexaFormlyFactory, CommonAdminService, HelperService, ErrorFactory, AdminFactory, ABMModelFactory, GlobalDataFactory) {
        var factory = {};

        //region Generals

        var compiledElement = function(template, scope) {
            var compiled = $compile(template)(scope);

            var $element = $(compiled);
            return $element;
        }

        var getContainerWithContent = function (label, field, content, compile, scope, hidden) {
            var template = getContainerTemplate(label, field, hidden);

            template = template.replace('[CONTENT]', content);

            if(compile) {
                return compiledElement(template, scope);
            } else {
                return template;
            }
        }

        var getModel = function(origin, field, language, model) {
            var defaultModel = !language ? 'model[\'' + field + '\']' : 'model';

            if(origin && origin.type && origin.id) {
                switch (origin.type) {
                    case 'transaction':
                        return !language ? 'model.transactions[\'' + origin.id + '\'][\'' + field + '\']' : 'model.transactions[\'' + origin.id + '\']';
					case 'transactionTransition':
						if(model && model.transactionTransitionGuideds && !model.transactionTransitionGuideds[origin.id]){
							model.transactionTransitionGuideds[origin.id] = ABMModelFactory.InitializeTransactionTransition(origin.cell, origin.cell.get("type"))
						}
                        return !language ? 'model.transactionTransitionGuideds[\'' + origin.id + '\'][\'' + field + '\']' : 'model.transactionTransitionGuideds[\'' + origin.id + '\']';
                }
            }

            return defaultModel;
        }
        //endregion

        //region Templates

        var getContainerTemplate = function(label, field, hidden) {
            var template = '';
            var id = field+"BPMField";
            if(hidden ) {
                template += '<div class="row" id="'+id+'" style="display: none">';
            } else {
                template += '<div class="row" id="'+id+'">';
            }
            template += '   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">';
            template += '       <div class="row">';
            if(label){
				template += '           <div class="col-xs-12">';
            	template += '               <label class="label-strong small" small m-b-0"><span translate="' + label + '">' + label + '</span>';
            	template += '           </div>';
			}
            template += '           <div class="col-xs-12">';
            template += '               [CONTENT]';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        var getValidation = function(required, maxlength, min, max, languageRequired, languageRequiredOrigin) {
            var tag = 'validation="';
            if(languageRequired) {
                if(required) {
                    if (!languageRequiredOrigin) {
                        languageRequiredOrigin = {type: 'Procedure', id: ''};
                    }
                    return 'validation="custom:angularInputLanguageValidation(\'' + languageRequired + '\',\'' + languageRequiredOrigin.id + '\')"';
                } else {
                    return '';
                }
            }

            var validation = tag;

            if(required) {
                if(validation == tag) {
                    validation += 'required:alt={{ \'global.validation.required\' | translate }}';
                } else {
                    validation += '|required:alt={{ \'global.validation.required\' | translate }}';
                }
            }

            if(maxlength) {
                if(validation == tag) {
                    validation += 'max_len:' + maxlength;
                } else {
                    validation += '|max_len:' + maxlength;
                }
            }

            if(min) {
                if(validation == tag) {
                    validation += 'min_num:' + min;
                } else {
                    validation += '|min_num:' + min;
                }
            }

            if(max) {
                if(validation == tag) {
                    validation += 'max_num:' + max;
                } else {
                    validation += '|max_num:' + max;
                }
            }

            if(validation == tag) {
                validation = '';
            } else {
                validation += '"';
            }

            return validation;
        };

        var getInputTemplate = function(type, field, origin, maxlength, min, max, required, hidden) {
            var inputClass = 'form-control';

            if(type == 'number') {
                inputClass += ' num-20-pc';
            }

            var template = '<input type="' + type + '" class="' + inputClass + '" ng-model="' + getModel(origin,field)  + '" id="' + field + '" name="' + field + '"';

            template += getValidation(required, maxlength, min, max) + '>';

            return template;
        };

        var getUISelectTemplate = function (field, origin, labelProp, valueProp, required, searchEnabled, showClear, options, hidden) {
            var template = '';
            template += '<a href="" tabindex="0" class="bt-deselect" ng-click="clearUISelect($event,' + getModel(origin, field) + ',$select)" ng-show="' + getModel(origin, field)  + ' && ' + showClear + '" role="button">';
            template += '   <i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span>';
            template += '</a>';
            template += '<ui-select fix-focus-on-touch data-ng-model="' + getModel(origin, field) + '" data-ng-required="' + required + '" search-enabled="' + searchEnabled + '" theme="bootstrap" on-select="onUISelect($select, $model, \'' + (origin == undefined ? '' : origin.type) + '\',\'' + (origin == undefined ? '' : origin.id) + '\',\'' + field + '\')" name="' + field + '" ' + getValidation(required) + '>';
            template += '   <ui-select-match placeholder="">';
            template += '       <span>{{$select.selected[\'' + labelProp + '\'] | translate}}</span>';
            template += '   </ui-select-match>'
            template += '   <ui-select-choices data-repeat="option[\'' + valueProp + '\'] as option in options[\'' + field + '\'] | filter: $select.search">';
            template += '       <div ng-bind-html="option[\'' + labelProp + '\'] | translate | highlight: $select.search"></div>';
            template += '   </ui-select-choices>';
            template += '</ui-select>';
            return template;
        };

		var getUIButtonTemplate = function (field, label, func, hidden) {
			var template = '';
            template += '<button type="button" class="btn primary text-white" ng-click="'+func+'()">';
            template += '   <span translate="'+label+'">See</span>';
            template += '</button>';
            return template;
        };
        var getUISelectMultipleTemplate = function (field, origin, labelProp, valueProp, required, searchEnabled) {
            var template = '';

            template += '<ui-select multiple data-ng-model="' + getModel(origin, field) + '" data-ng-required="' + required + '" search-enabled="' + searchEnabled + '" theme="bootstrap" on-select="onUISelect($select, $model, \'' + (origin == undefined ? '' : origin.type) + '\',\'' + (origin == undefined ? '' : origin.id) + '\',\'' + field + '\')" name="' + field + '" ' + getValidation(required) + '>';
            template += '   <ui-select-match placeholder="">';
            template += '       <span style="display: inline;">{{$item[\'' + labelProp + '\']}}</span>';
            template += '   </ui-select-match>';
            template += '   <ui-select-choices data-repeat="option[\'' + valueProp + '\'] as option in (options[\'' + field + '\'] | filter: {'+labelProp+': $select.search})">';
            template += '       <div ng-bind-html="option[\'' + labelProp + '\'] | translate | highlight: $select.search"></div>';
            template += '   </ui-select-choices>';
            template += '</ui-select>';

            return template;
        };

        var getSelectTreeTemplate = function (field, origin, onlyLastLevelClick, required) {
            var template = '';

            template += '<ui-tree-select ng-model="' + getModel(origin, field) + '" data="options[\'' + field + '\']" required="' + required + '" only-last-level-click="' + onlyLastLevelClick + '" name="' + field + '" ' + getValidation(required) + '>';
            template += '</ui-tree-select>';

            return template;
        };

        //endregion

        //region Field Types

        var CustomFields = function (scope, origin) {
            if(origin.type != 'transaction' && origin.type != 'procedure') {
                return '';
            }

            if(origin.type == 'transaction' && !origin.id) {
                return '';
            }
            if(origin.type == 'procedure'){
            	var template = '<annexa-custom-field-definition custom-fields="' + getModel(origin, 'customFields', false)+'" type="PROCEDURE" show-type="'+((origin.type == 'procedure')?'true':'false')+'" update-on-change="false" parent-property="'+((origin.type == 'procedure')?'procedure':'transaction')+'" parent-id="' + getModel(origin, 'id', false)+'" init-cfield-when-saved="' + getModel(undefined, 'initCfieldWhenSaved', false)+'"/>';
            }else{
            	var template = '<annexa-custom-field-definition custom-fields="' + getModel(origin, 'customFields', false)+'" type="PROCEDURE" show-type="'+((origin.type == 'procedure')?'true':'false')+'" update-on-change="false" parent-property="'+((origin.type == 'procedure')?'procedure':'transaction')+'" parent-id="' + getModel(origin, 'id', false)+'"/>';
            }
            return compiledElement(template, scope);
        }
        var InputLanguage = function (scope, field, label, origin, required) {
            var template = '<div id="'+field+'BPMField"><languageinputs  model-language="' + getModel(origin, field, true) + '" enabled-field="true" model-field="' + field + '" label="' + label + '" ng-model="' + getModel(origin, field, true) + '" name="' + (field == undefined || field == "" ? "language" : field) + '" ' + getValidation(required,undefined,undefined,undefined, field == undefined || field == "" ? "language" : field, origin) + '/><div>';
            return compiledElement(template, scope);
        };
        
        var TextAreaLanguage = function (scope, field, label, origin, required) {
            var template = '<div id="'+field+'BPMField"><languagetextareas  model-language="' + getModel(origin, field, true) + '" enabled-field="true" model-field="' + field + '" label="' + label + '" ng-model="' + getModel(origin, field, true) + '" name="' + (field == undefined || field == "" ? "language" : field) + '" ' + getValidation(required,undefined,undefined,undefined, field == undefined || field == "" ? "language" : field, origin) + '/><div>';
            return compiledElement(template, scope);
        };

        var InputText = function(scope, field, label, origin, maxlength, min, max, required, hidden) {
            return getContainerWithContent(label, field, getInputTemplate('text', field, origin, maxlength, min, max, required, hidden), true, scope, hidden);
        };

		var TextArea = function (scope, field, label, origin, maxlength, rows) {
			var template = '';
            var id = field+"BPMField";
            template += '<div class="row" id="'+id+'">';
            template += '   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">';
            template += '       <div class="row">';
            if(label){
				template += '           <div class="col-xs-12">';
            	template += '               <label class="label-strong small" small m-b-0"><span translate="' + label + '">' + label + '</span>';
            	template += '           </div>';
			}
            template += '           <div class="col-xs-12">';
            template += '               <textarea style="width: 100%; height: auto;" rows="'+((rows)?rows:5)+'" class="form-control" ng-model="'+getModel(origin,field, undefined,scope.model)+'" maxlength='+maxlength+'></textarea>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
			return compiledElement(template, scope);
        };

        var InputNumber = function (scope, field, label, origin, min, max, required, hidden) {
            return getContainerWithContent(label, field, getInputTemplate('number', field, origin, false, min, max, required, hidden), true, scope, hidden);
        };

        var UISelect = function (scope, field, label, origin, labelProp, valueProp, required, searchEnabled, showClear, options, hidden) {
            scope.clearUISelect = function($event, model, $select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            };

            scope.onUISelect = function($item, $model, origin_type, origin_id, field) {
                $rootScope.$emit('BPMInspectorChange', { origin: { type: origin_type, id: origin_id }, field: field, value: $model });
            }

            return getContainerWithContent(label, field, getUISelectTemplate(field, origin, labelProp, valueProp, required, searchEnabled, showClear, options, hidden), true, scope, hidden);
        };

        var UISelectMultiple = function(scope, field, label, origin, labelProp, valueProp, required, searchEnabled, hidden) {
            scope.onUISelect = function($item, $model, origin_type, origin_id, field) {
                $rootScope.$emit('BPMInspectorChange', { origin: { type: origin_type, id: origin_id }, field: field, value: $model });
            }

            return getContainerWithContent(label, field, getUISelectMultipleTemplate(field, origin, labelProp, valueProp,required, searchEnabled, hidden), true, scope, hidden);
        }

        var SelectTree = function(scope, field, label, origin, onlyLastLevelClick, required) {
            return getContainerWithContent(label, field, getSelectTreeTemplate(field, origin, onlyLastLevelClick, required), true, scope);
        };

        var LanguageFieldSet = function(scope, field, label, origin, modelField, placeholder, hidden) {
            scope.addLanguageFieldSet = function(field) {
                var id = 0;

                if(scope.optionsAdd[field]) {
                    var element = {};
                    element[Language.getActiveColumn()] = scope.optionsAdd[field];
                    element.deleted = false;

                    if(scope.model[field]) {
                        scope.model[field].push(element);
                    } else {
                        scope.model[field] = [];
                        scope.model[field].push(element);
                    }

                    scope.optionsAdd[field] = '';
                }
            };

            scope.removeLanguageFieldSet = function(field, index) {
                if(scope.model[field] && scope.model[field].length > index) {
                    scope.model[field].splice(index, 1);
                }
            };

            scope.filterList = function(item) {
                if(item && item.deleted){
                    return false;
                }else{
                    return true;
                }
            };

            var template = '';
            var showFieldSet = true;
            if(scope.model.showClassification == 'DISABLED'){
                showFieldSet = false;
            }
            if(hidden && !showFieldSet) {
                template += '<fieldset id="' + field + '" class="m-b" style="display: none">';
            } else {
                template += '<fieldset id="' + field + '" class="m-b">';
            }
            template += '   <legend class="label-strong">';
            template += '       {{\'' + label + '\' | translate}}';
            template += '   </legend>';
            template += '   <div ng-model="' + getModel(origin, field, true) + '">';
            template += '       <div ng-repeat="field in ' + getModel(origin, field) + ' | filter: filterList">';
            template += '           <div class="row m-b-125">';
            template += '               <div class="col-sm-1 text-right p-x-0">';
            template += '                   <label class="col-sm-3 p-y-sm grey-pencil p-a-0">{{((field.id)? field.id : \'\')}}</label>';
            template += '               </div>';
            template += '               <div class="col-sm-10">';
            template += '                   <languageinputs model-language="field" model-field="' + modelField + '"/>';
            template += '               </div>';
            template += '               <div class="col-sm-1 p-l-0 m-t-xs">';
            template += '                   <a href="" tabindex="0" ng-show="{{!field.id}}" ng-click="removeLanguageFieldSet(\'' + field + '\', $index)" class="remove-button m-t-xs"><i class="fa fa-remove grey-pencil" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span></a>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="row m-b-125">';
            template += '           <div class="col-sm-1 text-right p-r-xs">';
            template += '           </div>';
            template += '           <div class="col-sm-10">';
            template += '               <input type="text" class="form-control" ng-model="optionsAdd[\'' + field + '\']" placeholder="{{\'' + placeholder + '\' | translate}}">';
            template += '           </div>';
            template += '           <div class="col-sm-1 p-l-0">';
            template += '               <a ng-click="addLanguageFieldSet(\'' + field + '\')" ng-show="optionsAdd[\'' + field + '\']" class="remove-button m-t-xs"><i class="fa fa-check grey-pencil"></i></a>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</fieldset>';

            return compiledElement(template, scope);
        }
		var UIButton = function (scope, field, label, func, hidden) {
			scope[field+"OnUIButton"] = function() {
                if(func){
					func(scope.model);
				}
            }
			
            return getContainerWithContent(undefined, field, getUIButtonTemplate(field, label, field+"OnUIButton", hidden), true, scope, hidden);
        };
        var OrgansOpinionCouncillor = function (scope, field, hidden) {
			scope[field+"OrgansOpinionCouncillor"] = {
					organs: ((GlobalDataFactory.organs)?$linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'OPINION'").toArray():[]),
					delegationTypes: AdminFactory.councillorProposalTypeDelegationType,
					organsOpinion: ((scope.procedure && scope.procedure.organsOpinion)?scope.procedure.organsOpinion:[])
			}
        	var template = '';
        	template += '<annexa-organs-opinion-councillor-procedure data="'+field+'OrgansOpinionCouncillor"></annexa-organs-opinion-councillor-procedure>';
            return getContainerWithContent(undefined, field, template, true, scope, hidden);
        };
        //endregion

        //region Inspector Functions

        factory.renderCustomFields = function (options, path, value) {
            switch (options.type) {
                case 'InputLanguage':
                    return InputLanguage(options.scope, options.field, options.label, options.origin, options.required);
                case 'InputText':
                    return InputText(options.scope, options.field, options.label, options.origin, options.maxlength || false, undefined, undefined, options.required, options.hidden == undefined ? false : options.hidden);
                case 'InputNumber':
                    return InputNumber(options.scope, options.field, options.label, options.origin, options.min || false, options.max || false, options.required, options.hidden == undefined ? false : options.hidden);
                case 'UISelect':
                    return UISelect(options.scope, options.field, options.label, options.origin, options.labelProp || 'description', options.valueProp || 'id', options.required == undefined ? false : options.required, options.searchEnabled == undefined ? false : options.searchEnabled, options.showClear == undefined ? true : options.showClear, undefined, ((options.hidden)?true:false));
                case 'UISelectMultiple':
                    return UISelectMultiple(options.scope, options.field, options.label, options.origin, options.labelProp || 'description', options.valueProp || 'id', options.required == undefined ? false : options.required, options.searchEnabled == undefined ? false : options.searchEnabled, ((options.hidden)?true:false));
                case 'SelectTree':
                    return SelectTree(options.scope, options.field, options.label, options.origin, options.onlyLastLevelClick == undefined ? false : options.onlyLastLevelClick, options.required == undefined ? false : options.required);
                case 'LanguageFieldSet':
                    return LanguageFieldSet(options.scope, options.field, options.label, options.origin, options.modelField || '', options.placeholder || '', options.hidden == undefined ? false : options.hidden );
                case 'CustomFields':
                    return CustomFields(options.scope, options.origin);
                case 'TextAreaLanguage':
                    return TextAreaLanguage(options.scope, options.field, options.label, options.origin, options.required);
				 case 'TextArea':
                    return TextArea(options.scope, options.field, options.label, options.origin, options.maxlength || false, options.rows);
				case 'UIButton':
                    return UIButton(options.scope, options.field, options.label, options.func, ((options.hidden)?true:false));
				case 'OrgansOpinionCouncillor':
                    return OrgansOpinionCouncillor(options.scope, options.field, ((options.hidden)?true:false));
            }

            var $span = $('<span>Error</span>');
            return $span;
        };


        factory.notEqual = function(cell, value, arguments) {
            return arguments.scope.model[arguments.field] != arguments.value;
        };

        //endregion

        return factory;
    }]);
